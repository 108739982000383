// Backport of VueUse v9's `useInfiniteScroll`
// See: https://github.com/vueuse/vueuse/issues/3060

import type { UnwrapNestedRefs } from 'vue'
import { nextTick, reactive, watch } from 'vue'
import type { MaybeRefOrGetter, UseScrollOptions } from '@vueuse/core'
import { toValue, useScroll } from '@vueuse/core'

export interface UseInfiniteScrollOptions extends UseScrollOptions {
	/**
   * The minimum distance between the bottom of the element and the bottom of the viewport
   *
   * @default 0
   */
	distance?: number

	/**
   * The direction in which to listen the scroll.
   *
   * @default 'bottom'
   */
	direction?: 'top' | 'bottom' | 'left' | 'right'

	/**
   * Whether to preserve the current scroll position when loading more items.
   *
   * @default false
   */
	preserveScrollPosition?: boolean
}

/**
 * Reactive infinite scroll.
 *
 * @see https://vueuse.org/useInfiniteScroll
 */
export function useInfiniteScroll(
	element: MaybeRefOrGetter<HTMLElement | SVGElement | Window | Document | null | undefined>,
	onLoadMore: (state: UnwrapNestedRefs<ReturnType<typeof useScroll>>) => void | Promise<void>,
	options: UseInfiniteScrollOptions = {},
) {
	const direction = options.direction ?? 'bottom'
	const state = reactive(useScroll(
		element,
		{
			...options,
			offset: {
				[direction]: options.distance ?? 0,
				...options.offset,
			},
		},
	))

	watch(
		() => state.arrivedState[direction],
		async(v) => {
			if (v) {
				const elem = toValue(element) as Element
				const previous = {
					height: elem?.scrollHeight ?? 0,
					width: elem?.scrollWidth ?? 0,
				}

				await onLoadMore(state)

				if (options.preserveScrollPosition && elem) {
					nextTick(() => {
						elem.scrollTo({
							top: elem.scrollHeight - previous.height,
							left: elem.scrollWidth - previous.width,
						})
					})
				}
			}
		},
	)
}
