<script setup lang="ts">
import { match } from '~/ts/utils/match'
import QuotationStatuses = Domain.BookingCaptainJet.Enums.QuotationStatuses

const $props = defineProps<{
	status: QuotationStatuses
}>()

const state = computed(() => match($props.status, {
	accepted: {
		text: 'Contract pending',
		icon: 'IconSandClock',
		class: 'bg-turquoise-100 text-turquoise-600',
	},
	pending: {
		text: 'Pending',
		icon: 'IconSandClock',
		class: 'bg-gray-100 text-blue-400',
	},
	dispatched: {
		text: 'Pending',
		icon: 'IconSandClock',
		class: 'bg-gray-100 text-blue-400',
	},
	declined: {
		text: 'No longer available',
		icon: 'IconX',
		class: 'bg-turquoise-50 text-blue-300',
	},
	default: undefined,
}))
</script>

<template>
	<div v-if="state" class="flex items-center rounded-xl px-3 py-1.5 text-[0.815rem] font-medium leading-none" :class="state.class">
		<Icon :name="state?.icon" class="mr-1.5 size-3.5" />
		<span v-text="state.text" />
	</div>
</template>
