<template>
	<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.335 3.108a.733.733 0 0 1 .639-.361.748.748 0 0 1 .639.361l2.15 3.673 2.149 3.675a.714.714 0 0 1-.27.99.747.747 0 0 1-.369.097h-8.63a.747.747 0 0 1-.638-.362.716.716 0 0 1 0-.725l2.15-3.675 2.18-3.673Z"
			stroke="currentColor"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="m6.536 5.629.093 2.556h.748L7.47 5.63h-.934ZM6.639 9.66a.491.491 0 0 0 .364.157c.14 0 .262-.054.36-.156a.513.513 0 0 0 .148-.366c0-.14-.05-.264-.148-.366a.486.486 0 0 0-.36-.156.491.491 0 0 0-.364.156.513.513 0 0 0-.149.366c0 .14.05.264.149.366Z"
			fill="currentColor"
		/>
	</svg>
</template>
