import { clamp } from '@vueuse/core'

export type Vector2 = {
	x: number
	y: number
}

export function lerp(from: number, to: number, by: number) {
	return from + (to - from) * by
}

export function magnitude(a: number, b: number) {
	return Math.sqrt((a * a) + (b * b))
}

/**
 * Gradually changes a value towards a desired goal over time.
 * From: https://github.com/Unity-Technologies/UnityCsReference/blob/02d565cf3dd0f6b15069ba976064c75dc2705b08/Runtime/Export/Math/Mathf.cs#L301-L329
 * @param velocity Reference to current velocity
 * @param smoothTime Larger number = more smoothing
 * @param deltaTime In seconds
 */
export function smoothDamp(
	current: number,
	target: number,
	velocity: number,
	smoothTime: number,
	maxSpeed: number,
	deltaTime: number,
) {
	// Based on Game Programming Gems 4 Chapter 1.10
	smoothTime = Math.max(0.0001, smoothTime)
	const omega = 2.0 / smoothTime

	const x = omega * deltaTime
	const exp = 1.0 / (1.0 + x + 0.48 * x * x + 0.235 * x * x * x)
	let change = current - target
	const originalTo = target

	// Clamp maximum speed
	const maxChange = maxSpeed * smoothTime
	change = clamp(change, -maxChange, maxChange)
	target = current - change

	const temp = (velocity + omega * change) * deltaTime
	velocity = (velocity - omega * temp) * exp
	let smoothed = target + (change + temp) * exp

	// Prevent overshooting
	if ((originalTo - current > 0.0) === (smoothed > originalTo)) {
		smoothed = originalTo
		velocity = (smoothed - originalTo) / deltaTime
	}

	return {
		smoothed,
		velocity,
	}
}
