<template>
	<svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16.153 3.4c3.053 3.054 3.472 7.743 1.252 11.197a7.655 7.655 0 0 1-1.228 1.58c-.498.497-1.024.906-1.551 1.257A9.076 9.076 0 0 1 3.4 16.154C-.123 12.63-.133 6.886 3.377 3.376s9.253-3.5 12.776.024ZM5.432 5.433a6.12 6.12 0 0 0 .016 8.674 6.12 6.12 0 0 0 8.674.016 6.12 6.12 0 0 0-.016-8.674 6.12 6.12 0 0 0-8.674-.016Z"
			fill="currentColor"
		/>
		<path
			d="m24.222 25.041.848-.848c.497-.497.437-1.318-.12-1.875l-6.43-6.43a8.806 8.806 0 0 1-1.17 1.463 9.467 9.467 0 0 1-1.462 1.17l6.43 6.43c.557.557 1.407.588 1.904.09Z"
			fill="currentColor"
		/>
	</svg>
</template>
