<script setup lang="ts">
import { VNode } from 'vue'

const props = defineProps<{
	maxNumber: number
	number: number
	title: string
	paragraphs: VNode[]
	aircraftImg: string
	aircraftImg2x: string
	aircraftIcon?: VNode
	mainIcon: VNode
	onClickPrevious?: (payload: MouseEvent) => void
	onClickNext?: (payload: MouseEvent) => void
}>()

const aircraft = ref<HTMLElement>()

defineExpose({
	aircraft,
})
</script>

<template>
	<div class="relative flex max-w-[532px] flex-col items-start">
		<div class="flex">
			<button
				class="-ml-4 rounded-full stroke-blue-400 px-4 transition-colors disabled:stroke-blue-500"
				:disabled="props.number <= 1"
				@click="props.onClickPrevious"
			>
				<svg fill="none" viewBox="0 0 7 12" width="7">
					<path stroke-width="2" d="M6 1 2 6l4 5" />
				</svg>
			</button>
			<p class="mx-1 text-3xl font-light tracking-[-0.165px] text-blue-400">
				{{ props.number }}/{{ props.maxNumber }}
			</p>
			<button
				class="rounded-full stroke-blue-400 px-4 transition-colors disabled:stroke-blue-500"
				:disabled="props.number >= props.maxNumber"
				@click="props.onClickNext"
			>
				<svg fill="none" viewBox="0 0 7 12" width="7" transform="rotate(180)">
					<path stroke-width="2" d="M6 1 2 6l4 5" />
				</svg>
			</button>
		</div>
		<div ref="aircraft" class="relative mt-14 flex size-16 items-center justify-center rounded-full border border-warning-500">
			<img
				class="absolute max-w-none"
				:srcSet="`${props.aircraftImg2x} 2x`"
				:src="props.aircraftImg"
				alt="Aircraft"
			/>
			<component
				:is="props.aircraftIcon"
				class="absolute -right-1 -top-1 size-[17px] drop-shadow-[-2px_1px_0px_rgba(18,35,54,1)]"
			/>
		</div>
		<component :is="props.mainIcon" class="mt-16 size-[25px] text-white" />
		<p class="mt-5 text-lg font-bold leading-[22px] text-white">
			{{ props.title }}
		</p>
		<div class="mt-5 font-nova text-base font-medium leading-[23px] text-blue-200">
			<component :is="() => props.paragraphs" />
		</div>
	</div>
</template>
