<script setup lang="ts">
import { SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { useWireTransferDialog } from '~/ts/dialog'

const { isOpen, close, properties } = useWireTransferDialog()
const confirmed = ref(false)

function submit() {
	close({ confirmed: confirmed.value })
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="Wire transfer"
		title-size="sm"
		@close="close()"
	>
		<form @submit.prevent="submit">
			<div class="space-y-5 border-t border-turquoise-100 p-6">
				<div class="space-y-3.5">
					<BaseInput
						id="address"
						:value="properties?.details.address.replaceAll('\n', ', ')"
						:readonly="true"
						name="address"
						label="Address"
						type="text"
					/>

					<BaseInput
						id="reference"
						:value="properties?.details.reference"
						:readonly="true"
						name="reference"
						label="Références"
						type="text"
					/>

					<BaseInput
						id="iban"
						:value="properties?.details.iban"
						:readonly="true"
						name="iban"
						label="IBAN"
						type="text"
					/>

					<BaseInput
						id="bic"
						:value="properties?.details.bic"
						:readonly="true"
						name="bic"
						label="BIC"
						type="text"
					/>
				</div>

				<SwitchGroup as="div" class="flex items-center justify-between rounded-xl border border-turquoise-100 p-4">
					<SwitchLabel as="div" class="cursor-default font-medium tracking-tighter text-blue-500">
						I agree to make an on-time payment no later than 72 hours prior scheduled departure.
					</SwitchLabel>

					<InputSwitch v-model="confirmed" label="Favorite address" />
				</SwitchGroup>

				<BaseButton
					type="submit"
					variant="primary"
					size="lg"
					class="w-full font-display text-lg font-bold tracking-tight"
					:disabled="!confirmed"
				>
					Confirm
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>
