<script setup lang="ts">
const jetflyImages = Object.values(import.meta.glob('~/images/captainjet/about/group/jetfly/*/1x.webp', { eager: true, as: 'url' }))
const jetflyImages2x = Object.values(import.meta.glob('~/images/captainjet/about/group/jetfly/*/2x.webp', { eager: true, as: 'url' }))

const jetflyTechnikImages = Object.values(import.meta.glob('~/images/captainjet/about/group/jetfly-technik/*/1x.webp', { eager: true, as: 'url' }))
const jetflyTechnikImages2x = Object.values(import.meta.glob('~/images/captainjet/about/group/jetfly-technik/*/2x.webp', { eager: true, as: 'url' }))

const fly7Images = Object.values(import.meta.glob('~/images/captainjet/about/group/fly7/*/1x.webp', { eager: true, as: 'url' }))
const fly7Images2x = Object.values(import.meta.glob('~/images/captainjet/about/group/fly7/*/2x.webp', { eager: true, as: 'url' }))

const fly7TrainingImages = Object.values(import.meta.glob('~/images/captainjet/about/group/fly7-training/*/1x.webp', { eager: true, as: 'url' }))
const fly7TrainingImages2x = Object.values(import.meta.glob('~/images/captainjet/about/group/fly7-training/*/2x.webp', { eager: true, as: 'url' }))

type Image = {
	src: string
	srcSet?: string
	alt?: string
}

type Company = {
	name: string
	url: string
	icon: string
	iconClass?: string
	title: string
	description: string
	images: Image[]
}

function getImages(x1s: string[], x2s: string[]): Image[] {
	return x1s.map((src, index) => ({
		src,
		srcSet: `${x2s[index]} 2x`,
	}))
}

const companies: Company[] = [
	{
		name: 'Jetfly',
		url: 'https://jetfly.com/',
		icon: 'IconLogoJetfly',
		iconClass: '-ml-7',
		title: 'Fractional ownership',
		description: 'Benefit from all advantages of owning an aircraft, without taking on the complete cost risk',
		images: getImages(jetflyImages, jetflyImages2x),
	},
	{
		name: 'Jetfly Technik',
		url: 'https://www.jetfly-technik.com/',
		icon: 'IconLogoJetflyTechnik',
		iconClass: '-ml-9',
		title: 'Aircraft maintenance',
		description: 'The comprehensive maintenance solution – Maintenance for all Pilatus aircraft',
		images: getImages(jetflyTechnikImages, jetflyTechnikImages2x),
	},
	{
		name: 'Fly7',
		url: 'https://fly7.ch/',
		icon: 'IconLogoFly7',
		iconClass: '-ml-5',
		title: 'Aircraft management',
		description: 'The largest Commercial Operator for Pilatus PC-12 & PC-24 aircraft, who provides individual solutions to fit the owner’s exact needs',
		images: getImages(fly7Images, fly7Images2x),
	},
	{
		name: 'Fly7 Training',
		url: 'https://fly7-training.com/',
		icon: 'IconLogoFly7Training',
		iconClass: '-ml-5',
		title: 'Training center',
		description: 'Innovative training solutions in a unique PC-12 simulator training center',
		images: getImages(fly7TrainingImages, fly7TrainingImages2x),
	},
]
const lastHoveredCompanyIndex = ref(0)

function handleGroupCardHover(index: number, hovered: boolean) {
	if (hovered) {
		lastHoveredCompanyIndex.value = index
	}
}
</script>

<template>
	<div class="overflow-hidden bg-turquoise-50 pb-18 pt-10">
		<PageContainer max-width-class="max-w-7xl px-6">
			<div class="flex flex-col sm:items-center sm:text-center">
				<h1 class="font-inter text-4xl font-medium text-blue-500">
					The Jetfly group
				</h1>
				<p class="mt-5 font-medium text-blue-300">
					We are part of a private aviation group with unique flight solutions to fit all your needs.
				</p>
			</div>
			<div class="relative mt-14 flex flex-col-reverse items-center gap-20 lg:flex-row">
				<div class="z-20 max-w-[700px] space-y-4 lg:absolute lg:inset-y-0 lg:left-0 lg:max-w-[min(700px,66%)] lg:pr-6 lg:pt-4">
					<AboutGroupCard
						v-for="(company, index) in companies"
						:key="company.url"
						:href="company.url"
						:icon="company.icon"
						:icon-class="company.iconClass ?? ''"
						:title="company.title"
						:description="company.description"
						@mouseenter="() => handleGroupCardHover(index, true)"
						@mouseleave="() => handleGroupCardHover(index, false)"
					/>
				</div>

				<div class="-ml-12 hidden xs:-ml-20 lg:-mr-28 lg:flex lg:flex-1 lg:justify-end lg:py-20 xl:m-0 xl:p-0">
					<div class="lg:w-1/2">
						<div class="relative h-72 w-60 xs:h-[360px] xs:w-72 sm:h-[440px] sm:w-[330px] xl:mb-32 xl:h-[550px] xl:w-[470px]">
							<div
								v-for="(company, index) in companies"
								:key="company.url"
								class="absolute inset-0 transition-opacity ease-out lg:w-[470px] lg:overflow-x-visible lg:pl-8"
								:class="[lastHoveredCompanyIndex === index ? 'z-10 opacity-100 duration-150' : 'z-0 opacity-0 duration-500']"
							>
								<img
									class="size-full rounded-2xl object-cover"
									:src="company.images[0].src"
									:srcSet="company.images[0].srcSet"
									:alt="company.name"
								/>
								<div class="relative z-10 -mr-12 -mt-8 flex justify-end xs:-mr-20 xs:-mt-12 lg:-mr-10 lg:-mt-24 xl:-mr-40">
									<div class="h-28 w-40 xs:h-32 xs:w-48 sm:h-40 sm:w-64 xl:h-[220px] xl:w-[310px]">
										<img
											class="size-full rounded-2xl object-cover"
											:src="company.images[1].src"
											:srcSet="company.images[1].srcSet"
											:alt="company.name"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PageContainer>
	</div>
</template>
