<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999999 31.999998"
	>
		<path
			d="m12 1020.4c-0.554 0-1 0.446-1 1v1c0 0.554 0.446 1 1 1s1-0.446 1-1v-1c0-0.554-0.446-1-1-1zm-7.7773 3.2207c-0.25562 0-0.51117 0.099-0.70703 0.2949-0.39174 0.3918-0.39174 1.0224 0 1.4141l0.70703 0.707c0.39173 0.3918 1.0223 0.3918 1.4141 0 0.39174-0.3917 0.39174-1.0223 0-1.414l-0.70703-0.7071c-0.19587-0.1958-0.45142-0.2949-0.70703-0.2949zm15.555 0c-0.25561 0-0.51116 0.099-0.70703 0.2949l-0.70703 0.7071c-0.39174 0.3917-0.39174 1.0223 0 1.414 0.39174 0.3918 1.0223 0.3918 1.4141 0l0.70704-0.707c0.39173-0.3917 0.39173-1.0223 0-1.4141-0.19587-0.1958-0.45142-0.2949-0.70704-0.2949zm-7.7773 0.7793c-4.4183 0-8 3.5817-8 8 0 1.5899 0.4699 3.0672 1.2695 4.3125-0.79632 1.0202-1.2693 2.3012-1.2695 3.6875 0 3.3137 2.6863 6 6 6h15.5c3.5898 0 6.5-2.9101 6.5-6.5 0-3.5898-2.9102-6.5-6.5-6.5-0.49867 0-0.99543 0.062-1.4805 0.1777-0.97236-1.7878-2.5764-3.091-4.4434-3.7304-1.0664-3.1648-4.0518-5.4473-7.5762-5.4473zm0 2c2.2253 0 4.1633 1.2143 5.1992 3.0137-0.067 0-0.13195-0.014-0.19922-0.014-3.2637 0-6.1985 1.9875-7.416 5.0156-1.0334 0.072-1.9871 0.4063-2.8066 0.9297-0.49216-0.8707-0.77734-1.8738-0.77734-2.9453 0-3.3137 2.6863-6 6-6zm-11 5c-0.554 0-1 0.446-1 1s0.446 1 1 1h1c0.554 0 1-0.446 1-1s-0.446-1-1-1zm16 0c2.8668 0 5.3313 2.0325 5.8828 4.8457 0.76276-0.5481 1.6779-0.8438 2.6172-0.8457 2.4853 0 4.5 2.0147 4.5 4.5s-2.0147 4.5-4.5 4.5h-15.5c-2.2091 0-4-1.7909-4-4s1.7909-4 4-4c0.36119 0 0.72057 0.051 1.0684 0.1484 0.4232-2.9516 2.9498-5.1446 5.9316-5.1484z"
			transform="translate(0 -1020.4)"
		/>
	</svg>
</template>
