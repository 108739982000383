<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { parse } from '~/ts/utils/datetime'

const HOURS_STEP = 1
const MINUTES_STEP = 5

const props = defineProps<{
	id: string
	modelValue?: string
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
}>()

const date = computed(() => parse(props.modelValue))
const formattedDate = computed(() => date.value.format('HH : mm'))
const hours = ref(date.value.hour())
const minutes = ref(date.value.minute())

watch([hours, minutes], () => updateTime())
watch(date, () => {
	hours.value = date.value.hour()
	minutes.value = date.value.minute()
})

function updateTime() {
	if (hours.value > 23) {
		hours.value = 0
	} else if (hours.value < 0) {
		hours.value = 23
	}

	if (minutes.value > 59) {
		minutes.value = 0
	} else if (minutes.value < 0) {
		minutes.value = 55
	}

	emit('update:modelValue', date.value
		.minute(Math.ceil(minutes.value / 5) * 5)
		.hour(hours.value)
		.format(),
	)
}

function addHour() {
	hours.value += HOURS_STEP
}

function subtractHour() {
	hours.value -= HOURS_STEP
}

function addMinute() {
	minutes.value += MINUTES_STEP
}

function subtractMinute() {
	minutes.value -= MINUTES_STEP
}

function setMinutes(e: Event) {
	const target = e.target as HTMLInputElement
	minutes.value = Math.min(Math.max(Number.parseInt(target.value) || minutes.value, 0), 55)
}

function setHours(e: Event) {
	const target = e.target as HTMLInputElement
	hours.value = Math.min(Math.max(Number.parseInt(target.value) || hours.value, 0), 23)
}
</script>

<template>
	<Popover class="relative">
		<PopoverButton :id="id" class="w-full text-left font-medium text-blue-500 focus-visible:ring-2 focus-visible:ring-turquoise-600">
			{{ formattedDate }}
		</PopoverButton>
		<transition
			enter-active-class="transition duration-100 ease-out origin-top"
			enter-from-class="scale-95 opacity-0"
			enter-to-class="scale-100 opacity-100"
			leave-active-class="transition duration-75 ease-in origin-top"
			leave-from-class="scale-100 opacity-100"
			leave-to-class="scale-95 opacity-0"
		>
			<PopoverPanel class="absolute left-1/2 z-10 mt-3 -translate-x-1/2 overflow-hidden rounded-2xl bg-white shadow focus:outline-none">
				<div class="flex items-center px-6 py-0.5 font-display text-lg font-semibold text-blue-500">
					<div class="flex flex-col items-center space-y-1">
						<button
							type="button"
							class="px-5 py-4 text-turquoise-400 transition hover:text-turquoise-500 focus-visible:text-turquoise-600 disabled:text-turquoise-300 disabled:hover:text-turquoise-300"
							@click="addHour"
						>
							<IconChevronDown class="w-3 rotate-180" />
						</button>
						<input
							:value="hours.toString().padStart(2, '0')"
							type="text"
							class="w-14 rounded-md bg-turquoise-50 px-1 py-2 text-center font-display text-lg font-semibold text-blue-500"
							:step="HOURS_STEP"
							@blur="setHours"
							@keydown.up.prevent="addHour"
							@keydown.down.prevent="subtractHour"
						/>
						<button
							type="button"
							class="px-5 py-4 text-turquoise-400 transition hover:text-turquoise-500 focus-visible:text-turquoise-600 disabled:text-turquoise-300 disabled:hover:text-turquoise-300"
							@click="subtractHour"
						>
							<IconChevronDown class="w-3" />
						</button>
					</div>

					<div class="mx-2">
						:
					</div>

					<div class="flex flex-col items-center space-y-1">
						<button
							type="button"
							class="px-5 py-4 text-turquoise-400 transition hover:text-turquoise-500 focus-visible:text-turquoise-600 disabled:text-turquoise-300 disabled:hover:text-turquoise-300"
							@click="addMinute"
						>
							<IconChevronDown class="w-3 rotate-180" />
						</button>
						<input
							:value="minutes.toString().padStart(2, '0')"
							type="text"
							class="w-14 rounded-md bg-turquoise-50 px-1 py-2 text-center font-display text-lg font-semibold text-blue-500"
							:step="MINUTES_STEP"
							@blur="setMinutes"
							@keydown.up.prevent="addMinute"
							@keydown.down.prevent="subtractMinute"
						/>
						<button
							type="button"
							class="px-5 py-4 text-turquoise-400 transition hover:text-turquoise-500 focus-visible:text-turquoise-600 disabled:text-turquoise-300 disabled:hover:text-turquoise-300"
							@click="subtractMinute"
						>
							<IconChevronDown class="w-3" />
						</button>
					</div>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>

<style lang="postcss">
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance:textfield;
}
</style>
