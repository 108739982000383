<script setup lang="ts">
import { vElementVisibility } from '@vueuse/components'
import { clamp } from '@vueuse/core'

const props = withDefaults(defineProps<{
	initialChar?: string
	targetChar?: string
	maxDistance?: number
	initialDelay?: number
	speed?: number
	skewAmount?: number
	height?: number
	minWidth?: number
	borderRadius?: number
	flapColor?: string
	splitColor?: string
	shadowAmount?: number
	textVOffset?: number
	biDirectional?: boolean
}>(), {
	initialChar: '0',
	targetChar: ' ',
	maxDistance: 14,
	initialDelay: 250,
	speed: 0.74,
	skewAmount: 0.85,
	height: 36,
	minWidth: 25,
	borderRadius: 5,
	flapColor: '#122336',
	splitColor: '#1A3550',
	shadowAmount: 1,
	textVOffset: 0,
	biDirectional: false,
})

const prevChar = ref(props.initialChar)
const char = ref(props.initialChar)
const nextChars = ref<string[]>([])
const sanitiseCharacters = (char?: string) => char || ' '
const runUpdate = ref(false)
const forwards = ref(false)

function tryShiftChars() {
	if (!nextChars.value.length) {
		return
	}

	prevChar.value = char.value
	char.value = nextChars.value.shift() ?? ' '
	runUpdate.value = true

	if (props.biDirectional) {
		forwards.value = char.value.charCodeAt(0) > prevChar.value.charCodeAt(0)
	}

	return true
}

function fillNextChars(targetChar: string) {
	const prevCode = char.value.charCodeAt(0)
	const nextCode = sanitiseCharacters(targetChar).charCodeAt(0)
	const isForwards = nextCode > prevCode
	const step = isForwards ? 1 : -1
	const validRegExp = /\w/

	nextChars.value.length = 0

	for (let i = prevCode + step; isForwards ? i <= nextCode : i >= nextCode; i += step) {
		const char = String.fromCharCode(i)

		// Restrict certain characters (except the target character).
		if (i === nextCode || validRegExp.test(char)) {
			nextChars.value.push(char)
		}
	}

	// Clamp distance to max by skipping first chars.
	while (nextChars.value.length > props.maxDistance) {
		nextChars.value.shift()
	}

	if (!runUpdate.value) {
		tryShiftChars()
	}
}

function onVisibility(state: boolean) {
	if (state && props.targetChar !== char.value) {
		setTimeout(() => fillNextChars(props.targetChar), props.initialDelay)
	}
}

watch(() => props.targetChar, fillNextChars)

const progress = ref(0)
const topBack = ref<HTMLElement>()
const topFlapHalf = ref<HTMLElement>()
const topFlap = ref<HTMLElement>()
const bottomBack = ref<HTMLElement>()
const bottomFlapHalf = ref<HTMLElement>()
const bottomFlap = ref<HTMLElement>()

function setFlapOpen(flapHalf: HTMLElement, flap: HTMLElement, isTop: boolean, amount: number) {
	const invAmount = 1 - amount
	const skewDeg = clamp(props.skewAmount, -1, 1) * 45
	const skewOffset = 0.25 * props.height * Math.tan(skewDeg * Math.PI / 180)

	flapHalf.style.transform = `skewX(${(isTop ? 1 : -1) * invAmount * skewDeg}deg) translateX(${invAmount * -skewOffset}px)`
	flap.style.transform = `scaleY(${amount})`
	flap.style.boxShadow = `0 0 ${props.shadowAmount * amount * 30}px black`
}

function setBackVisible(back: HTMLElement, value: boolean) {
	back.style.visibility = value ? 'visible' : 'hidden'
}

useRafFn(({ delta }) => {
	if (!runUpdate.value) {
		return
	}

	progress.value = Math.min(1, progress.value + props.speed * 0.01 * delta)
	const circularProgress = Math.sin((forwards.value ? 1 : -1) * (0.5 + progress.value) * Math.PI)

	if (topFlap.value && topFlapHalf.value) {
		const openAmount = Math.max(0, -circularProgress)
		setFlapOpen(topFlapHalf.value, topFlap.value, true, openAmount)

		if (topBack.value) {
			setBackVisible(topBack.value, openAmount < 1)
		}
	}

	if (bottomFlap.value && bottomFlapHalf.value) {
		const openAmount = Math.max(0, circularProgress)
		setFlapOpen(bottomFlapHalf.value, bottomFlap.value, false, openAmount)

		if (bottomBack.value) {
			setBackVisible(bottomBack.value, openAmount < 1)
		}
	}

	if (progress.value >= 1) {
		progress.value = 0

		if (!tryShiftChars()) {
			runUpdate.value = false
		}
	}
})
</script>

<template>
	<div v-element-visibility="onVisibility" class="cont relative flex">
		<!--Use target char to set width of element-->
		<span class="invisible">{{ props.targetChar }}</span>
		<div class="layer">
			<div class="half top">
				<div ref="topBack" class="char invisible">
					<span>{{ forwards ? prevChar : char }}</span>
				</div>
			</div>
			<div class="half bottom">
				<div ref="bottomBack" class="char">
					<span>{{ forwards ? char : prevChar }}</span>
				</div>
			</div>
		</div>
		<div class="layer">
			<div ref="topFlapHalf" class="half top">
				<div ref="topFlap" class="char">
					<span>{{ forwards ? char : prevChar }}</span>
				</div>
			</div>
			<div ref="bottomFlapHalf" class="half bottom">
				<div ref="bottomFlap" class="char scale-y-0">
					<span>{{ forwards ? prevChar : char }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.cont {
  height: v-bind('`${$props.height}px`');
  min-width: v-bind('`${$props.minWidth}px`');
}
.layer {
	@apply absolute top-0 left-0 h-full w-full
}
.half {
  --border-radius: v-bind('`${$props.borderRadius}px`');

	@apply absolute left-0 h-1/2 w-full overflow-hidden
}
.top.half {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.bottom.half {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.top {
	@apply top-0
}
.top > .char {
	--lin-grad-angle: 180deg;

	@apply top-0
}
.bottom {
	@apply bottom-0
}
.bottom > .char {
	--lin-grad-angle: 0deg;

	@apply bottom-0
}
.char {
  --lin-grad-angle: 0deg;
  --bg-length: 48%;

  position: absolute;
  left: 0;
  height: 200%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    var(--lin-grad-angle),
    v-bind('$props.flapColor') var(--bg-length),
    v-bind('$props.splitColor') var(--bg-length));

  border-radius: var(--border-radius);
  line-height: normal;
}
.char > span {
	--v-offset: v-bind('`${$props.textVOffset}px`');

	margin-top: calc(-1 * var(--v-offset));
	margin-bottom: var(--v-offset);
}
</style>
