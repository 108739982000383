<script setup lang="ts">
useHead({
	title: 'Flight Brokerage Terms & Conditions',
})

const $props = defineProps<{
	content: string
}>()
</script>


			<script lang="ts">
			import layout from '@/layouts/marketing.vue'
			export default { layout }
			</script>
			<template>
		
	<div class="font-nova">
		<BaseHeader :marketing="true" />

		<LegalDocumentHero title="Flight Brokerage Terms & Conditions" />

		<LegalDocumentContent :content="$props.content" />

		<MarketingFooter />
	</div>
</template>
