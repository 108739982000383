<script setup lang="ts">
const $props = defineProps<{
	content: string
}>()
</script>
<template>
	<div class="mx-auto pb-5 pt-9 lg:max-w-[50%] lg:py-16">
		<!-- eslint-disable-next-line vue/no-v-html -->
		<div class="prose mt-4" v-html="$props.content" />
	</div>
</template>
