<script setup lang="ts">
import { route } from '@/composables/route'
import { useNewPasswordDialog } from '~/ts/dialog'
import { useProperty } from '@/composables/useProperty'

const { isOpen, close } = useNewPasswordDialog()

const form = useForm({
	password: '',
	password_confirmation: '',
	...useProperty('new-password').value,
})

function recover() {
	form.post(route('captainjet.web.password.update'), {
		onSuccess: () => {
			close()
			form.reset()
		},
	})
}
</script>

<template>
	<BaseDialog
		:show="isOpen"
		title="New password"
		title-size="lg"
		:closeable="false"
		@close="close()"
	>
		<form @submit.prevent="recover">
			<div class="p-6 pt-0">
				<div class="space-y-3.5">
					<BaseInput
						id="password"
						v-model="form.password"
						name="password"
						label="Password"
						type="password"
						autocomplete="new-password"
						:error="form.errors.password"
						@clear="form.clearErrors('password')"
					/>
					<BaseInput
						id="password-confirmation"
						v-model="form.password_confirmation"
						name="password_confirmation"
						label="Confirmation"
						type="password"
						autocomplete="false"
						:error="form.errors.password_confirmation"
						@clear="form.clearErrors('password_confirmation')"
					/>
				</div>

				<base-button
					type="submit"
					size="lg"
					class="mt-6 w-full font-display text-lg"
					variant="primary"
					:disabled="form.password === '' || form.password_confirmation === ''"
					:loading="form.processing"
				>
					Confirm
				</base-button>
			</div>
		</form>
	</BaseDialog>
</template>
