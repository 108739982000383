<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'
import { parse } from '~/ts/utils/datetime'

const props = defineProps<{
	id: string
	modelValue?: string
}>()

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void
}>()

const departureDate = ref(props.modelValue)

watch(departureDate, (val, oldVal) => {
	let dateTime = parse(val ?? oldVal)
		.set('hour', parse(props.modelValue).get('hour'))
		.set('minute', parse(props.modelValue).get('minute'))

	if (!val) {
		dateTime = dateTime.set('year', 1970)
	}

	emit('update:modelValue', dateTime.format())
})

</script>

<template>
	<Popover v-slot="{ open }" as="div" class="w-full">
		<Float
			portal
			placement="bottom"
			:show="open"
			:shift="6"
			enter="transition duration-100 ease-out"
			enter-from="scale-95 opacity-0"
			enter-to="scale-100 opacity-100"
			leave="transition duration-75 ease-in"
			leave-from="scale-100 opacity-100"
			leave-to="scale-95 opacity-0"
			origin-class="top"
			:offset="10"
		>
			<PopoverButton :id="id" class="w-full text-left font-medium text-blue-500 focus-visible:ring-2 focus-visible:ring-turquoise-600">
				<span v-if="parse(modelValue).get('year') === 1970" class="max-w-full overflow-hidden font-medium italic text-warning-500">Select date</span>
				<span v-else>{{ parse(modelValue).format('D MMM. YY') }}</span>
			</PopoverButton>

			<PopoverPanel class="mt-0.5 w-[calc(100vw-32px)] overflow-hidden rounded-2xl bg-white shadow focus:outline-none xs:w-auto xs:min-w-[375px]">
				<DatePicker v-model="departureDate" class="vc-style" />
			</PopoverPanel>
		</Float>
	</Popover>
</template>

<style lang="postcss">
.vc-style.vc-container {
	@apply !w-full !h-full !text-base !border-none !py-1 !text-blue-500 ![--rounded:9999px] ![--blue-600:#F2A949];
}

.vc-style .vc-title {
	@apply !text-blue-500 !tracking-widest;
}

.vc-style .vc-arrow {
	@apply !mx-1 hover:!text-turquoise-400 !text-turquoise-500 hover:!bg-transparent;
}

.vc-style .vc-weeks {
	@apply mt-2;
}

.vc-style .vc-weekday {
	@apply !text-xs !font-normal !text-blue-500 !font-display;
}

.vc-style .vc-highlight-bg-solid {
	@apply !bg-warning-500;
}

.vc-style .vc-day-content {
	@apply !h-10 !w-10 !tracking-tighter !font-medium !font-display !my-0.5 !z-10 !shadow-none;
}

.vc-style .vc-day-content:not(.vc-highlight-content-solid):hover,
.vc-style .vc-day-content[tabindex='0']:not(.vc-highlight-content-solid) {
	@apply !bg-turquoise-50;
}

.vc-style .vc-day-content:hover,
.vc-day-content:focus {
	@apply !bg-inherit;
}

.vc-style .vc-day-content.vc-highlight-content-solid {
	@apply !font-bold;
}

.vc-popover-content-wrapper {
	@apply !top-0;
	transform: translate(103.2px, 42.4px) !important;
}

.vc-style .vc-highlights .vc-highlight {
	@apply !h-10 !w-10;
}

.vc-style .vc-nav-popover-container {
	@apply bg-turquoise-100 border-none shadow shadow-turquoise-100 font-display;
}

.vc-style .vc-nav-popover-container .vc-nav-header {
	@apply px-1.5 text-blue-500 my-1;
}

.vc-style .vc-nav-popover-container .vc-nav-header .vc-nav-title {
	@apply text-blue-500 hover:bg-blue-100 border-0 focus:border-0;
}

.vc-style .vc-nav-popover-container .vc-nav-header .vc-nav-arrow {
	@apply hover:bg-transparent hover:text-blue-400 focus:border-0 border-0;
}

.vc-style .vc-nav-popover-container .vc-nav-items {
	@apply gap-2;
}

.vc-style .vc-nav-popover-container .vc-nav-items .vc-nav-item,
.vc-style .vc-nav-popover-container .vc-nav-items .vc-nav-item.is-current {
	@apply hover:bg-turquoise-200 hover:text-blue-500 border-0 shadow-none font-medium text-sm text-blue-400 transition-none;
}

.vc-style .vc-nav-popover-container .vc-nav-items .vc-nav-item.is-active {
	@apply hover:bg-turquoise-500 bg-turquoise-500 text-white;
}
</style>
