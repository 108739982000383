<script setup lang="ts">
import { match } from '~/ts/utils/match'

const $props = defineProps<{
	current: 'profile' | 'payment'
}>()

useHead({
	title: match($props.current, {
		profile: 'Profile settings',
		payment: 'Payment settings',
		default: 'Profile',
	}),
})
</script>

<template>
	<PageContainer>
		<h1 class="font-display text-xl font-bold leading-9 text-blue-500">
			Settings
		</h1>

		<div class="mt-6">
			<SettingsMenu :current="current" />
			<div class="mt-4">
				<transition
					mode="out-in"
					enter-active-class="duration-200 transition"
					enter-from-class="opacity-0 translate-y-1"
					enter-to-class="opacity-100 translate-y-0"
					leave-active-class="duration-200 transition"
					leave-from-class="opacity-100 translate-y-0"
					leave-to-class="opacity-0 translate-y-1"
				>
					<slot />
				</transition>
			</div>
		</div>
	</PageContainer>
</template>
