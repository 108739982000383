<script setup lang="ts">
import { VNode } from 'vue'

export type Data = {
	src: string
	srcSet?: string
	label: string
}

const props = withDefaults(defineProps<{
	datas: Data[]
	orientation?: 'right' | 'left'
	index: number
	labelIcon?: VNode
	showToggles?: boolean
	darken?: boolean
	lighten?: boolean
	fade?: boolean
	onIndexChange?: (value: number) => void
}>(), {
	orientation: 'right',
	showToggles: true,
})

function handleIndexChange(value: number) {
	if (props.onIndexChange) {
		props.onIndexChange(value)
	}
}

function isHidden(i: number) {
	return props.orientation === 'right' ? (i < props.index - 1) : (i > props.index + 1)
}

function getWidthClass(i: number) {
	if (isHidden(i)) {
		return 'w-0'
	}
	if (i === props.index) {
		return 'w-[325px]'
	}

	return 'w-[120px]'
}

function getMarginClass(i: number) {
	switch (props.orientation) {
		case 'right': {
			return i < props.index - 1 ? 'mr-0' : 'mr-6'
		}
		case 'left': {
			return i > props.index + 1 ? 'ml-0' : 'ml-6'
		}
	}
}

function getOpacityClass(i: number) {
	if (isHidden(i)) {
		return 'opacity-0'
	}
	if (props.fade && i !== props.index) {
		return 'opacity-70'
	}

	return 'opacity-100'
}
</script>

<template>
	<div
		:class="[
			'group/gallery flex flex-col',
			props.orientation === 'right' ? 'items-start' : 'items-end'
		]"
	>
		<!--Images-->
		<div class="max-w-full">
			<div
				:class="[
					'flex',
					props.orientation === 'right' ? 'justify-start' : 'justify-end',
				]"
			>
				<div
					v-for="(data, i) in props.datas"
					:key="i"
					:class="[
						'group/item relative h-[475px] shrink-0 overflow-hidden transition-all duration-300 will-change-auto group-hover/gallery:will-change-transform',
						props.darken || props.lighten ? 'bg-white' : (i === props.index ? 'bg-turquoise-400' : 'bg-blue-500'),
						i === props.index ? 'rounded-[40px]' : 'rounded-2xl',
						getWidthClass(i),
						getMarginClass(i),
						getOpacityClass(i),
						{
							'cursor-pointer hover:opacity-100 hover:duration-200': i !== props.index,
						}
					]"
					@click="() => handleIndexChange(i)"
				>
					<img
						:class="[
							'absolute left-0 top-0 size-full object-cover transition-transform duration-300 ease-out',
							{
								'group-hover/item:scale-105': i !== props.index,
							}
						]"
						:src="data.src"
						:srcSet="data.srcSet"
						:alt="data.label"
					/>
					<!--Darken-->
					<div
						v-if="props.darken"
						:class="[
							'absolute inset-px rounded-2xl bg-blue-600 mix-blend-overlay transition-opacity duration-300',
							i === props.index ? 'opacity-0' : 'opacity-100',
						]"
					/>
					<!--Lighten-->
					<div
						v-if="props.lighten"
						:class="[
							'absolute inset-0 rounded-[42px] bg-turquoise-500 mix-blend-soft-light transition-opacity duration-200 ease-out group-hover/item:opacity-100',
							i === props.index ? 'opacity-100' : 'opacity-0',
						]"
					/>
					<!--Label underlay-->
					<div
						:class="[
							'label-underlay absolute inset-0 transition-opacity duration-300',
							i === props.index ? 'opacity-100' : 'opacity-0',
						]"
					/>
					<!--Label-->
					<div
						:class="[
							'absolute bottom-[26px] left-8 flex items-center gap-2 whitespace-nowrap text-sm text-white transition-opacity duration-300',
							i === props.index ? 'opacity-100' : 'opacity-0',
						]"
					>
						<component :is="props.labelIcon" v-if="props.labelIcon" class="w-3 shrink-0" />
						<span>{{ data.label }}</span>
					</div>
				</div>
			</div>
		</div>
		<!--Toggles-->
		<div
			v-if="props.showToggles"
			:class="[
				'relative mt-[26px] flex w-0 justify-center',
				props.orientation === 'right' ? 'ml-[306.5px]' : 'mr-[306.5px]',
			]"
		>
			<div class="flex">
				<label
					v-for="(_, i) in props.datas"
					:key="i"
					class="cursor-pointer p-[5px]"
				>
					<input
						class="peer sr-only"
						type="radio"
						name="image"
						:checked="i === props.index"
						@change="() => handleIndexChange(i)"
					/>
					<div class="size-1.5 rounded-full bg-blue-100 transition-[width,color] duration-300 peer-checked:w-5 peer-checked:bg-blue-400" />
				</label>
			</div>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.label-underlay {
	background: linear-gradient(13deg, #1A3550 3%, rgba(59, 119, 133, 0) 19%);
}
</style>
