<script setup lang="ts">
import { SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { useNewCreditCardDialog } from '~/ts/dialog'
import { useStripe } from '@/composables/useStripe'
import { route } from '@/composables/route'
import StorePaymentMethodData = Domain.Payment.Data.StorePaymentMethodData

const { isOpen, close: closeDialog, properties } = useNewCreditCardDialog()
const cardElement = ref<HTMLDivElement>()

const form = useForm<StorePaymentMethodData>({
	stripe_id: '',
	is_default: false,
})

const { initialized, initialize, error, filled, submitting, getId } = useStripe({
	onIdReceived: (id) => form.stripe_id = id,
	cardElement,
	cardOptions: {
		style: {
			base: {
				'iconColor': '#8EA1B4',
				'color': '#23466B',
				'fontWeight': '500',
				'fontSize': '1.1rem',
				'fontFamily': '"Red Hat Display", -system-ui, Arial',
				'fontSmoothing': 'antialiased',
				'::placeholder': {
					color: '#8EA1B4',
				},
			},
			invalid: {
				iconColor: '#F96662',
				color: '#F96662',
			},
		},
	},
})

whenever(isOpen, initialize, { flush: 'post' })
const canSubmit = computed(() => filled.value)

async function onSubmit() {
	if (!canSubmit.value) {
		return
	}

	if (!await getId()) {
		return
	}

	form.post(route('captainjet.web.payment-methods.store'), {
		onSuccess() {
			properties.value?.onSave?.(form.stripe_id)
			form.reset()
			close()
		},
		onError(errors) {
			error.value = errors.stripe_id
		},
	})
}

const isBusy = computed(() => submitting.value || form.processing)
function close() {
	closeDialog()
	form.reset()
}
</script>

<template>
	<base-dialog
		:show="isOpen"
		title="New Credit Card"
		:closeable="!isBusy"
		title-size="sm"
		@close="close"
	>
		<form @submit.prevent="onSubmit">
			<div class="space-y-5 border-t border-turquoise-100 p-6">
				<div class="space-y-1">
					<div class="flex overflow-hidden rounded-xl bg-turquoise-50 focus-within:ring-1 focus-within:ring-turquoise-600">
						<div class="flex h-16 w-full flex-col py-2 pl-3.5">
							<label for="cardElement" class="flex font-display text-sm font-medium text-blue-300">Card details</label>
							<div v-show="initialized" class="mt-2">
								<div ref="cardElement" />
							</div>
							<div v-if="!initialized" class="mt-1 flex h-5 w-full items-center justify-center opacity-50">
								<icon-loading class="size-4" />
							</div>
						</div>
					</div>
					<div v-if="error" class="flex items-center space-x-2 text-sm font-medium text-danger-500">
						<icon-exclamation-circle class="size-3.5 shrink-0" />
						<span>{{ error }}</span>
					</div>
				</div>

				<SwitchGroup as="div" class="flex items-center justify-between rounded-xl border border-turquoise-100 p-4">
					<SwitchLabel as="div" class="flex cursor-default items-center space-x-2 font-medium tracking-tighter text-blue-500">
						<icon-star-outline class="size-5" />
						<span>Favorite card</span>
					</SwitchLabel>

					<InputSwitch v-model="form.is_default" label="Favorite card" />
				</SwitchGroup>

				<base-button
					type="submit"
					size="lg"
					class="w-full font-display text-lg font-bold tracking-tight"
					variant="primary"
					:disabled="!canSubmit"
					:loading="isBusy"
				>
					Save
				</base-button>
			</div>
		</form>
	</base-dialog>
</template>
