<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.508 6.72c-.397 0-.795-.134-1.06-.403a1.836 1.836 0 0 1-.597-.809h-.464V4.97h.398v-.068h-.398v-.538h.53c.066-.337.265-.606.53-.808a1.815 1.815 0 0 1 1.061-.337c.22 0 .394.046.598.1l.132.035c.199.134.397.269.53.47l.133.136-.597.404-.066-.068c-.047-.047-.086-.095-.122-.14a.676.676 0 0 0-.21-.196c-.132-.068-.265-.068-.398-.068a.746.746 0 0 0-.53.202.522.522 0 0 0-.265.27h1.193l-.199.538H5.58v.068h1.127l-.199.538h-.795c.066.135.132.202.265.337.133.067.331.135.53.135.133 0 .266 0 .398-.068.133-.067.199-.134.332-.269l.066-.135.53.404-.066.135a2.034 2.034 0 0 1-.53.539 2.35 2.35 0 0 1-.73.135ZM4.718 8.74H8.1c.2 0 .398-.201.398-.403s-.132-.404-.398-.404H4.72c-.2 0-.399.202-.399.404.067.202.2.404.398.404ZM4.718 10.02h5.105c.2 0 .332.135.332.404 0 .202-.2.404-.398.404H4.718c-.265 0-.398-.202-.398-.404 0-.27.2-.404.398-.404Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.613 1.333c1.127 0 2.122.876 2.254 2.02L14 13.658c0 .336-.199.606-.53.74-.133.068-.2.068-.332.068a.835.835 0 0 1-.597-.27l-.265-.27-.53.472a1.058 1.058 0 0 1-.597.202.835.835 0 0 1-.596-.269l-.465-.471-.464.471c-.132.202-.397.27-.596.27-.2 0-.464-.135-.597-.27l-.464-.471-.464.606c-.2.134-.332.202-.597.202a.835.835 0 0 1-.597-.27l-.464-.471-.53.471a1.058 1.058 0 0 1-.597.202.835.835 0 0 1-.596-.269l-.465-.471-.265.269a1.058 1.058 0 0 1-.596.202c-.133 0-.2 0-.332-.067A.829.829 0 0 1 2 13.522V3.42c0-1.145.928-2.088 2.122-2.088h7.491Zm.995 11.448h.132V3.488c-.132-.606-.596-1.01-1.127-1.01H4.122c-.53 0-.995.404-.995.943v9.36h.199c.133-.135.265-.135.398-.135.199 0 .398.068.53.202l.53.54.465-.472c.199-.202.397-.27.596-.27.2 0 .398.068.53.202l.531.54.53-.472a.6.6 0 0 1 .53-.27c.2 0 .399.068.531.202l.53.54.465-.472c.199-.202.397-.27.596-.27.2 0 .398.068.53.202l.465.54.53-.472a.6.6 0 0 1 .53-.27c.2 0 .332.068.465.135Z"
			fill="currentColor"
		/>
	</svg>
</template>
