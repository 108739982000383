<template>
	<svg viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M77.2894 27.3479C92.9035 43.3374 92.9035 69.2749 77.2894 85.2872C61.6754 101.277 36.3468 101.277 20.7106 85.2872C5.09648 69.2977 5.09648 43.3601 20.7106 27.3479" stroke="#CED7DF" stroke-miterlimit="10" stroke-dasharray="4 4" />
		<path d="M66.5533 43.3322C66.7096 43.3322 66.866 43.3747 67 43.4383V56.2346C66.866 56.277 66.7096 56.3195 66.5533 56.3195H60.8351C57.0601 56.3195 54 53.4122 54 49.8258C54 46.2607 57.0601 43.3322 60.8351 43.3322H66.5533Z" fill="#CED7DF" />
		<path d="M67 25.5036V40.2226C66.8652 40.1776 66.7078 40.1776 66.5505 40.1776H60.7967C55.7622 40.1776 51.6716 44.2737 51.6716 49.3151C51.6716 54.3564 55.7622 58.4525 60.7967 58.4525H66.5505C66.7078 58.4525 66.8652 58.4525 67 58.4075V73.1265C67 74.882 65.584 76.2999 63.8309 76.2999H3.16907C1.41597 76.2999 0 74.882 0 73.1265V25.5036C0 23.7706 1.41597 22.3528 3.16907 22.3528H63.8309C65.584 22.3528 67 23.7706 67 25.5036Z" fill="#CED7DF" />
		<path d="M67.5799 43.7461V55.1351C67.5799 55.6022 67.3128 55.9804 66.912 56.1583C66.7784 56.2028 66.6225 56.2473 66.4666 56.2473H60.7665C57.0036 56.2473 53.9531 53.1999 53.9531 49.4406C53.9531 45.7035 57.0036 42.6339 60.7665 42.6339H66.4666C66.6225 42.6339 66.7784 42.6783 66.912 42.7451C67.3128 42.9008 67.5799 43.3012 67.5799 43.7461Z" fill="#7188A1" />
		<path d="M59.5 52.3234C60.8807 52.3234 62 51.2052 62 49.8258C62 48.4465 60.8807 47.3283 59.5 47.3283C58.1193 47.3283 57 48.4465 57 49.8258C57 51.2052 58.1193 52.3234 59.5 52.3234Z" fill="white" />
		<path d="M67 70.3808V73.1265C67 74.882 65.584 76.2999 63.8309 76.2999H3.16907C1.41597 76.2999 0 74.882 0 73.1265V25.5036C0 23.7706 1.41597 22.3528 3.16907 22.3528H7.86649C7.86649 22.3528 2.85442 59.6679 12.901 66.2847C20.9249 71.5736 54.0091 70.8759 67 70.3808Z" fill="#7188A1" />
		<path d="M54.175 0.374268H26.825C24.7025 0.374268 23 2.0566 23 4.15405V31.1588C23 33.2562 24.7025 34.9385 26.825 34.9385H32.1314V40.3351L39.693 34.9385H54.175C56.2975 34.9385 58 33.2562 58 31.1588V4.15405C58 2.0566 56.2754 0.374268 54.175 0.374268Z" fill="#00BE94" />
		<path
			d="M33 17.1484L38.1789 22.3528L48 11.3635"
			stroke="white"
			stroke-width="3"
			stroke-miterlimit="10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path d="M97.5683 50.9788C97.5683 50.9788 90.8762 48.7783 89.5163 48.3068C89.4299 48.2843 89.3867 48.2619 89.3651 48.2394C89.3651 48.2394 88.6312 44.4671 88.1347 43.4791C87.6598 42.4462 87.1848 41.7726 86.8394 41.4357C86.7099 41.3235 86.5588 41.3235 86.4077 41.3459C86.2566 41.3684 86.1055 41.4357 86.0191 41.5705C85.8033 41.9971 85.5874 42.8279 85.501 43.9506C85.3715 45.0509 85.9544 48.8681 85.9544 48.8681C85.9544 48.8681 85.9112 48.913 85.8464 48.9804C84.7239 49.9235 79.1976 54.4144 79.1976 54.4144C79.0681 54.4144 78.9817 54.5266 79.0033 54.6838L79.1544 55.5371C79.1976 55.6494 79.2839 55.7167 79.4135 55.7167L81.5074 54.4817L81.5506 54.5042L86.3214 51.6749H86.3645C86.4509 52.0566 86.5804 52.4384 86.6883 52.7752C87.1201 54.2796 87.5086 55.4248 87.8325 56.3904C88.1563 57.3559 88.4369 58.0969 88.6744 58.6133L88.5017 58.7256L88.1563 59.0175L88.221 59.0624L85.9328 61.016C85.8896 61.0833 85.8464 61.1282 85.868 61.2405L86.0191 62.1387C86.0407 62.2959 86.2134 62.3632 86.3645 62.2734L89.3004 60.5893L89.6674 60.0504L90.1854 60.4097L93.4883 60.9261C93.6394 60.9486 93.7905 60.8139 93.7473 60.6791L93.5962 59.781C93.5746 59.6911 93.5099 59.6462 93.4667 59.6013L90.682 58.6133L90.7251 58.546L90.315 58.3888L90.1207 58.3439C90.1854 57.7601 90.1855 56.9742 90.1639 55.9637C90.1423 54.9308 90.0991 53.7183 90.0128 52.1689C89.9912 51.8097 89.9912 51.4055 89.948 51.0237H89.9912L95.4095 51.9444L95.4311 51.8995L97.8057 52.3036C97.9352 52.2587 98 52.1689 98 52.0342L97.8489 51.1809C97.8057 51.0013 97.6978 50.9339 97.5683 50.9788Z" fill="#CED7DF" />
	</svg>
</template>
