<script setup lang="ts">
import { router, useForm } from '@inertiajs/vue3'
import { route } from '@/composables/route'
import { openDialog, useLoginDialog } from '~/ts/dialog'

const { isOpen, close, properties } = useLoginDialog()

const form = useForm({
	email: '',
	password: '',
})

function login() {
	form.post(route('captainjet.web.login.store'), {
		onSuccess: () => {
			close()
			form.reset()
			properties.value?.onSuccess?.()
		},
	})
}

function signup() {
	close()
	// Timeout is necessary: https://github.com/tailwindlabs/headlessui/issues/1744
	setTimeout(() => openDialog('register', {
		onSuccess: properties.value?.onSuccess,
	}), 250)
}

function recoverPassword() {
	close()
	openDialog('password-recovery')
}

function redirectAndClose() {
	if (document.location.href.endsWith('/login')) {
		router.get('/')
	}

	close()
}
</script>

<template>
	<BaseDialog :show="isOpen" title="Login" title-size="lg" @close="() => redirectAndClose()">
		<form @submit.prevent="login">
			<div class="p-6 pt-0">
				<div class="space-y-3.5">
					<BaseInput
						id="email"
						v-model="form.email"
						name="email"
						label="Email"
						type="email"
						:error="form.errors.email"
						@clear="form.clearErrors('email')"
					/>
					<BaseInput
						id="password"
						v-model="form.password"
						name="password"
						label="Password"
						type="password"
						:error="form.errors.password"
						@clear="form.clearErrors('password')"
					/>
				</div>

				<div class="mt-4 flex justify-center">
					<button
						type="button"
						class="flex font-display font-medium tracking-tighter text-blue-500 hover:text-blue-400 focus-visible:ring-2 focus-visible:ring-turquoise-600"
						@click="recoverPassword"
					>
						Forgot your password?
					</button>
				</div>

				<div class="mt-8">
					<button
						type="button"
						class="flex h-[45px] w-full items-center rounded-full bg-turquoise-100 px-6 font-display transition-colors duration-75 hover:opacity-80 focus-visible:ring-2 focus-visible:ring-turquoise-600"
						@click.prevent="signup"
					>
						<div class="flex flex-1 items-center space-x-5">
							<IconAircraftFlying class="size-6 text-blue-400" />
							<span class="leading-none text-blue-500">First time on CaptainJet?</span>
						</div>
						<div class="flex items-center space-x-3.5 text-turquoise-500">
							<span class="sr-only font-semibold md:not-sr-only">Sign up</span>
							<IconChevronRight class="h-3" />
						</div>
					</button>
				</div>

				<BaseButton
					type="submit"
					size="lg"
					variant="primary"
					class="mt-6 w-full font-display text-lg"
					:disabled="form.email === '' || form.password === ''"
					:loading="form.processing"
				>
					Login
				</BaseButton>
			</div>
		</form>
	</BaseDialog>
</template>
