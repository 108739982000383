export function sleep(ms: number): Promise<void> {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function invokeWithTimeout<T>(fn: () => Promise<T>, timeout: number): Promise<T> {
	return await Promise.any([
		sleep(timeout),
		fn(),
	]) as T
}
