<script setup lang="ts">
import { Step } from './Steps.vue'

const props = withDefaults(defineProps<{
	steps: Step[]
	maxScale?: number
	scaleRange?: number
	scaleCurvature?: number
}>(), {
	maxScale: 1.45,
	scaleRange: 1,
	scaleCurvature: 0.83,
})

const scrollable = ref<HTMLElement>()
const images = ref<HTMLElement[]>()

defineExpose({
	scrollable,
})

function updateScale() {
	if (!scrollable.value || !images.value) {
		return
	}

	// Calculate scroll progress
	const availableScroll = scrollable.value.scrollWidth - scrollable.value.clientWidth
	const scrollProgress = (images.value.length - 1) * scrollable.value.scrollLeft / availableScroll

	// Set scale of each image based on proximity to current scroll position
	for (let i = 0; i < images.value.length; ++i) {
		const proximity = (1 - Math.min(props.scaleRange, Math.abs(scrollProgress - i)) / props.scaleRange)
		const scale = 1 + (props.maxScale - 1) * Math.pow(proximity, props.scaleCurvature)
		images.value[i].style.transform = `scale(${scale})`
	}
}

onMounted(() => {
	updateScale()
	window.addEventListener('resize', updateScale)
})

onUnmounted(() => {
	window.removeEventListener('resize', updateScale)
})
</script>

<template>
	<div
		ref="scrollable"
		class="pointer-events-none -mt-44 flex h-[380px] w-screen overflow-x-scroll scrollbar-hide"
		@scroll="updateScale"
	>
		<div class="images flex shrink-0 items-center gap-[69px]">
			<img
				v-for="(step, i) in props.steps"
				ref="images"
				:key="i"
				class="w-[130px]"
				:srcSet="`${step.screenshot2x} 2x`"
				:src="step.screenshot"
				:alt="`CaptainJet app: ${step.title}`"
			/>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.images {
	padding: 0px calc(0.5 * (100vw - 130px));
}
</style>
