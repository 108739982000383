<template>
	<svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.5 4.5 6 9l8-8"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
