<template>
	<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8 6V1.5a1 1 0 0 0-2 0V6H1.5a1 1 0 0 0 0 2H6v4.5a1 1 0 1 0 2 0V8h4.5a1 1 0 1 0 0-2H8Z"
			fill="currentColor"
		/>
	</svg>
</template>
