<script setup lang="ts">
import { useSeoMeta } from '@vueuse/head'
import { breakpointsTailwind } from '@vueuse/core'
import { useUnreadChatMessage } from '~/ts/chat/unread'
import { openDialog } from '~/ts/dialog'
import { useProperty } from '@/composables/useProperty'
import ogImage from '~/images/captainjet/og.png'

const isChatEnabled = useProperty('security.features.chat')
const isIndex = computed(() => useProperty<string>('security.current_route').value === 'index')
const { unreadChatMessages } = useUnreadChatMessage()

useHead({
	titleTemplate: (title) => title ? `${title} - CaptainJet` : 'CaptainJet',
})

useSeoMeta({
	description: 'We offer a simple and quick private charter flight service with the most transparent prices on the market and no long-term binding agreements.',
	ogDescription: 'We offer a simple and quick private charter flight service with the most transparent prices on the market and no long-term binding agreements.',
	ogTitle: 'CaptainJet',
	ogImage,
	twitterCard: 'summary_large_image',
})

const { arrivedState } = useScroll(window, { offset: { bottom: 30 } })
const breakpoint = useBreakpoints(breakpointsTailwind)
</script>

<template>
	<!-- Page -->
	<div class="relative flex min-h-screen w-full flex-1 flex-col bg-turquoise-50 font-sans">
		<slot />

		<!-- Chat bubble -->
		<div
			v-if="isChatEnabled"
			class="fixed bottom-5 right-5 z-chat-bubble transition duration-300 ease-in-out md:bottom-10 md:right-10"
			:class="{
				'-translate-y-28': arrivedState.bottom && breakpoint.isGreater('lg') && isIndex
			}"
		>
			<button
				class="rounded-full bg-turquoise-500 p-3.5 shadow-md transition hover:bg-turquoise-400 hover:shadow-lg"
				@click.prevent="openDialog('chat')"
			>
				<IconMessage class="size-6 text-white" />
				<span
					v-if="unreadChatMessages"
					class="absolute -bottom-0 right-0.5 size-4.5 rounded-full bg-red-700 text-xs font-bold text-red-100"
					v-text="unreadChatMessages"
				/>
			</button>
		</div>
	</div>

	<Feedback />
</template>
