<template>
	<svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M70.047 38.5605V61.1409C70.047 62.926 68.6063 64.3667 66.8213 64.3667H27.3508C25.5657 64.3667 24.125 62.926 24.125 61.1409V38.5605C24.125 36.7754 25.5657 35.3347 27.3508 35.3347H66.8213C68.6063 35.3347 70.047 36.7754 70.047 38.5605Z" fill="#9EC9D4" />
		<path d="M70.047 40.2188H24.125V43.6892H70.047V40.2188Z" fill="#8CA0B4" />
		<path opacity="0.5" d="M59.826 35.3347V54.9793C59.826 56.7644 58.3853 58.2051 56.6003 58.2051H24.125V38.5605C24.125 36.7754 25.5657 35.3347 27.3508 35.3347H59.826Z" fill="#5EA6B7" />
		<path d="M54.5595 56.0125H15.0891C13.304 56.0125 11.8633 54.5717 11.8633 52.7867V30.2062C11.8633 28.4212 13.304 26.9805 15.0891 26.9805H54.5595C56.3446 26.9805 57.7853 28.4212 57.7853 30.2062V52.7867C57.7853 54.5627 56.3355 56.0125 54.5595 56.0125Z" fill="#BDDBE3" />
		<path d="M20.0458 37.6092C21.6072 37.6092 22.8729 36.3435 22.8729 34.7822C22.8729 33.2208 21.6072 31.9551 20.0458 31.9551C18.4845 31.9551 17.2188 33.2208 17.2188 34.7822C17.2188 36.3435 18.4845 37.6092 20.0458 37.6092Z" fill="#8CA0B4" />
		<path d="M24.0146 37.6092C25.5759 37.6092 26.8417 36.3435 26.8417 34.7822C26.8417 33.2208 25.5759 31.9551 24.0146 31.9551C22.4532 31.9551 21.1875 33.2208 21.1875 34.7822C21.1875 36.3435 22.4532 37.6092 24.0146 37.6092Z" fill="#E3EBEE" />
		<path d="M21.5862 51.799H18.1973C17.6537 51.799 17.2188 51.3641 17.2188 50.8204C17.2188 50.2767 17.6537 49.8418 18.1973 49.8418H21.5862C22.1299 49.8418 22.5648 50.2767 22.5648 50.8204C22.5648 51.3641 22.1209 51.799 21.5862 51.799Z" fill="#9EC9D4" />
		<path d="M29.0511 51.799H25.6622C25.1185 51.799 24.6836 51.3641 24.6836 50.8204C24.6836 50.2767 25.1185 49.8418 25.6622 49.8418H29.0511C29.5948 49.8418 30.0297 50.2767 30.0297 50.8204C30.0297 51.3641 29.5857 51.799 29.0511 51.799Z" fill="#9EC9D4" />
		<path d="M36.5198 51.799H33.131C32.5873 51.799 32.1523 51.3641 32.1523 50.8204C32.1523 50.2767 32.5873 49.8418 33.131 49.8418H36.5198C37.0635 49.8418 37.4985 50.2767 37.4985 50.8204C37.4894 51.3641 37.0544 51.799 36.5198 51.799Z" fill="#9EC9D4" />
		<path d="M43.9847 51.799H40.5958C40.0521 51.799 39.6172 51.3641 39.6172 50.8204C39.6172 50.2767 40.0521 49.8418 40.5958 49.8418H43.9847C44.5284 49.8418 44.9633 50.2767 44.9633 50.8204C44.9542 51.3641 44.5193 51.799 43.9847 51.799Z" fill="#9EC9D4" />
		<path d="M51.4534 51.799H48.0646C47.5209 51.799 47.0859 51.3641 47.0859 50.8204C47.0859 50.2767 47.5209 49.8418 48.0646 49.8418H51.4534C51.9971 49.8418 52.432 50.2767 52.432 50.8204C52.423 51.3641 51.988 51.799 51.4534 51.799Z" fill="#9EC9D4" />
		<path d="M65.9887 61.0595H62.3733C61.9746 61.0595 61.6484 60.7333 61.6484 60.3346V58.178C61.6484 57.7793 61.9746 57.4531 62.3733 57.4531H65.9887C66.3874 57.4531 66.7136 57.7793 66.7136 58.178V60.3346C66.7136 60.7333 66.3874 61.0595 65.9887 61.0595Z" fill="#E3EBEE" />
		<path d="M54.5035 17.3756C55.0339 17.3756 55.4639 16.9455 55.4639 16.4151C55.4639 15.8846 55.0339 15.4546 54.5035 15.4546C53.973 15.4546 53.543 15.8846 53.543 16.4151C53.543 16.9455 53.973 17.3756 54.5035 17.3756Z" fill="#E3EBEE" />
		<path d="M64.1839 26.9804C65.3749 26.9804 66.3405 26.0148 66.3405 24.8238C66.3405 23.6328 65.3749 22.6672 64.1839 22.6672C62.9929 22.6672 62.0273 23.6328 62.0273 24.8238C62.0273 26.0148 62.9929 26.9804 64.1839 26.9804Z" fill="#E3EBEE" />
		<path d="M14.6464 65.3274C15.4521 65.3274 16.1052 64.6742 16.1052 63.8685C16.1052 63.0628 15.4521 62.4097 14.6464 62.4097C13.8407 62.4097 13.1875 63.0628 13.1875 63.8685C13.1875 64.6742 13.8407 65.3274 14.6464 65.3274Z" fill="#E3EBEE" />
	</svg>
</template>
