<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.687 4.847c.073-.074.146-.22.146-.294 0-.22-.146-.44-.366-.44H5.873c-.073 0-.22.074-.22.147-.073.073-.146.147-.146.293 0 .22.146.44.366.44h3.52c.147 0 .22-.073.294-.146ZM11.227 6.753c0 .074-.074.22-.147.294a.56.56 0 0 1-.367.146H6.02c-.293 0-.513-.22-.513-.44 0-.146.073-.22.146-.293a.56.56 0 0 1 .367-.147h4.767c.293 0 .44.22.44.44ZM11.08 9.1c.073-.073.147-.22.147-.293 0-.22-.147-.367-.44-.44H6.02a.56.56 0 0 0-.367.146c-.073.074-.146.147-.146.294 0 .22.22.44.513.44h4.693a.56.56 0 0 0 .367-.147Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M13.94 12.767h.953c.294 0 .514.366.44.66-.293.953-.953 1.906-1.833 1.906H4.847A1.815 1.815 0 0 1 3.013 13.5V2.867H1.107c-.294 0-.514-.294-.44-.587A2.233 2.233 0 0 1 1.62.96c0-.073.073-.073.073-.073h.074l.06-.02c.224-.078.583-.2 1.186-.2h8.947c1.1 0 1.98.806 1.98 2.2v9.9ZM4.847 14.38h.88l.073-.147c.22-.293.587-1.393.587-1.393h6.526V2.5c0-.513-.44-.88-1.026-.88H3.82c.073.073.073.147.073.22V13.5c0 .44.44.88.954.88Zm1.54.293H13.5c.367 0 .88-.513 1.1-1.246H6.9c-.073.44-.293.88-.513 1.246Z"
			fill="currentColor"
		/>
	</svg>
</template>
