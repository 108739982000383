<script setup lang="ts">
import LegData = Domain.BookingCaptainJet.Data.LegData

defineProps<{
	leg: LegData
}>()

function rateFlight() {

}
</script>

<template>
	<BookingLegInput
		:leg="leg"
		type="archived"
	>
		<template #default>
			<BaseButton
				v-if="false"
				variant="secondary"
				size="md"
				class="space-x-2 px-4"
				icon="StarSolid"
				icon-class="w-5 h-5"
				@click="rateFlight"
			>
				Rate this Flight
			</BaseButton>
		</template>
	</BookingLegInput>
</template>
