<template>
	<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.989 6.22c.322.84.499 1.754.499 2.708a7.56 7.56 0 0 1-7.56 7.56 7.56 7.56 0 0 1-7.56-7.56 7.56 7.56 0 0 1 7.56-7.56c1.272 0 2.47.314 3.522.869l1.009-1.009A8.974 8.974 0 0 0 8.928 0C6.552 0 4.32.936 2.592 2.592.936 4.32 0 6.552 0 8.928c0 2.376.936 4.608 2.592 6.336 1.656 1.656 3.96 2.592 6.336 2.592 2.376 0 4.608-.936 6.336-2.592 1.656-1.728 2.592-3.96 2.592-6.336a8.944 8.944 0 0 0-.823-3.751L15.989 6.22Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.1 1.071c.24.24.24.627 0 .866l-3.867 3.868a.613.613 0 0 1-.866 0l-1.82-1.82a.613.613 0 0 1 .866-.867L12.8 4.505l3.434-3.434c.24-.24.627-.24.866 0Z"
			fill="currentColor"
		/>
		<path
			d="M11.188 6.283a2.59 2.59 0 0 0-.83.349c-.34.2-1.33 1.144-1.33 1.144-.033.01-3.068-.875-3.068-.875a.087.087 0 0 0-.125 0l-.215.215a.087.087 0 0 0-.007.117L8.27 8.461l.012.014v.001a15.37 15.37 0 0 0-.269.329c-.655.834-1.049 1.387-1.289 1.753a.088.088 0 0 1-.107.034l-1.236-.322a.08.08 0 0 0-.084.02l-.234.234a.083.083 0 0 0 .02.13l1.239.586a.104.104 0 0 0 .035.01l.016.006a.02.02 0 0 0 0 .014c0 .012.004.024.01.034l.586 1.24a.082.082 0 0 0 .13.02l.235-.234a.081.081 0 0 0 .019-.084l-.322-1.237-.023-.066c.183-.115.415-.273.712-.488.148-.108 1.037-.785 1.098-.834.115-.09.224-.18.33-.267l.014.01 1.17 2.55a.088.088 0 0 0 .115-.01l.215-.214a.09.09 0 0 0 0-.125c-.01-.034-.826-2.925-.816-2.957 0 0 .954-1.002 1.156-1.348v-.005c.157-.248.27-.521.335-.808a.144.144 0 0 0-.15-.164Z"
			fill="currentColor"
		/>
	</svg>
</template>
