<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999999 31.999998"
	>
		<path
			d="m 5.0002,1029.4 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 22,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 l -22,0 z m 0,4 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 22,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 l -22,0 z m 0,4 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 22,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 l -22,0 z m 0,4 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 22,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 l -22,0 z"
			transform="translate(0 -1021.4)"
		/>
	</svg>
</template>
