<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999999 31.999998"
	>
		<path
			d="m24.608 1028.4c-0.94188-0.027-1.8841 0.3235-2.582 1.0215-0.98167 0.9425 0.47155 2.3957 1.4141 1.414 0.48348-0.4834 1.2184-0.5801 1.8106-0.2382 0.59214 0.3418 0.87618 1.027 0.69922 1.6875-0.17697 0.6604-0.76548 1.1113-1.4492 1.1113h-19.5c-0.55228 0-1 0.4477-1 1s0.44772 0.9999 1 1h19.5c1.5793 0 2.9721-1.0683 3.3809-2.5938 0.40875-1.5254-0.26316-3.1478-1.6309-3.9375-0.51289-0.2961-1.0774-0.4484-1.6426-0.4648zm-19.608 8.9995c-0.55228 0-1 0.4478-1 1 0 0.5523 0.44772 1 1 1h11.5c0.68374 0 1.2722 0.4509 1.4492 1.1114 0.17696 0.6604-0.10708 1.3456-0.69922 1.6875-0.59214 0.3418-1.3271 0.2452-1.8106-0.2383-0.94251-0.9817-2.3957 0.4715-1.4141 1.414 1.1167 1.1168 2.8569 1.3463 4.2246 0.5567 1.3677-0.7897 2.0396-2.412 1.6309-3.9375-0.40875-1.5254-1.8016-2.5938-3.3809-2.5938z"
			transform="translate(0 -1020.4)"
		/>
	</svg>
</template>
