<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999999 31.999998"
	>
		<path
			d="m10.748 1022.4c0.1649 0.6414 0.24953 1.3007 0.25195 1.9629 0 4.4183-3.5817 8-8 8-0.24979-0.0007-0.49941-0.013-0.74805-0.037 0.45224 1.7708 1.4972 3.3334 2.9609 4.4277-0.76071 1.0074-1.2126 2.2576-1.2129 3.6094 0 3.3137 2.6863 6 6 6h15.5c3.5898 0 6.5-2.9101 6.5-6.5 0-3.5898-2.9102-6.5-6.5-6.5-0.49867 0-0.99543 0.062-1.4805 0.1777-1.248-2.2947-3.5282-3.8092-6.084-4.1132-0.44132-3.7431-3.4354-6.6705-7.1875-7.0274zm2.0996 2.709c1.6249 0.8766 2.7857 2.4576 3.0762 4.3359-2.8188 0.3853-5.2582 2.2436-6.3398 4.9336-1.0593 0.074-2.0342 0.4243-2.8672 0.9707-0.57342-0.3884-1.0794-0.8749-1.4961-1.4375 4.1598-0.9832 7.2836-4.4382 7.627-8.8027zm4.1523 6.2539c2.8668 0 5.3313 2.0325 5.8828 4.8457 0.76276-0.5481 1.6779-0.8438 2.6172-0.8457 2.4853 0 4.5 2.0147 4.5 4.5s-2.0147 4.5-4.5 4.5h-15.5c-2.2091 0-4-1.7909-4-4s1.7909-4 4-4c0.36119 0 0.72057 0.051 1.0684 0.1484 0.4232-2.9516 2.9498-5.1446 5.9316-5.1484z"
			transform="translate(0 -1020.4)"
		/>
	</svg>
</template>
