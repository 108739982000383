<template>
	<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.65 18.635 22.06 2.17 24.748 0l2.688 2.17 20.411 16.465c-.36.326-.84.526-1.37.526H3.02c-.529 0-1.01-.2-1.37-.526Z"
			fill="#8EA1B4"
		/>
		<path
			d="M48.5 20.104v27.913c0 .667-.336 1.258-.853 1.616-.33.231-.734.366-1.169.366H3.02c-.437 0-.841-.135-1.17-.366A1.968 1.968 0 0 1 1 48.017V20.104c0-.577.25-1.098.65-1.458.36-.326.842-.526 1.37-.526h43.458c.528 0 1.01.2 1.37.526.401.36.652.88.652 1.458Z"
			fill="#8EA1B4"
		/>
		<path
			d="M47.847 18.646 37.37 27.098l-9.931 8.014-2.688 2.17-2.688-2.17-9.934-8.014L1.65 18.646c.36-.326.841-.526 1.37-.526h43.458c.528 0 1.01.2 1.37.526Z"
			fill="#7289A1"
		/>
		<path
			d="M47.647 49.634c-.33.23-.734.366-1.169.366H3.02c-.437 0-.841-.135-1.17-.366l20.212-14.521 2.688-1.931 2.688 1.93 20.21 14.522Z"
			fill="#7289A1"
		/>
		<path
			d="M37.37 12.941V27.1l-9.932 8.013-.459.37-2.23 1.8-2.687-2.17-9.934-8.013V12.94c0-.52.43-.943.961-.943h23.319c.532 0 .961.423.961.943Z"
			fill="#D4E5E9"
		/>
		<path
			d="M37.37 12.941V27.1l-9.932 8.013-.458.37-2.38-1.92-9.933-8.013V11.998h21.742c.532 0 .961.423.961.943Z"
			fill="#fff"
		/>
		<path d="m1.65 18.646 23.098 16.962 23.1-16.962L24.75 37.281 1.65 18.646Z" fill="#CFD8E0" />
		<path
			d="M28.997 20.85c0-.447-.278-.779-.738-.828-.253-.027-.294-.125-.308-.335-.13-1.957-2.157-3.21-4.038-2.474-1.168.455-1.773 1.333-1.87 2.533-.01.11.034.232-.171.256-.612.07-.863.364-.864.954 0 1.364.017 2.719-.008 4.077-.011.637.465.977 1.014.967.994-.017 1.99 0 2.985 0h3.08c.549 0 .917-.35.918-.863.004-1.43.004-2.86 0-4.288Zm-3.479 2.72c-.012.28-.196.423-.527.421-.315 0-.498-.145-.504-.418a25.26 25.26 0 0 1 0-1.155c.007-.273.197-.415.511-.415.315 0 .504.146.518.412.01.196 0 .392 0 .589 0 .196.01.378.002.566Zm1.17-3.573c-.569-.006-1.138 0-1.707 0-.544 0-1.089-.006-1.633 0-.18 0-.249-.04-.243-.23a1.8 1.8 0 0 1 .587-1.248c.357-.328.832-.51 1.326-.51.494.002.968.186 1.324.516.355.329.563.777.581 1.25.008.176-.067.227-.235.222Z"
			fill="#61A7B7"
		/>
	</svg>
</template>
