<template>
	<svg viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.522 3.243c.004.003.005.008.01.011.034.039.079.063.126.074.937.224 9.983 2.363 10.701 2.664.798.333 2.856 2.074 2.736 2.41-.116.337-12.129-2.912-13.727-3.421-.731-.231-1.664-2.179-1.8-2.468a.076.076 0 0 0-.055-.045l-.417-.107a.076.076 0 0 1-.051-.036l-.303-.486c-.036-.06.01-.137.078-.128l1.706.144a.086.086 0 0 1 .063.034l.933 1.354Z"
			fill="currentColor"
		/>
		<path
			d="M7.752 3.75 5.75 1.09c-.05-.061.011-.154.085-.133l1.013.261a.09.09 0 0 1 .035.017l3.364 3.011c.066.06.007.165-.075.145l-2.373-.607a.119.119 0 0 1-.046-.033ZM17.255 13.56H.745c-.411 0-.745.314-.745.702 0 .388.334.703.745.703h16.51c.411 0 .745-.315.745-.703 0-.388-.334-.702-.745-.702Z"
			fill="currentColor"
		/>
	</svg>
</template>
