<template>
	<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.103 4.17A2.445 2.445 0 1 1 9.82 8.233a2.445 2.445 0 0 1-2.718-4.065Zm2.536 1.788h.781a1.978 1.978 0 0 0-1.14-1.542 3.7 3.7 0 0 1 .36 1.542Zm-1.875.48c.058 1.082.429 1.722.698 1.722v.032c.268 0 .652-.691.704-1.754H7.764Zm.698-2.208c-.27 0-.653.666-.698 1.728h1.402C9.102 4.87 8.73 4.23 8.462 4.23ZM7.284 5.958a3.7 3.7 0 0 1 .359-1.542 1.959 1.959 0 0 0-1.159 1.542h.8Zm0 .48h-.8a1.958 1.958 0 0 0 1.159 1.543 3.725 3.725 0 0 1-.359-1.543Zm2.355 0a3.725 3.725 0 0 1-.358 1.543 1.977 1.977 0 0 0 1.14-1.543h-.782Z"
			fill="currentColor"
		/>
		<path
			d="M6.535 10.752h3.84a.243.243 0 0 0 0-.48h-3.84a.243.243 0 0 0 0 .48ZM9.767 11.904h-2.61a.243.243 0 0 1-.238-.237.237.237 0 0 1 .237-.237h2.611a.23.23 0 0 1 .237.237.237.237 0 0 1-.237.237Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.154.64h8.615a1.095 1.095 0 0 1 1.05 1.094v12.218c.006.072.006.145 0 .218a1.1 1.1 0 0 1-1.076.876H4.13a1.093 1.093 0 0 1-1.069-.876.813.813 0 0 1 0-.218V1.734A1.094 1.094 0 0 1 4.154.64Zm.007 13.312h8.614V1.734H4.161v12.218Z"
			fill="currentColor"
		/>
	</svg>
</template>
