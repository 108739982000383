<template>
	<svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="25" height="16" rx="3" fill="white" />
		<path d="M4.5 6C5.32843 6 6 5.32843 6 4.5C6 3.67157 5.32843 3 4.5 3C3.67157 3 3 3.67157 3 4.5C3 5.32843 3.67157 6 4.5 6Z" fill="#4F6B89" />
		<path d="M6.5 6C7.32843 6 8 5.32843 8 4.5C8 3.67157 7.32843 3 6.5 3C5.67157 3 5 3.67157 5 4.5C5 5.32843 5.67157 6 6.5 6Z" fill="#8EA1B4" />
		<path d="M5.3582 13.6778H3.4905C3.19087 13.6778 2.95117 13.4381 2.95117 13.1385C2.95117 12.8388 3.19087 12.5991 3.4905 12.5991H5.3582C5.65783 12.5991 5.89752 12.8388 5.89752 13.1385C5.89752 13.4381 5.65283 13.6778 5.3582 13.6778Z" fill="#8EA1B4" />
		<path d="M9.47245 13.6778H7.60477C7.30514 13.6778 7.06543 13.4381 7.06543 13.1385C7.06543 12.8388 7.30514 12.5991 7.60477 12.5991H9.47245C9.77208 12.5991 10.0118 12.8388 10.0118 13.1385C10.0118 13.4381 9.76709 13.6778 9.47245 13.6778Z" fill="#8EA1B4" />
		<path d="M13.5887 13.6778H11.721C11.4214 13.6778 11.1816 13.4381 11.1816 13.1385C11.1816 12.8388 11.4214 12.5991 11.721 12.5991H13.5887C13.8883 12.5991 14.128 12.8388 14.128 13.1385C14.123 13.4381 13.8833 13.6778 13.5887 13.6778Z" fill="#8EA1B4" />
		<path d="M17.7029 13.6778H15.8352C15.5356 13.6778 15.2959 13.4381 15.2959 13.1385C15.2959 12.8388 15.5356 12.5991 15.8352 12.5991H17.7029C18.0026 12.5991 18.2422 12.8388 18.2422 13.1385C18.2373 13.4381 17.9976 13.6778 17.7029 13.6778Z" fill="#8EA1B4" />
		<path d="M21.8191 13.6778H19.9515C19.6518 13.6778 19.4121 13.4381 19.4121 13.1385C19.4121 12.8388 19.6518 12.5991 19.9515 12.5991H21.8191C22.1188 12.5991 22.3585 12.8388 22.3585 13.1385C22.3535 13.4381 22.1138 13.6778 21.8191 13.6778Z" fill="#8EA1B4" />
	</svg>
</template>
