<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999998 31.999999"
	>
		<path
			d="m15 1027.4c-3.2637 0-6.1985 1.9876-7.416 5.0156-3.1442 0.2186-5.5834 2.8326-5.584 5.9844 0 3.3137 2.6863 6 6 6h15.5c3.5898 0 6.5-2.9101 6.5-6.5 0-3.5898-2.9102-6.5-6.5-6.5-0.49867 0-0.99543 0.062-1.4805 0.1777-1.399-2.5722-4.0915-4.1747-7.0195-4.1777zm0 2c2.8668 0 5.3313 2.0325 5.8828 4.8457 0.76276-0.5481 1.6779-0.8438 2.6172-0.8457 2.4853 0 4.5 2.0147 4.5 4.5s-2.0147 4.5-4.5 4.5h-15.5c-2.2091 0-4-1.7909-4-4s1.7909-4 4-4c0.36119 0.001 0.72057 0.051 1.0684 0.1484 0.4232-2.9516 2.9498-5.1446 5.9316-5.1484z"
			transform="translate(0 -1020.4)"
		/>
	</svg>
</template>
