<script setup lang="ts">
const emit = defineEmits<{
	(e: 'update:modelValue', text: string): void
	(e: 'clear'): void
}>()

const props = withDefaults(defineProps<{
	modelValue?: string | number
	label?: string
	type?: string
	id?: string
	name?: string
	error?: string
	clearable?: boolean
	readonly?: boolean
	value?: string | number
	step?: number
	autocomplete?: string
	placeholder?: string
}>(), {
	readonly: false,
	type: 'text',
	step: 1,
})

const inputRef = ref()
const showPassword = ref(false)

const clearInput = () => {
	inputRef.value.focus()
	emit('update:modelValue', '')
	emit('clear')
}

const inputType = computed(() => {
	return props.type === 'password' && showPassword.value ? 'text' : props.type
})
</script>

<template>
	<div v-auto-animate class="space-y-1">
		<div
			class="flex overflow-hidden rounded-xl bg-turquoise-50"
			:class="{
				'focus-within:ring-1 focus-within:ring-turquoise-600': !readonly,
			}"
		>
			<div class="flex w-full flex-col py-2 pl-3.5" :class="{ 'opacity-80': readonly }">
				<label
					:for="id"
					class="flex font-display text-sm font-medium text-blue-300"
					v-text="label"
				/>
				<component
					:is="inputType === 'textarea' ? 'textarea' : 'input'"
					:id="id"
					ref="inputRef"
					:readonly="readonly"
					:name="name"
					:value="value || modelValue"
					rows="3"
					:placeholder="placeholder"
					:type="inputType"
					:autocomplete="autocomplete"
					:step="inputType === 'number' ? step : undefined"
					:class="{ 'cursor-default text-blue-500': readonly }"
					class="resize-none overflow-hidden bg-transparent font-medium tracking-tighter text-blue-500 caret-blue-500"
					@input="$emit('update:modelValue', ($event.target as HTMLInputElement)?.value)"
				/>
			</div>
			<div v-if="clearable || type === 'password'" class="flex items-center px-2.5">
				<button
					v-if="type === 'password'"
					type="button"
					class="rounded-full p-2 text-blue-500 ring-blue-500 focus-visible:ring-1"
					tabindex="-1"
					@click="showPassword = !showPassword"
				>
					<IconEyeOff v-if="!showPassword" class="size-3.5" />
					<IconEye v-if="showPassword" class="size-3.5" />
				</button>
				<button v-if="clearable && modelValue" tabindex="-1" class="rounded-full p-2 text-blue-500 ring-blue-500 focus-visible:ring-1" type="button" @click="clearInput">
					<IconX class="w-2" />
				</button>
			</div>
		</div>
		<div v-if="error" class="flex items-center space-x-2 text-sm font-medium text-danger-500">
			<IconExclamationCircle class="size-3.5 shrink-0" />
			<span>{{ error }}</span>
		</div>
	</div>
</template>
