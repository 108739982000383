<template>
	<svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M53.451 40.0355L53.0533 39.417C53.8559 38.9016 54.607 38.3051 55.2771 37.6277L55.7999 38.1505C55.093 38.8574 54.2978 39.498 53.451 40.0355ZM34.2031 35.2125C33.7097 34.3289 33.3121 33.3937 33.0249 32.4291L33.7318 32.2156C34.0043 33.1287 34.3798 34.0123 34.851 34.8443L34.2031 35.2125ZM58.4065 34.4541L57.7438 34.1374C58.1562 33.2759 58.4728 32.3702 58.6937 31.4424L59.4079 31.6118C59.1797 32.5838 58.8483 33.541 58.4065 34.4541ZM33.209 27.9743L32.4727 27.9448C32.5168 26.936 32.6715 25.9346 32.9366 24.9627L33.6435 25.1541C33.3931 26.0745 33.2458 27.0244 33.209 27.9743ZM58.9514 27.1643C58.8483 26.2144 58.6422 25.2793 58.3329 24.3736L59.0324 24.138C59.3564 25.0952 59.5773 26.0819 59.6804 27.0907L58.9514 27.1643ZM35.4328 21.2663L34.8216 20.8539C35.3886 20.0218 36.0513 19.2487 36.7876 18.5565L37.2883 19.094C36.5888 19.7494 35.9629 20.4784 35.4328 21.2663ZM56.308 20.6109C55.7263 19.8525 55.0562 19.1677 54.3198 18.5565L54.7911 17.9895C55.5643 18.6301 56.2785 19.3591 56.897 20.1544L56.308 20.6109ZM40.8522 16.7304L40.5503 16.0603C41.4707 15.648 42.4427 15.3387 43.4294 15.1399L43.5767 15.8615C42.6415 16.053 41.7211 16.3401 40.8522 16.7304ZM50.6161 16.4285C49.7251 16.0971 48.7899 15.8615 47.8474 15.729L47.9431 15C48.9446 15.1399 49.9313 15.3829 50.8738 15.7363L50.6161 16.4285Z" fill="#CCD5DD" />
		<path d="M58.4158 66.9993H17.7552C16.6139 66.9993 15.7818 65.939 15.907 64.621L18.0497 41.5073C18.1749 40.1966 19.1984 39.1289 20.3398 39.1289H61.0004C62.1417 39.1289 62.9738 40.1892 62.8486 41.5073L60.7059 64.621C60.5807 65.9316 59.5572 66.9993 58.4158 66.9993Z" fill="#7EB7C6" />
		<path d="M45.1119 57.2291L47.1295 55.2115L49.1471 57.2291L51.1646 55.2115L53.1822 57.2291L55.1998 55.2115L57.2173 57.2291L59.2349 55.2115L61.2525 57.2291L63.27 55.2115L65.2876 57.2291L67.3052 55.2115L69.2933 57.1997V24.4105C69.2933 23.5637 68.6085 22.8789 67.7617 22.8789H42.5863C41.7395 22.8789 41.0547 23.5637 41.0547 24.4105V57.2659L43.1164 55.2042L45.1119 57.2291Z" fill="#EBEEF2" />
		<path d="M60.4614 32.8995H44.0042C43.6213 32.8995 43.3047 32.5829 43.3047 32.2C43.3047 31.8171 43.6213 31.5005 44.0042 31.5005H60.4614C60.8443 31.5005 61.1609 31.8171 61.1609 32.2C61.1609 32.5903 60.8517 32.8995 60.4614 32.8995Z" fill="#CED7DF" />
		<path d="M66.3155 32.8768H63.5763C63.2008 32.8768 62.9062 32.5749 62.9062 32.2067C62.9062 31.8312 63.2082 31.5366 63.5763 31.5366H66.3155C66.691 31.5366 66.9856 31.8385 66.9856 32.2067C66.9856 32.5749 66.6837 32.8768 66.3155 32.8768Z" fill="#CED7DF" />
		<path d="M60.4614 36.4999H44.0042C43.6213 36.4999 43.3047 36.1833 43.3047 35.8004C43.3047 35.4175 43.6213 35.1008 44.0042 35.1008H60.4614C60.8443 35.1008 61.1609 35.4175 61.1609 35.8004C61.1609 36.1833 60.8517 36.4999 60.4614 36.4999Z" fill="#CED7DF" />
		<path d="M66.3155 36.471H63.5763C63.2008 36.471 62.9062 36.1691 62.9062 35.8009C62.9062 35.4254 63.2082 35.1309 63.5763 35.1309H66.3155C66.691 35.1309 66.9856 35.4328 66.9856 35.8009C66.9856 36.1691 66.6837 36.471 66.3155 36.471Z" fill="#CED7DF" />
		<path d="M60.4614 40.1012H44.0042C43.6213 40.1012 43.3047 39.7846 43.3047 39.4017C43.3047 39.0188 43.6213 38.7021 44.0042 38.7021H60.4614C60.8443 38.7021 61.1609 39.0188 61.1609 39.4017C61.1609 39.7846 60.8517 40.1012 60.4614 40.1012Z" fill="#CED7DF" />
		<path d="M66.3155 40.0723H63.5763C63.2008 40.0723 62.9062 39.7704 62.9062 39.4022C62.9062 39.0267 63.2082 38.7322 63.5763 38.7322H66.3155C66.691 38.7322 66.9856 39.0341 66.9856 39.4022C66.9856 39.7704 66.6837 40.0723 66.3155 40.0723Z" fill="#CED7DF" />
		<path d="M60.4614 43.6959H44.0042C43.6213 43.6959 43.3047 43.3793 43.3047 42.9964C43.3047 42.6135 43.6213 42.2969 44.0042 42.2969H60.4614C60.8443 42.2969 61.1609 42.6135 61.1609 42.9964C61.1609 43.3866 60.8517 43.6959 60.4614 43.6959Z" fill="#CED7DF" />
		<path d="M66.3155 43.6731H63.5763C63.2008 43.6731 62.9062 43.3712 62.9062 43.0031C62.9062 42.6275 63.2082 42.333 63.5763 42.333H66.3155C66.691 42.333 66.9856 42.6349 66.9856 43.0031C66.9856 43.3712 66.6837 43.6731 66.3155 43.6731Z" fill="#CED7DF" />
		<path d="M60.4614 47.296H44.0042C43.6213 47.296 43.3047 46.9794 43.3047 46.5965C43.3047 46.2136 43.6213 45.897 44.0042 45.897H60.4614C60.8443 45.897 61.1609 46.2136 61.1609 46.5965C61.1609 46.9868 60.8517 47.296 60.4614 47.296Z" fill="#CED7DF" />
		<path d="M66.3155 47.2732H63.5763C63.2008 47.2732 62.9062 46.9713 62.9062 46.6032C62.9062 46.2276 63.2082 45.9331 63.5763 45.9331H66.3155C66.691 45.9331 66.9856 46.235 66.9856 46.6032C66.9856 46.9713 66.6837 47.2732 66.3155 47.2732Z" fill="#CED7DF" />
		<path d="M48.0766 28.6882H44.1372C43.6807 28.6882 43.3125 28.3201 43.3125 27.8635V26.251C43.3125 25.7944 43.6807 25.4263 44.1372 25.4263H48.0766C48.5331 25.4263 48.9013 25.7944 48.9013 26.251V27.8635C48.8939 28.3201 48.5258 28.6882 48.0766 28.6882Z" fill="#CED7DF" />
		<path d="M59.3248 51.1912H66.5556C66.7986 51.1912 66.9975 50.9924 66.9975 50.7494V50.0131C66.9975 49.7701 66.7986 49.5713 66.5556 49.5713H59.3248C59.0818 49.5713 58.883 49.7701 58.883 50.0131V50.7494C58.8756 50.9924 59.0744 51.1912 59.3248 51.1912Z" fill="#8CA0B4" />
		<path d="M56.8528 38.7762H29.4021L27.9294 36.9353C27.9294 36.3757 27.4802 35.9265 26.9206 35.9265H19.7781C19.2185 35.9265 18.7693 36.3757 18.7693 36.9353V38.7762H17.5691C16.4278 38.7762 15.5 39.7039 15.5 40.8453V64.9309C15.5 66.0722 16.4278 67 17.5691 67H56.8602C58.0015 67 58.9293 66.0722 58.9293 64.9309V40.8453C58.9219 39.7039 57.9941 38.7762 56.8528 38.7762Z" fill="#BDDBE3" />
		<path d="M40.2089 56.3378C40.2089 56.3378 39.5094 53.0905 39.3842 52.4205C39.3768 52.3836 39.3768 52.3542 39.3768 52.3468C39.3768 52.3468 40.6949 51.1908 40.9821 50.771C41.2766 50.3513 41.4386 49.9905 41.4901 49.777C41.5048 49.7034 41.4754 49.6297 41.4165 49.5782C41.3723 49.5193 41.3134 49.4751 41.2324 49.4751C41.0115 49.4825 40.636 49.5782 40.1647 49.7917C39.6935 49.9905 38.3239 51.0803 38.3239 51.0803C38.3165 51.0803 38.2944 51.0729 38.2576 51.0582C37.6244 50.8152 34.5464 49.5414 34.5464 49.5414C34.517 49.4898 34.4507 49.4824 34.3992 49.5266L34.0973 49.777C34.0605 49.8212 34.0605 49.8727 34.0899 49.9316L35.0693 50.5133V50.5354L37.3004 51.8608L37.3151 51.8829C37.1899 52.0007 37.0647 52.1406 36.9543 52.2658C36.4609 52.7812 36.1001 53.1936 35.7835 53.5471C35.4742 53.9005 35.2533 54.1803 35.0987 54.3938L35.0177 54.3497L34.8189 54.276V54.3055L33.5156 53.8048C33.4788 53.7974 33.4493 53.7974 33.4125 53.8269L33.0885 54.0919C33.037 54.1361 33.0443 54.2245 33.1106 54.2613L34.4581 55.086L34.7526 55.1228L34.7305 55.4173L35.3049 56.8974C35.3343 56.971 35.4153 56.9931 35.4669 56.9489L35.7909 56.6838C35.8277 56.6544 35.8277 56.6176 35.8277 56.5881L35.57 55.2185H35.5994L35.5626 55.0124L35.5331 54.924C35.7761 54.8135 36.0854 54.6515 36.4904 54.4086C36.8954 54.1656 37.3666 53.8784 37.9631 53.4881C38.103 53.3998 38.2576 53.3041 38.4049 53.201L38.4196 53.2231L39.3253 55.6603H39.3474L39.745 56.728C39.7892 56.7648 39.8407 56.7722 39.8923 56.7428L40.1942 56.4924C40.2457 56.4482 40.2531 56.3819 40.2089 56.3378Z" fill="#7EB7C6" />
	</svg>
</template>
