<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.287 6.05c0 1.581 1.303 2.866 2.907 2.866 1.603 0 2.906-1.288 2.906-2.87 0-1.581-1.303-2.866-2.906-2.866-1.604 0-2.907 1.288-2.907 2.87Zm1.428-.004c0-.803.665-1.458 1.479-1.458.817 0 1.478.655 1.478 1.458 0 .803-.664 1.458-1.478 1.458a1.471 1.471 0 0 1-1.479-1.458Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.592 15.745c.14.16.347.255.562.255a.733.733 0 0 0 .562-.255c.055-.066 1.393-1.615 2.753-3.554 1.885-2.687 2.839-4.747 2.839-6.121 0-3.347-2.761-6.07-6.154-6.07S2 2.723 2 6.07c0 1.374.954 3.434 2.839 6.12 1.3 1.855 2.58 3.353 2.738 3.538l.015.017ZM3.478 6.07c0-2.541 2.1-4.612 4.676-4.612 2.58 0 4.675 2.07 4.675 4.615 0 1.04-.913 2.917-2.568 5.283a48.742 48.742 0 0 1-2.107 2.77 47.295 47.295 0 0 1-2.1-2.763C4.396 8.99 3.478 7.113 3.478 6.07Z"
			fill="currentColor"
		/>
	</svg>
</template>
