<script setup lang="ts">
import type { CreateLegData } from '~/ts/legs'
import { getReturnDate, parse } from '~/ts/utils/datetime'

const emit = defineEmits<{
	(e: 'update:modelValue', value: CreateLegData[]): void
	(e: 'search'): void
}>()

const props = defineProps<{
	modelValue: CreateLegData[]
}>()

const legs = useVModel(props, 'modelValue', emit)
const lastLeg = computed(() => legs.value[legs.value.length - 1])

function addLeg(attributes: Partial<CreateLegData>) {
	legs.value.push({
		departure_airport: undefined,
		arrival_airport: undefined,
		departure_date: getReturnDate().format(),
		female_count: 1,
		male_count: 0,
		children_count: 0,
		baby_count: 0,
		pet_count: 0,
		...attributes,
	})
}

function removeLeg(i: number) {
	legs.value.splice(i, 1)
}

function addReturnLeg() {
	const previous = toRaw(lastLeg.value)

	addLeg({
		...previous,
		departure_airport: previous.arrival_airport,
		arrival_airport: previous.departure_airport,
		departure_date: getReturnDate(previous.departure_date).format(),
	})
}

const searchDisabled = computed(() => {
	return props.modelValue.some((leg: any) => {
		return !leg.departure_airport
        || !leg.arrival_airport
        || parse(leg.departure_date).get('year') === 1970
		|| (leg.female_count === 0 && leg.male_count === 0 && leg.children_count === 0 && leg.baby_count === 0 && leg.pet_count === 0)
	})
})
</script>

<template>
	<form @submit.prevent="emit('search')">
		<div class="space-y-10 lg:space-y-2">
			<LegInput
				v-for="(_, i) in legs"
				:key="i"
				v-model="legs[i]"
				:index="i"
				:removable="modelValue.length > 1"
				:searchable="modelValue.length === 1"
				:search-disabled="searchDisabled"
				@remove="removeLeg(i)"
				@search="emit('search')"
			/>
		</div>

		<div class="mt-3.5 flex flex-col md:flex-row md:items-center md:justify-between md:space-x-3">
			<div class="flex space-x-2">
				<BaseButton
					variant="secondary-hero"
					icon="Plus"
					size="xs"
					icon-class="h-3.5 w-3.5"
					class="grow space-x-2 px-4"
					type="button"
					@click.prevent="addLeg"
				>
					Add flight
				</BaseButton>

				<BaseButton
					v-if="lastLeg.arrival_airport && lastLeg.departure_airport"
					variant="secondary-hero"
					icon="Return"
					size="xs"
					icon-class="h-3.5 w-3.5"
					class="grow space-x-2 px-4"
					type="button"
					@click.prevent="addReturnLeg"
				>
					Add return
				</BaseButton>
			</div>

			<BaseButton
				variant="primary"
				size="lg"
				class="mt-5 space-x-2 px-4 md:mt-0"
				:class="{ 'lg:hidden' : modelValue.length === 1 }"
				icon="Search"
				icon-class="h-5 w-5"
				:disabled="searchDisabled"
				type="submit"
			>
				Search
			</BaseButton>
		</div>
	</form>
</template>
