import axios from 'axios'
import { random } from '../utils/string'
import { join, listenToWhispers, whisper } from './echo'
import { ChatOptions, CorrespondantData, Message, State } from './types'
import { authorIsCurrentUser } from './user'
import { useMessageCache } from './cache'
import { fetchMessages } from './api'
import { useInfiniteScroll } from '@/composables/useInfiniteScroll'
import { useProperty } from '@/composables/useProperty'
import { route } from '@/composables/route'
import UserData = SupportCaptainJet.Data.UserData

const TYPING_DELAY = 4000

export function useChat(options: ChatOptions) {
	const disconnectCallbacks: Function[] = []
	const token = ref(options.token)
	const { messages, hasPrevious, scrollY } = useMessageCache(token)
	const state = ref<State>('initializing')
	const input = ref<string>('')
	const hasManuallyScrolled = ref(false)
	const scroll = useScroll(options.logarea)
	const isAtBottom = ref(false)
	const channel = computed(() => `conversation.${token.value}`)
	const currentUser = useProperty<UserData>('security.user')
	const correspondantTyping = refAutoReset<CorrespondantData | undefined>(undefined, TYPING_DELAY)
	const canSendMessage = computed(() => {
		if (!token.value) {
			return false
		}

		if (!input.value.trim() && !options.attachments.value.length) {
			return false
		}

		return true
	})

	const whisperTyping = useThrottleFn(() => {
		whisper<{ user: UserData; content: string }>(channel.value, 'typing', {
			user: currentUser.value,
			content: options.client ? input.value : '',
		})
	}, options.client ? 100 : TYPING_DELAY / 2)

	const markAsRead = useDebounceFn((token) => {
		axios.put(route('captainjet.web.api.chat.messages.mark-as-read', { token }))
	}, 1000)

	/** Initializes the chat. */
	whenever(token, async(current, previous) => {
		if (current === previous) {
			return
		}

		// If we don't already have the message log from the cache,
		// we need to update them.
		if (!messages.value.length) {
			updateMessages()
		}

		const updateMessagesWith = async(model: Message, type: 'delete' | 'replace' | 'add') => {
			const index = messages.value.findIndex((message) => {
				if (message.context && message.context === model.context) {
					return true
				}

				if (message.id === model.id) {
					return true
				}

				return false
			})

			if (index === -1 && type === 'replace') {
				messages.value.push(model)
				return
			}

			if (index === -1) {
				return
			}

			const deleteCount = ['delete', 'replace'].includes(type) ? 1 : 0
			const add = ['add', 'replace'].includes(type) ? [model] : []
			messages.value.splice(index, deleteCount, ...add)
		}

		// Join chatroom
		disconnectCallbacks.forEach((cb) => cb())
		disconnectCallbacks.splice(0, disconnectCallbacks.length - 1)
		disconnectCallbacks.push(listenToWhispers(channel.value, 'typing', (data: { user?: UserData; content: string }) => {
			correspondantTyping.value = {
				name: data.user?.full_name ?? 'Anonymous user',
				profile_picture_url: data.user?.profile_picture_url ?? undefined,
				content: data.content,
			}

			scrollToBottom()
		}))
		disconnectCallbacks.push(join<Message>(channel.value, {
			MessageDeleted: ({ model }) => updateMessagesWith(model, 'delete'),
			MessageTrashed: ({ model }) => updateMessagesWith(model, 'delete'),
			MessageUpdated: ({ model }) => {
				updateMessagesWith(model, 'replace')

				// Attachments will take more place after an update, so if this is the latest
				// message and the user has not scrolled manually, we want to fix the scroll.
				if (model.id === messages.value.at(-1)?.id) {
					scrollToBottom()
				}
			},
			MessageCreated: (data) => {
				correspondantTyping.value = undefined
				updateMessagesWith(data.model, 'replace')
				scrollToBottom()
			},
		}))

		state.value = 'idle'
	}, { immediate: true })

	// When a scroll happens, we want to set the state of "user has scrolled" to
	// true when they haven't reached the bottom, which is a good heuristic to
	// determine when we want to automatically scroll to the bottom again.
	watch(scroll.y, () => {
		hasManuallyScrolled.value = !scroll.arrivedState.bottom
		isAtBottom.value = scroll.y.value + 2000 > (unref(options.logarea)?.scrollHeight ?? 0)
		scrollY.value = scroll.arrivedState.bottom ? undefined : scroll.y.value
	})

	// When the user reaches the end of the chatbox, we try to fetch
	// the next messages so the scrollbox can be infinite.
	useInfiniteScroll(options.logarea, () => updateMessages(), { direction: 'top', distance: 20, preserveScrollPosition: true })

	// When the textarea becomes available, scroll to the last known scroll value.
	whenever(() => unref(options.logarea), async() => {
		scrollToBottom({ scrollY: scrollY.value })
	})

	/** Fetches the messages at the next defined endpoint. If empty, do nothing. */
	async function updateMessages(): Promise<void> {
		if (state.value === 'loading-messages') {
			return
		}

		if (!hasPrevious.value || !token.value) {
			return
		}

		state.value = 'loading-messages'
		const newMessages = await fetchMessages(token.value, messages.value.at(0)?.id)
		hasPrevious.value = newMessages.length === 25
		messages.value = [...newMessages, ...messages.value]
		state.value = 'idle'

		scrollToBottom()
	}

	/** Submits a message. */
	async function submit() {
		if (!canSendMessage.value) {
			return
		}

		const data = {
			token: token.value,
			body: input.value,
			attachments: options.attachments.value,
			context: random(4),
			is_client: options.client,
		}

		messages.value.push({
			...data,
			author: {
				id: currentUser.value?.id,
				full_name: currentUser.value?.full_name,
			},
		} as any)

		options?.onSubmit?.()

		try {
			await axios.post<Message>(route('captainjet.web.api.chat.messages.create'), data, { withCredentials: true })
		} catch (error: any) {
			options?.onFail?.(messages.value.pop()!)
			if (error.response.data.errors) {
				options?.onError?.(Object.values(error.response.data.errors).join('\n'))
			} else {
				options?.onError?.(error.message)
			}
		}
	}

	/** Marks the conversation as seen. */
	async function onMessageSeen(id: number) {
		const message = messages.value.find((m) => m.id === id)

		if (!message || message.read_at || authorIsCurrentUser(message)) {
			return
		}

		await until(token).toBeTruthy()

		markAsRead(token.value)
	}

	/** Scrolls to the bottom of the log area. */
	function scrollToBottom({ smooth = false, force = false, scrollY }: { smooth?: boolean; force?: boolean; scrollY?: number } = {}) {
		if (hasManuallyScrolled.value && !force) {
			return
		}

		nextTick(() => {
			unref(options.logarea)?.scrollTo({ top: scrollY ?? unref(options.logarea)?.scrollHeight, behavior: smooth ? 'smooth' : 'auto' })
		})
	}

	// When typing something, whisper to clients that we are typing.
	watch(input, () => {
		whisperTyping()
	})

	/**
	 * Since this is a chat input, we need to customize the behavior of the textarea.
	 * When the user presses Enter, the message should be sent, but if the shift
	 * key is pressed, a new line should be appended to the message instead.
	 */
	function onKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault()
			submit()
		}
	}

	async function trash(message: Message) {
		try {
			await axios.delete<Message>(route('captainjet.web.api.chat.messages.delete', { message }))
			options?.onTrash?.()
		} catch (error: any) {
			if (error.response.data.errors) {
				options?.onError?.(Object.values(error.response.data.errors).join('\n'))
			} else {
				options?.onError?.(error.message)
			}
		}
	}

	return {
		state,
		token,
		input,
		messages,
		submit,
		onMessageSeen,
		markAsRead,
		onKeyDown,
		canSendMessage,
		hasManuallyScrolled,
		isAtBottom,
		scroll,
		scrollToBottom,
		hasPrevious,
		trash,
		correspondantTyping,
	}
}
