import { router } from '@inertiajs/vue3'
import { route } from '@/composables/route'
import BillingAddressData = Domain.BookingCaptainJet.Data.BillingAddressData

/**
 * Deletes the given billing address.
 */
export function deleteBillingAddress(billingAddress: BillingAddressData) {
	router.delete(route('captainjet.web.billing-address.destroy', { billingAddress }))
}

/**
 * Sets the given billing address as the default.
 */
export function setFavoriteBillingAddress(billingAddress: BillingAddressData) {
	router.post(route('captainjet.web.billing-address.favorite', { billingAddress }))
}
