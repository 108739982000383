<template>
	<svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.202 0h10.233c.639 0 1.156.52 1.159 1.159V14.84A1.16 1.16 0 0 1 13.435 16H3.202a1.16 1.16 0 0 1-1.16-1.159V1.16C2.043.52 2.564 0 3.203 0Zm10.23 14.899a.058.058 0 0 0 .058-.058h.003V1.16a.058.058 0 0 0-.058-.058H3.202a.058.058 0 0 0-.058.058v13.68c0 .033.027.058.058.058h10.23Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.121 8.32a.48.48 0 0 1 .48-.48h5.44a.48.48 0 1 1 0 .96h-5.44a.48.48 0 0 1-.48-.48Z"
		/>
		<path
			d="M5.121 12.8v-1.92h.95a.76.76 0 0 1 .281.05.613.613 0 0 1 .356.331.627.627 0 0 1 0 .497.54.54 0 0 1-.139.195.672.672 0 0 1-.217.131.799.799 0 0 1-.28.047h-.473v.669h-.478Zm.867-1.53H5.6v.485h.386c.097 0 .17-.023.217-.069a.237.237 0 0 0 .073-.175.225.225 0 0 0-.073-.17c-.048-.048-.12-.071-.214-.071Zm1.523 1.53v-1.92h.792a.95.95 0 0 1 .381.077.95.95 0 0 1 .52 1.261.95.95 0 0 1-.52.507.98.98 0 0 1-.38.075H7.51Zm.792-1.517H7.99v1.114h.314a.445.445 0 0 0 .342-.16.6.6 0 0 0 .142-.392.608.608 0 0 0-.142-.397.463.463 0 0 0-.153-.12.42.42 0 0 0-.189-.045ZM10.04 12.8v-1.92h1.481v.395h-1.003v.378h.7v.384h-.7v.763h-.478ZM8.017 6.922 6.522 5.504a.388.388 0 0 1 0-.569.44.44 0 0 1 .6 0l.772.734V3.602c0-.221.191-.402.424-.402.233 0 .423.18.423.402v2.067l.773-.734a.44.44 0 0 1 .6 0 .39.39 0 0 1 .124.284.39.39 0 0 1-.124.283l-1.498 1.42a.44.44 0 0 1-.599 0Z"
		/>
	</svg>
</template>
