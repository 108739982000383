<script setup lang="ts">
import BookingData = Domain.BookingCaptainJet.Data.BookingData

defineProps<{
	booking?: BookingData
	confirmedFlights: number
}>()
</script>


			<script lang="ts">
			import layout from '@/layouts/marketing.vue'
			export default { layout }
			</script>
			<template>
		
	<base-header :marketing="true" />
	<marketing-booking :booking="booking" />
	<marketing-statistics :confirmed-flights="confirmedFlights" />
	<marketing-about />
	<marketing-features />
	<marketing-steps />
	<marketing-group />
	<marketing-jetbook />
	<marketing-footer />
</template>
