<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { route } from '@/composables/route'
import { useEcho } from '~/ts/chat/echo'
import { useNotifications } from '~/ts/notifications/notifications'
import ConversationData = Domain.Chat.Data.ConversationData

const { push } = useNotifications()

defineProps<{
	conversations: ConversationData[]
	conversation?: ConversationData
}>()

function lastMessageExcerpt(conversation: ConversationData) {
	const body = conversation.messages?.at(-1)?.body?.substring(0, 100)
	const attachments = conversation.messages?.at(-1)?.attachments_count ?? 0

	return attachments > 0
		?	(attachments === 1 ? '<attachment>' : `<${attachments} attachments>`)
		: body ?? '<no message>'
}

function notifyError(error: string) {
	push({
		type: 'error',
		content: error,
	})
}

onMounted(() => {
	const { listen } = useEcho()
	const reload = () => router.reload({ only: ['conversations', 'conversation'] })

	listen<ConversationData>('admin.conversations', {
		ConversationCreated: reload,
		ConversationUpdated: reload,
		ConversationTrashed: reload,
	})
})

function redirectToUserPage(userId: number) {
	window.parent?.postMessage({ redirect: route('filament.captainjet.management.resources.users.edit', { record: userId }) })
}
</script>

<template>
	<section class="flex h-screen flex-1 divide-turquoise-100 md:divide-x">
		<!-- Conversation selection -->
		<ul
			class="flex-1 overflow-auto md:w-1/3 md:max-w-md md:flex-auto"
			:class="{
				'hidden md:block': conversation,
				'block': !conversation
			}"
		>
			<li v-for="item in conversations" :key="item.token" class="border-b border-turquoise-100">
				<InertiaLink
					:href="route('captainjet.web.iframe.chat.show', { conversation: item })"
					class="flex flex-col px-6 py-3 transition hover:bg-turquoise-50"
					:class="{
						'bg-turquoise-50 text-blue-700': item.id === conversation?.id,
						'text-turquoise-700': item.id !== conversation?.id,
					}"
				>
					<span class="inline-flex items-baseline space-x-2 font-medium">
						<div v-if="item.has_unread" class="size-2 rounded-full bg-red-400" />
						<span v-text="item.user?.full_name ?? 'Anonymous User'" />
					</span>
					<p class="truncate text-sm" v-text="lastMessageExcerpt(item)" />
				</InertiaLink>
			</li>
		</ul>

		<!-- Chat window -->
		<div v-if="conversation" class="relative flex size-full flex-col bg-white">
			<chat-window :token="conversation.token" :client="false" class="size-full" @error="notifyError" />
			<div class="absolute left-0 top-0 flex items-center rounded-br-xl rounded-tl-xl border-b border-r border-turquoise-100 bg-white">
				<InertiaLink :href="route('captainjet.web.iframe.chat.index')" class="block px-4 py-2.5 text-gray-500 transition hover:text-gray-900">
					Back
				</InertiaLink>
				<button class="block px-4 py-2.5 text-gray-500 transition hover:text-gray-900" @click="() => redirectToUserPage(conversation!.user_id!)">
					See user
				</button>
			</div>
		</div>

		<div v-if="!conversation" class="hidden size-full flex-col items-center justify-center font-medium text-gray-300 md:flex">
			<icon-letter-box class="size-24" />
			<p v-if="conversations.length === 0">
				There is no conversation yet
			</p>
			<p v-else>
				Open a conversation to start chatting
			</p>
		</div>
	</section>

	<feedback />
</template>
