import { MaybeRef } from '@vueuse/core'
import { Message } from './types'
import { useProperty } from '@/composables/useProperty'
import UserData = SupportCaptainJet.Data.UserData

/** Checks if the given author is the current user. */
export function authorIsCurrentUser(message?: MaybeRef<Message>) {
	const current = useProperty<UserData>('security.user')

	if (!current.value) {
		return !unref(message)?.author?.id
	}

	return unref(message)?.author?.id === current.value?.id
}
