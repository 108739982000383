<template>
	<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M33.921 3.893v42.214c0 .423-.066.83-.19 1.21A3.86 3.86 0 0 1 30.063 50H9.857C7.73 50 6 48.258 6 46.107V3.893c0-1.47.81-2.75 2-3.41A3.767 3.767 0 0 1 9.858 0h20.207c2.131 0 3.857 1.742 3.857 3.893Z"
			fill="#4F6B89"
		/>
		<path
			d="M33.921 3.893v42.214c0 .423-.066.83-.19 1.21-.55.307-1.183.48-1.857.48H11.667c-2.131 0-3.857-1.744-3.857-3.895V1.69c0-.42.066-.826.19-1.207A3.767 3.767 0 0 1 9.858 0h20.207c2.131 0 3.857 1.742 3.857 3.893Z"
			fill="#7289A1"
		/>
		<path
			d="M31.143 47.797H8.781a.975.975 0 0 1-.971-.98V5.324c0-.542.434-.98.97-.98h22.363c.537 0 .971.438.971.98V46.82c0 .54-.436.977-.97.977ZM21.183 1.54h-2.442a.591.591 0 0 0-.589.593c0 .327.263.594.589.594h2.442a.591.591 0 0 0 .588-.594.593.593 0 0 0-.588-.594Z"
			fill="#fff"
		/>
		<path d="M32 19v13H19.58c-.874 0-1.58-.953-1.58-2.128v-8.744c0-1.175.706-2.128 1.58-2.128H32Z" fill="#E4ECEF" />
		<path
			d="M29.384 28.376h13.118a2.149 2.149 0 0 0 2.14-2.163V17.34c0-1.193-.958-2.16-2.14-2.16H24.931c-1.182 0-2.141.967-2.141 2.16v8.874c0 1.193.959 2.16 2.14 2.16h1.272v3.182l3.182-3.181Z"
			fill="#61A7B7"
		/>
		<path
			d="M36.998 20.993c0-.347-.208-.605-.553-.643-.19-.022-.22-.097-.231-.261-.097-1.522-1.618-2.496-3.029-1.924-.876.354-1.33 1.037-1.402 1.97-.008.085.026.18-.128.199-.459.054-.648.283-.648.742 0 1.06.013 2.114-.006 3.171-.008.495.348.76.76.752.746-.014 1.494 0 2.24 0h2.31c.41 0 .687-.272.687-.671.004-1.113.004-2.225 0-3.335ZM34.39 23.11c-.009.217-.147.329-.395.327-.236 0-.373-.113-.378-.325-.006-.3-.006-.599 0-.898.005-.212.148-.323.384-.323.236 0 .377.114.388.32.007.153 0 .306 0 .459 0 .152.007.294.001.44Zm.878-2.78c-.427-.003-.854 0-1.28 0-.408 0-.817-.003-1.225 0-.135 0-.187-.03-.182-.178.015-.368.173-.716.44-.97a1.437 1.437 0 0 1 1.987.004c.266.256.423.605.436.973.006.136-.05.176-.176.172Z"
			fill="#fff"
		/>
	</svg>
</template>
