<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 13">
		<path fill="currentColor" d="M11.26 6.52c0-.16.14-.3.3-.3h.42a6.08 6.08 0 0 0-1.53-3.74l-.3.3a.3.3 0 0 1-.21.09.3.3 0 0 1-.21-.1.3.3 0 0 1 0-.41l.3-.3A6 6 0 0 0 6.29.5v.42a.3.3 0 0 1-.3.3.3.3 0 0 1-.3-.3V.5a6.03 6.03 0 0 0-3.74 1.54l.3.3a.3.3 0 0 1 0 .42.3.3 0 0 1-.2.1.3.3 0 0 1-.22-.1l-.3-.3A6.16 6.16 0 0 0 0 6.21h.42a.3.3 0 0 1 0 .6H0a6 6 0 0 0 1.55 3.74l.3-.3c.12-.12.3-.12.42 0a.3.3 0 0 1 0 .42l-.3.29A5.91 5.91 0 0 0 5.7 12.5v-.42c0-.17.13-.3.3-.3.17 0 .3.13.3.3v.42a6.03 6.03 0 0 0 3.73-1.54l-.29-.29c-.12-.12-.12-.31 0-.42.12-.12.3-.12.43 0l.3.3A5.92 5.92 0 0 0 12 6.81h-.42a.29.29 0 0 1-.32-.29ZM8.3 4.47 6.77 7.13l-.18-.18.18.2L4.1 8.67a.2.2 0 0 1-.28-.27l.6-1.01.94-1.66 1.65-.95 1.01-.6c.18-.1.38.1.27.28Z" />
		<g clip-path="url(#location-icon-clip-path)">
			<path fill="currentColor" d="M6.5 6.51a.49.49 0 0 0-.5-.5.5.5 0 0 0-.5.5c0 .17.07.32.2.41.08.07.18.1.3.1.27 0 .5-.23.5-.5Z" />
		</g>
		<defs>
			<clipPath id="location-icon-clip-path">
				<path fill="currentColor" d="M5.5 7.02h1v-1h-1z" />
			</clipPath>
		</defs>
	</svg>
</template>
