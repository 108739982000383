<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import BaseButton from '../BaseButton.vue'
import { openDialog } from '~/ts/dialog'
import { parseFlightTime } from '~/ts/utils/datetime'
import { route } from '@/composables/route'
import BookingData = Domain.BookingCaptainJet.Data.BookingData
import BookingEstimateData = Domain.BookingCaptainJet.Data.BookingEstimateData
import QuotationData = Domain.BookingCaptainJet.Data.QuotationData

const $emit = defineEmits<{
	(e: 'toggle', selected?: boolean): void
	(e: 'ask-for-contract'): void
	(e: 'review-contract'): void
	(e: 'export-as-pdf'): void
}>()

const $props = defineProps<{
	booking: BookingData
	estimate?: BookingEstimateData
	quotation?: QuotationData
	selected?: boolean
	selectable?: boolean
	isBusinessGetter?: boolean
}>()

const disabled = computed(() => $props.quotation?.status === 'declined')
const selectable = computed(() => $props.isBusinessGetter || $props.selectable === true || !!$props.estimate)
const aircraft = computed(() => $props.estimate?.aircraft ?? $props.quotation?.aircraft)
const flightTime = computed(() => $props.estimate?.total_flight_time ?? $props.quotation?.total_flight_time)
const urgent = computed(() => $props.booking.is_urgent)
const hasFuelStops = computed(() => !!$props.estimate?.fuel_stops)
const price = computed(() => $props.estimate?.display_price_formatted ?? $props.quotation?.display_price_formatted)

function showAircraftDetails() {
	openDialog('aircraft-details', {
		aircraft: toRaw(aircraft.value!),
		quotation: toRaw($props.quotation),
		estimate: toRaw($props.estimate),
		onAskForContract: () => $emit('ask-for-contract'),
		onReviewContract: () => $emit('review-contract'),
	})
}

function toggleSelect() {
	if (!selectable.value) {
		return
	}

	$emit('toggle')
}

function reviewContract() {
	if (disabled.value) {
		return
	}

	$emit('review-contract')
}

function askForContract() {
	if (disabled.value) {
		return
	}

	$emit('ask-for-contract')
}

function preloadAircraftPictures() {
	for (const { url } of aircraft.value?.pictures ?? []) {
		const image = new Image()
		image.src = url
	}
}

/*
|--------------------------------------------------------------------------
| Business getters
|--------------------------------------------------------------------------
*/

const businessGetterFeesFormatted = computed(() => $props.quotation?.business_getter_fees_formatted)
const actions = [
	{ label: 'Edit commission', handler: editCommission },
	{ label: 'Edit comment', handler: editComment },
	{ label: 'Export as PDF', handler: exportAsPdf },
]

const comment = ref($props.quotation?.comment)
const EditCommentDialog = createTemplatePromise<boolean>({ singleton: true })

async function editComment() {
	if (!await EditCommentDialog.start()) {
		return
	}

	router.put(route('captainjet.web.booking.quotation.update', { booking: $props.booking, quotation: $props.quotation }), {
		comment: comment.value,
	})
}

const commission = ref($props.quotation?.business_getter_fees)
const EditCommissionDialog = createTemplatePromise<boolean>({ singleton: true })

async function editCommission() {
	if (!await EditCommissionDialog.start()) {
		return
	}

	router.put(route('captainjet.web.booking.quotation.update', { booking: $props.booking, quotation: $props.quotation }), {
		business_getter_fees: Number(commission.value),
	})
}

async function exportAsPdf() {
	$emit('export-as-pdf')
}
</script>

<template>
	<div
		:class="[
			{ 'cursor-default bg-blue-100/30': disabled },
			{ 'bg-white': !disabled },
			{ 'cursor-pointer': !disabled && selectable },
			{ 'aircraft-shadow-active': selected },
			'group flex flex-col truncate rounded-2xl p-0.5 transition focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-500 focus-visible:ring-offset-2 xs:flex-row',
		]"
		:tabindex="disabled ? -1 : 0"
		@keypress.prevent.l="showAircraftDetails"
		@keypress.prevent.enter="toggleSelect"
		@keypress.prevent.space="toggleSelect"
		@click="toggleSelect"
	>
		<!-- Aircraft image -->
		<div class="relative aspect-video min-h-28 shrink-0 xs:aspect-auto xs:w-44" @mouseover="preloadAircraftPictures">
			<div class="peer size-full rounded-xl focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-500">
				<div class="size-full cursor-pointer overflow-hidden rounded-2xl" @click="showAircraftDetails">
					<img
						v-if="aircraft?.pictures?.[0]"
						:class="[
							{ 'transition-transform duration-500 ease-in-out will-change-transform hover:scale-110': !disabled },
							'size-full object-cover',
						]"
						:src="aircraft.pictures?.[0]?.url"
					/>
				</div>
			</div>
		</div>

		<!-- Details -->
		<div class="mt-2 flex min-w-0 grow flex-col justify-around px-5 py-3 xs:mt-0">
			<!-- Title, country and badge -->
			<div class="flex min-w-0 justify-between">
				<!-- Title and country -->
				<div class="flex min-w-0 flex-col pr-2">
					<h1
						:class="[
							{ 'text-turquoise-500': selected },
							{ 'text-blue-500': !selected },
							{ '!text-blue-200': disabled },
							'truncate font-display text-lg font-bold leading-none transition',
						]"
						:title="aircraft?.aircraft_type?.name"
						v-text="aircraft?.aircraft_type?.name"
					/>
					<span
						class="grow truncate text-blue-300"
						:title="aircraft?.country?.name"
						v-text="aircraft?.country?.name"
					/>
				</div>

				<!-- Right side -->
				<div class="flex items-start gap-x-4">
					<!-- Badge -->
					<div v-if="quotation?.status">
						<BookingQuotationBadge :status="quotation.status" />
					</div>

					<!-- Menu -->
					<Menu v-if="isBusinessGetter" v-slot="{ open }" as="div" class="relative">
						<Float
							portal
							placement="bottom-end"
							:show="open"
							:shift="10"
							enter="transition duration-100 ease-out"
							enter-from="scale-95 opacity-0 origin-top"
							enter-to="scale-100 opacity-100"
							leave="transition duration-75 ease-in origin-top"
							leave-from="scale-100 opacity-100"
							leave-to="scale-95 opacity-0"
							:offset="10"
						>
							<MenuButton
								:class="[
									{
										'bg-turquoise-100 focus-visible:ring-0': open,
										'hover:bg-turquoise-50': !open,
									},
									'flex size-7 items-center justify-center rounded-full text-turquoise-500 transition focus-visible:ring-2 focus-visible:ring-turquoise-600',
								]"
								@click.prevent.stop
							>
								<icon-dots-horizontal class="w-4" />
							</MenuButton>
							<MenuItems class="z-20 flex origin-top-right flex-col divide-y divide-turquoise-50 overflow-hidden rounded-2xl bg-white shadow focus:outline-none">
								<MenuItem v-for="action in actions" v-slot="{ active }" :key="action.label">
									<button
										:class="[
											{ 'bg-turquoise-50 text-blue-500': active },
											{ 'text-blue-400': !active },
											'flex items-center space-x-2 px-4 py-3 font-medium',
										]"
										@click="action.handler"
									>
										<!-- <icon-edit class="w-4" /> -->
										<span v-text="action.label" />
									</button>
								</MenuItem>
							</MenuItems>
						</Float>
					</Menu>
				</div>
			</div>

			<!-- Details, price and checkbox -->
			<div
				:class="[
					{ 'sm:items-center': !isBusinessGetter },
					{ 'sm:items-end': isBusinessGetter },
					'mt-2 flex w-full justify-between gap-x-4 sm:mt-0 sm:flex-row'
				]"
			>
				<!-- Details -->
				<div class="flex min-w-0 grow flex-col-reverse gap-x-4 gap-y-2 text-sm font-medium text-blue-300 md:flex-row">
					<!-- Learn more -->
					<button
						:disabled="disabled"
						class="relative z-10 flex items-center space-x-2 self-start transition hover:text-turquoise-600 focus-visible:text-turquoise-600 focus-visible:ring-2 focus-visible:ring-turquoise-500 disabled:text-blue-200"
						tabindex="-1"
						@click.stop="showAircraftDetails"
					>
						<IconSearchAircraft class="size-4" />
						<span class="underline underline-offset-4">Learn more</span>
						<span class="hidden opacity-50 group-focus-visible:inline">(L)</span>
					</button>

					<!-- Flight time -->
					<div
						v-if="flightTime"
						class="flex items-center space-x-2"
						:class="[selectable ? '' : 'sm:hidden md:flex']"
					>
						<IconClock class="size-4 shrink-0" />
						<span :title="`${flightTime} minute(s)`" v-text="parseFlightTime(flightTime)" />
					</div>

					<!-- Country -->
					<div v-if="aircraft?.country?.name && urgent" class="flex items-center space-x-2 overflow-hidden" :title="aircraft?.country?.name">
						<IconLocation class="size-4 shrink-0" />
						<span class="truncate" v-text="aircraft?.country?.name" />
					</div>

					<!-- Fuel stops -->
					<div v-if="hasFuelStops" class="flex items-center space-x-2 sm:hidden md:flex" title="This flight has fuel stops">
						<IconGasPump class="size-4 shrink-0" />
						<span class="sm:hidden lg:inline">Fuel stops</span>
					</div>
				</div>

				<!-- Price -->
				<div class="ml-2 mt-2 flex items-center justify-end gap-x-4 sm:mt-0">
					<div class="flex flex-col items-end justify-end">
						<div
							:class="[
								{ 'text-turquoise-500': selected },
								{ 'text-blue-500 motion-safe:scale-90': !selected },
								'flex items-center space-x-1 text-lg font-bold transition will-change-transform',
							]"
						>
							<IconApproximation v-if="price && $props.estimate" class="size-3.5" />
							<span v-text="price" />
						</div>
						<!-- Business getter stuff -->
						<div
							v-if="isBusinessGetter"
							class="flex gap-x-4 text-sm font-medium text-blue-300 lg:ml-auto"
						>
							<!-- Commission -->
							<div>
								<span class="mr-2">Your commission:</span>
								<button
									class="underline underline-offset-4 transition hover:text-turquoise-600 focus-visible:text-turquoise-600 focus-visible:ring-2 focus-visible:ring-turquoise-500 disabled:text-blue-200"
									tabindex="-1"
									@click.stop="editCommission"
								>
									<span>{{ businessGetterFeesFormatted ?? 'none' }}</span>
								</button>
							</div>
						</div>
					</div>

					<!-- Checkbox -->
					<div
						v-if="selectable"
						class="relative flex size-8 shrink-0 items-center justify-center rounded-full bg-turquoise-100 text-white"
					>
						<div
							class="absolute inset-0 rounded-full bg-turquoise-500 transition will-change-transform"
							:class="{
								'scale-100 opacity-100': selected,
								'opacity-0 motion-safe:scale-50': !selected,
							}"
						/>
						<IconTrue
							class="relative size-4 transition delay-100 will-change-transform"
							:class="{
								'scale-100 opacity-100': selected,
								'opacity-0 motion-safe:scale-50': !selected
							}"
						/>
					</div>

					<!-- Ask for contract button -->
					<BaseButton
						v-if="quotation?.status === 'quoted'"
						variant="primary"
						icon="ChevronRight"
						size="md"
						icon-placement="right"
						icon-class="h-3.5 w-3.5"
						class="space-x-2 px-4"
						text="Ask for contract"
						@click.stop="askForContract"
					/>

					<!-- Review contract button -->
					<BaseButton
						v-if="quotation?.status === 'contract_available'"
						variant="warning"
						icon="ChevronRight"
						size="md"
						icon-placement="right"
						icon-class="h-3.5 w-3.5"
						class="space-x-2 px-4"
						text="Review contract"
						@click.stop="reviewContract"
					/>
				</div>
			</div>
		</div>

		<!-- Dialogs -->
		<EditCommentDialog v-slot="{ resolve }">
			<BaseDialog show title="Update quotation comment" @close="resolve(false)">
				<form class="px-4 py-6" @submit.prevent="resolve(true)">
					<BaseInput
						v-model="comment"
						type="text"
						label="Quotation comment"
						placeholder="No comment"
						clearable
					/>
					<div class="flex justify-center gap-x-2">
						<BaseButton class="mt-4" variant="secondary" type="button" size="sm" @click="resolve(false)">
							Cancel
						</BaseButton>
						<BaseButton class="mt-4" variant="primary" type="submit" size="sm">
							Submit
						</BaseButton>
					</div>
				</form>
			</BaseDialog>
		</EditCommentDialog>

		<EditCommissionDialog v-slot="{ resolve }">
			<BaseDialog show title="Update commission" @close="resolve(false)">
				<form class="px-4 py-6" @submit.prevent="resolve(true)">
					<BaseInput
						v-model="commission"
						type="number"
						label="Commission"
						placeholder="No commission"
						clearable
					/>
					<div class="flex justify-center gap-x-2">
						<BaseButton class="mt-4" variant="secondary" type="button" size="sm" @click="resolve(false)">
							Cancel
						</BaseButton>
						<BaseButton class="mt-4" variant="primary" type="submit" size="sm">
							Submit
						</BaseButton>
					</div>
				</form>
			</BaseDialog>
		</EditCommissionDialog>
	</div>
</template>

<style scoped lang="postcss">
.aircraft-shadow-active {
		--tw-shadow: 0px 4px 15px rgba(147, 191, 201, 0.4);
		--tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color);
		box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
