<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import { route } from '@/composables/route'

const iosDownloadLink = useProperty('security.features.ios_download_link')
const androidDownloadLink = useProperty('security.features.android_download_link')
const displayablePhoneNumber = useProperty('security.features.phone_number')
const phoneNumber = computed(() => displayablePhoneNumber.value?.replaceAll(' ', ''))
</script>

<template>
	<footer>
		<div class="bg-blue-600">
			<div class="mx-auto flex flex-col items-center gap-y-7 px-8 py-6 text-white lg:flex-row lg:justify-between lg:py-4.5">
				<div class="flex items-center space-x-10 font-medium">
					<InertiaLink class="transition-colors hover:text-blue-100" :href="route('captainjet.web.index')">
						Search
					</InertiaLink>
					<InertiaLink class="transition-colors hover:text-blue-100" :href="route('captainjet.web.about')">
						About us
					</InertiaLink>
				</div>
				<div class="flex items-center space-x-6 font-semibold">
					<a class="transition-colors hover:text-blue-100" href="mailto:contact@captainjet.com">contact@captainjet.com</a>
					<div class="h-4.5 w-px bg-blue-400" />
					<a class="whitespace-nowrap transition-colors hover:text-blue-100" :href="`tel:${phoneNumber}`" v-text="displayablePhoneNumber" />
				</div>
			</div>
		</div>
		<div class="bg-blue-700">
			<div class="mx-auto flex flex-col items-center justify-between gap-y-2 p-8 text-white lg:flex-row lg:py-3.5">
				<div class="flex flex-col items-center space-y-8 text-sm font-light text-blue-100 lg:flex-row lg:space-x-4 lg:space-y-0">
					<div class="whitespace-nowrap text-center">
						<span>&copy; {{ new Date().getFullYear() }}&nbsp;</span>
						<span class="font-semibold">CaptainJet SA</span>
					</div>

					<div class="flex flex-col items-center justify-center gap-y-2 space-x-2 sm:flex-row">
						<InertiaLink class="whitespace-nowrap transition-colors hover:text-white" :href="route('captainjet.web.legal-documents.privacy-policy')">
							Privacy
						</InertiaLink>
						<InertiaLink class="whitespace-nowrap transition-colors hover:text-white" :href="route('captainjet.web.legal-documents.cookies-policy')">
							Cookies
						</InertiaLink>
						<InertiaLink class="whitespace-nowrap transition-colors hover:text-white" :href="route('captainjet.web.legal-documents.terms-and-conditions')">
							Terms & Conditions
						</InertiaLink>
						<InertiaLink class="whitespace-nowrap transition-colors hover:text-white" :href="route('captainjet.web.legal-documents.terms-and-conditions-flight-brokerage')">
							Terms & Conditions (Flight Brokerage)
						</InertiaLink>
					</div>
				</div>
				<div class="flex gap-x-4">
					<a
						v-if="iosDownloadLink"
						class="mt-6 flex items-center gap-x-2 font-semibold transition-colors hover:text-blue-100 lg:mt-0"
						:href="iosDownloadLink"
					>
						<Icon name="IconApple" class="size-6" />
						<span>Download on iOS</span>
					</a>
					<a
						v-if="androidDownloadLink"
						class="mt-6 flex items-center gap-x-2 font-semibold transition-colors hover:text-blue-100 lg:mt-0"
						:href="androidDownloadLink"
					>
						<Icon name="IconPlayStore" class="size-6" />
						<span>Download on Android</span>
					</a>
				</div>
			</div>
		</div>
	</footer>
</template>
