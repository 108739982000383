<script setup lang="ts">
import { route } from '@/composables/route'
import { match } from '~/ts/utils/match'

const $props = defineProps<{
	status: number
	eventId?: string
}>()

const title = computed(() => match($props.status, {
	404: 'Page not found.',
	403: 'Forbidden.',
	401: 'Unauthorized.',
	500: 'Oops.',
	default: 'Oops.',
}))

const description = computed(() => match($props.status, {
	503: 'Sorry, we are doing some maintenance. Please check back soon.',
	404: 'Sorry, the page you are looking for could not be found.',
	403: 'Sorry, you are forbidden from accessing this page.',
	401: 'Sorry, you are not authorized to access this page.',
	500: 'Sorry, something happened. Try again later.',
	default: 'Sorry, something happened. Try again later.',
}))

const canGoBackHome = computed(() => ![503].includes($props.status))

useHead({
	title: () => title.value.replace(/\.$/, ''),
})

const { copied, copy } = useClipboard({ copiedDuring: 2500 })
const showEventId = computed(() => true && $props.eventId && [503, 500].includes($props.status))
</script>

<template>
	<div class="flex h-screen flex-col bg-white pb-12 pt-16">
		<main class="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8">
			<div class="pb-16 pt-10">
				<div class="text-center">
					<p class="text-lg font-semibold uppercase tracking-wide text-turquoise-600" v-text="status" />
					<h1 v-if="title" class="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl" v-text="title" />
					<p v-if="description" class="mt-2 text-lg text-gray-500" v-text="description" />
					<div class="mt-12 flex items-center justify-center">
						<BaseButton
							v-if="canGoBackHome"
							as="InertiaLink"
							variant="secondary"
							icon="ArrowLeft"
							size="sm"
							icon-class="h-3.5 w-3.5"
							class="space-x-2 px-4"
							:href="route('captainjet.web.index')"
						>
							Go back home
						</BaseButton>
					</div>
				</div>
			</div>
			<!-- Event ID -->
			<div v-if="showEventId" class="absolute inset-x-0 bottom-10 mt-2 flex flex-col items-center justify-center text-sm text-gray-400">
				<div>Share this code with us: </div>
				<div class="relative">
					<button class="font-medium" @click="copy(eventId!)" v-text="eventId" />
					<Icon
						name="IconCheck"
						class="absolute -right-6 top-1/2 translate-y-[calc(-50%-2px)] transition"
						:class="{
							'scale-100 opacity-100': copied,
							'scale-75 opacity-0': !copied
						}"
					/>
				</div>
			</div>
		</main>
	</div>
</template>
