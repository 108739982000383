<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import { useInfiniteScroll } from '~/vue/composables/useInfiniteScroll'
import { removeEmpty } from '~/ts/utils/object'
import { route } from '@/composables/route'
import loadingAnimation from '~/json/animations/estimates-loading.json'
import BookingData = Domain.BookingCaptainJet.Data.BookingData
import BookingEstimateData = Domain.BookingCaptainJet.Data.BookingEstimateData
import FilterEstimatesData = Domain.BookingCaptainJet.Data.FilterEstimatesData
import CurrencyData = Domain.AirportCaptainJet.Data.CurrencyData

const $props = defineProps<{
	booking: BookingData
	estimates?: Paginator<BookingEstimateData>
	filters: FilterEstimatesData
	currencies: CurrencyData[]
}>()

useHead({
	title: () => $props.estimates?.meta?.total
		? `${$props.estimates?.meta?.total} aircraft to ${$props.booking.legs?.at(0)?.arrival_airport?.served_city_and_served_city_governing_district_code}`
		: `Looking for aircraft to go to ${$props.booking.legs?.at(0)?.arrival_airport?.served_city_and_served_city_governing_district_code}...`,
})

const loadingMore = ref(false)
const selection = ref<Set<number>>(new Set())
const estimatesList = ref($props.estimates?.data ?? [])
const hasLoadedEstimates = ref(false)
const canSelectAircraft = computed(() => selection.value.size > 0)

watch(estimatesList, (estimates) => {
	estimates
		.filter(({ is_selected }) => is_selected)
		.forEach(({ id }) => selection.value.add(id))
})

function selectAircraft() {
	router.post(route('captainjet.web.booking.selected-estimates.store', { booking: $props.booking.id }), {
		estimates: Array.from(selection.value),
	})
}

function toggleAircraft(id: number) {
	if (isAircraftSelected(id)) {
		selection.value.delete(id)
	} else {
		selection.value.add(id)
	}
}

function isAircraftSelected(id: number) {
	return selection.value.has(id)
}

function updateCurrency(to: CurrencyData) {
	updateFilters({
		...$props.filters,
		currency: to.code,
	})
}

function updateFilters(filters: Partial<FilterEstimatesData>) {
	const data = removeEmpty(filters, (value, key) => {
		if (key === 'sizes') {
			return ['small', 'medium', 'large'].every((size) => value.includes(size))
		}
		if (key === 'amenities') {
			return ['smoking', 'wifi', 'lavatory'].every((amenity) => value.includes(amenity))
		}
		if (key === 'turbo_prop') {
			return value !== false
		}
		if (key === 'max_aircraft_age') {
			return value === 100
		}
		if (key === 'sort_by') {
			return value === 'estimated_price'
		}

		return value === 0 || value === null || value === undefined || value === false || value.length === 0
	})

	router.get($props.estimates!.meta.path!, data, {
		only: ['estimates', 'filters', 'errors'],
		preserveState: true,
		preserveScroll: true,
		onSuccess: () => {
			selection.value = new Set()
			estimatesList.value = $props.estimates?.data ?? []
		},
	})
}

onMounted(() => {
	router.reload({
		only: ['estimates', 'filters'],
		onSuccess: () => {
			hasLoadedEstimates.value = true
			estimatesList.value = ($props.estimates as any)?.data

			// To avoid that, we'd need to queue the estimate search and
			// instead store its results somewhere, which would kinda
			// introduce more complexity. So this is fine for now.
			if (estimatesList.value.length === 0) {
				router.reload({ only: ['notifications'] })
			}
		},
	})

	useInfiniteScroll(window, () => {
		if (!$props.estimates?.meta?.next_page_url) {
			return
		}

		router.visit($props.estimates?.meta?.next_page_url, {
			only: ['estimates'],
			preserveState: true,
			preserveScroll: true,
			onBefore: () => loadingMore.value = true,
			onFinish: () => loadingMore.value = false,
			onSuccess: (page) => {
				estimatesList.value.push(...(page.props.estimates as any)?.data)
			},
		})
	})
})
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<page-container v-auto-animate max-width-class="max-w-[90rem]" class="flex gap-x-4">
		<!-- Estimates -->
		<section class="grow">
			<!-- Actions -->
			<header class="sticky top-0 z-sticky-on-scroll -mx-4 -mt-10 bg-turquoise-50 px-4 pb-4 pt-10">
				<div class="flex flex-col items-center justify-between xs:flex-row">
					<!-- Controls and count -->
					<div class="flex w-full items-center justify-between gap-x-4 xs:w-auto xs:justify-around">
						<!-- Count -->
						<h2 class="font-display text-xl font-bold text-blue-500">
							<span v-if="estimatesList">{{ estimates?.meta?.total ?? 0 }}</span> Aircraft
						</h2>
						<!-- Controls -->
						<div class="flex gap-x-4">
							<!-- Filters -->
							<Popover v-slot="{ open }" class="relative">
								<Float
									portal
									placement="bottom"
									:show="open"
									:shift="10"
									:flip="true"
									enter="transition duration-100 ease-out"
									enter-from="scale-95 opacity-0 origin-top"
									enter-to="scale-100 opacity-100"
									leave="transition duration-75 ease-in origin-top"
									leave-from="scale-100 opacity-100"
									leave-to="scale-95 opacity-0"
									:offset="10"
								>
									<PopoverButton
										:class="[
											{
												'bg-turquoise-300 focus-visible:ring-0': open,
												'hover:border-turquoise-200': !open,
											},
											'flex size-9 items-center justify-center rounded-full border border-turquoise-100 bg-white text-turquoise-500 focus-visible:ring-2 focus-visible:ring-turquoise-600',
										]"
									>
										<icon-filter class="h-4 text-turquoise-500" />
									</PopoverButton>
									<PopoverPanel
										v-slot="{ close }"
										class="z-20 w-[calc(100vw-32px)] rounded-2xl bg-white shadow focus:outline-none xs:w-auto xs:min-w-[400px]"
									>
										<booking-aircraft-filter
											:filters="filters"
											:can-sort-by="booking.is_urgent"
											@update="updateFilters"
											@close="close"
										/>
									</PopoverPanel>
								</Float>
							</Popover>

							<!-- Currency -->
							<Menu v-slot="{ open }" as="div" class="relative">
								<Float
									portal
									placement="bottom"
									:show="open"
									:shift="10"
									:flip="true"
									enter="transition duration-100 ease-out"
									enter-from="scale-95 opacity-0 origin-top"
									enter-to="scale-100 opacity-100"
									leave="transition duration-75 ease-in origin-top"
									leave-from="scale-100 opacity-100"
									leave-to="scale-95 opacity-0"
									:offset="10"
								>
									<MenuButton
										:class="[
											{
												'focus-visible:ring-0': open,
												'hover:border-turquoise-200': !open,
											},
											'flex h-9 items-center justify-center space-x-2 rounded-full border border-turquoise-100 bg-white px-3.5 focus-visible:ring-2 focus-visible:ring-turquoise-600',
										]"
									>
										<span class="text-sm font-medium" v-text="$props.filters.currency" />
										<icon-chevron-down
											:class="[
												{ '-rotate-180': open },
												'w-2.5 text-turquoise-500 transition duration-75',
											]"
										/>
									</MenuButton>
									<MenuItems
										class="z-20 flex w-64 flex-col divide-y divide-turquoise-50 overflow-hidden rounded-2xl bg-white shadow focus:outline-none"
									>
										<MenuItem v-for="option in currencies" :key="option.code" v-slot="{ active }">
											<button
												:class="[
													{ 'text-blue-500': $props.filters.currency === option.code },
													{ 'text-blue-400': $props.filters.currency !== option.code },
													{ 'bg-turquoise-50': active },
													'flex items-center px-5 py-4 text-left',
												]"
												@click="updateCurrency(option)"
											>
												<div class="flex-1 font-medium">
													{{ option.name }}
													<span class="font-normal">({{ option.code }})</span>
												</div>
												<icon-true
													v-if="option.code === $props.filters.currency"
													class="w-3.5 text-warning-500"
												/>
											</button>
										</MenuItem>
									</MenuItems>
								</Float>
							</Menu>
						</div>
					</div>
					<!-- Select -->
					<base-button
						:disabled="!canSelectAircraft"
						icon-placement="right"
						icon="ChevronRight"
						size="lg"
						variant="primary"
						class="order-first mx-12 mb-4 w-full xs:order-last xs:m-0 xs:w-auto"
						@click="selectAircraft"
					>
						<span>Select {{ selection.size > 0 ? selection.size : '' }} aircraft</span>
					</base-button>
				</div>
				<p class="mt-5 text-xs text-blue-500">
					Estimated prices are excluding VAT (and Italian luxury taxes), are based on variable costs only, and
					include 12 minutes for taxi time.
				</p>
			</header>
			<section>
				<!-- Results -->
				<ul v-if="estimatesList.length" v-auto-animate class="flex flex-col gap-4">
					<li
						v-for="estimate in estimatesList"
						:key="estimate.id"
					>
						<booking-aircraft-entry
							:booking="booking"
							:estimate="estimate"
							:selected="isAircraftSelected(estimate.id)"
							@toggle="toggleAircraft(estimate.id)"
						/>
					</li>
				</ul>
				<!-- Loading -->
				<div
					v-else-if="!hasLoadedEstimates"
					class="flex w-full flex-col items-center justify-center space-y-1 rounded-2xl bg-white px-8 pb-12"
				>
					<lottie-player
						autoplay
						loop
						mode="normal"
						:src="loadingAnimation"
						class="-mb-5 -mt-2 w-64"
					/>
					<span class="text-blue-300">We are selecting the best aircraft for you, it may take a few seconds...</span>
				</div>
				<!-- No result -->
				<div v-else class="flex w-full items-center justify-center">
					<div class="flex w-full flex-col items-center justify-center rounded-xl bg-white py-10">
						<icon-calendar-illustration class="mb-6 w-36" />
						<span class="px-4 text-center text-blue-300">Unfortunately, we have no aircraft available for this trip.</span>
						<base-button class="mt-6" variant="primary" as="InertiaLink" :href="route('captainjet.web.index', { from: booking.id })">
							Search for another flight
						</base-button>
					</div>
				</div>
				<!-- Loading more -->
				<div
					class="flex items-center justify-center pt-16 transition"
					:class="loadingMore ? 'opacity-100' : 'opacity-0' "
				>
					<icon-loading class="size-6 text-blue-600" />
				</div>
			</section>
		</section>
		<!-- Routing -->
		<section class="sticky top-10 order-first mt-24 hidden w-1/3 min-w-[400px] self-start rounded-2xl bg-white p-6 xl:order-last xl:block xl:w-auto">
			<h1 class="font-display text-[0.95rem] font-semibold uppercase tracking-widest text-blue-200">
				Routing
			</h1>

			<div class="mt-6">
				<ul class="flex flex-col items-center justify-center gap-4 sm:flex-row xl:flex-col">
					<li v-for="(leg, index) in booking.legs" :key="index" class="w-full">
						<booking-routing-summary-card
							:name="booking.legs!.length > 1 ? `Flight n°${index + 1}` : 'Flight'"
							:departs-at="leg.departs_at_local_time"
							:departure-airport="leg.departure_airport!"
							:arrival-airport="leg.arrival_airport!"
							:passengers="leg.passenger_count!"
						/>
					</li>
				</ul>
			</div>

			<div class="mt-4 flex justify-center">
				<base-button
					as="InertiaLink"
					variant="secondary"
					size="lg"
					class="px-6"
					icon="Plus"
					icon-class="h-3.5 w-3.5"
					:href="route('captainjet.web.index', { from: booking.id })"
				>
					Edit routing
				</base-button>
			</div>
		</section>
	</page-container>
</template>
