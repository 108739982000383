<template>
	<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.25 2.5H.448A.446.446 0 0 0 0 2.946v1.906c.913.103 1.617.893 1.617 1.854 0 .961-.704 1.75-1.617 1.854v1.905c0 .24.192.447.448.447H3.25V2.5Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.874 2.5h7.944c.653 0 1.182.567 1.182 1.267v5.878c0 .7-.53 1.267-1.182 1.267H3.874V2.5Zm3.363 4.008v.205c0 .03.002.06.006.091h1.164l-.122.36h-.984c.071.296.2.532.388.707a.962.962 0 0 0 .67.256 1 1 0 0 0 .51-.137 1.22 1.22 0 0 0 .4-.387l.388.336c-.16.24-.35.422-.569.548a1.48 1.48 0 0 1-.729.182c-.407 0-.757-.137-1.047-.41-.291-.278-.48-.643-.57-1.095h-.505v-.36h.458a.789.789 0 0 1-.006-.09v-.206a.84.84 0 0 1 .006-.097h-.458v-.365h.506c.092-.445.283-.804.574-1.078a1.482 1.482 0 0 1 1.053-.416c.266 0 .509.06.729.182.22.122.41.303.569.542l-.4.342a1.189 1.189 0 0 0-.398-.393.984.984 0 0 0-.505-.137.95.95 0 0 0-.67.262 1.37 1.37 0 0 0-.394.696h1.35l-.121.365H7.243a.84.84 0 0 0-.006.097Z"
			fill="currentColor"
		/>
	</svg>
</template>
