<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { route } from '@/composables/route'
import { usePaymentConfirmationDialog } from '~/ts/dialog'

const { isOpen, close } = usePaymentConfirmationDialog()

function finishBookingProcess() {
	// TODO redirect to flight briefing page
	router.get(route('captainjet.web.booking.index', { scope: 'upcoming' }))
	close()
}
</script>

<template>
	<base-dialog
		:show="isOpen"
		title="Wire transfer"
		title-size="sm"
		@close="finishBookingProcess"
	>
		<form @submit.prevent="finishBookingProcess">
			<div class="space-y-5 border-t border-turquoise-100 p-6">
				<div class="mx-auto flex w-5/6 flex-col items-center justify-center gap-4 py-6 text-center text-blue-500">
					<icon-payment-complete class="size-24" />

					<span class="text-[1.7rem] font-bold text-success-500">Congratulations!</span>
					<p class="leading-tight">
						Your trip is now confirmed! <br />
						You can now open it and fill in the passenger list while we prepare it.
					</p>
				</div>

				<base-button
					type="submit"
					size="lg"
					class="w-full font-display text-lg font-bold tracking-tight"
					variant="primary"
				>
					View my flight
				</base-button>
			</div>
		</form>
	</base-dialog>
</template>
