<template>
	<svg viewBox="0 0 126 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			opacity=".3"
			d="m15.14 67.04 15.631 6.128 10.935 2.545 73.275-10.487-17.419-7.133-82.421 8.947Z"
			fill="#8EA1B4"
		/>
		<path d="M30.68 72.574 24.89 66.22l-3.2-3.523 7.367-.61.69 4.454.932 6.034Z" fill="#61A7B7" />
		<path d="m20.342 5.71 8.717 56.378-7.785.64-6.133 4.313" fill="#4F6B89" />
		<path d="m29.579 63.205-7.785.64-6 2.296L20.597 8.13l8.983 55.075Z" fill="#23466B" />
		<path
			d="m98.544 63.834-67.773 9.335-1.023-6.627-.69-4.454-8.716-56.377 67.399-.64 10.803 58.763Z"
			fill="#7289A1"
		/>
		<path
			d="m30.77 72.678-.09-.103-5.789-6.355-3.2-3.523-.418-.46 7.785-.64.69 4.454 1.022 6.627Z"
			fill="#8EA1B4"
		/>
		<path d="m24.456 66.232-3.182-3.505-5.4 3.795-.733.518 9.75-.33-.435-.478Z" fill="#1A3550" />
		<path d="m30.951 64.532 64.594-7.904-10-50.013-64-.64 9.406 58.557Z" fill="#8EA1B4" />
		<path
			d="m32.477 60.044 70.265-7.748S88.345 35.628 84.947 8.261L21.609 5.99s4.609 42.139 10.868 54.053Z"
			fill="#E4ECEF"
		/>
		<path
			d="m102.742 48.449-69.751 8.098S24.68 35.31 21.545 5.975l65.652-.656s1.54 28.598 15.545 43.13Z"
			fill="#fff"
		/>
		<path
			d="m30.771 73.168-5.88-6.459-.435-.477-3.182-3.504-6.133 4.313.046-.522 6.087-4.282.418.46 3.2 3.522 5.788 6.356.091.593Z"
			fill="#4F6B89"
		/>
		<path d="m20.23 6.475 10.164 65.988.377.215L20.34 5.71l-.11.765Z" fill="#4F6B89" />
		<path
			d="M27.163 7.686c.392 0 .71-.285.71-.637 0-.352-.318-.637-.71-.637-.392 0-.71.285-.71.637 0 .352.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M27.472 7.075s0 .171-.26.171-.293-.18-.293-.18.325-2.5-.067-4.148a.023.023 0 0 0 .002-.01c0-.003 0-.006-.002-.009-.213-.862-.622-1.48-1.385-1.352a1.072 1.072 0 0 0-.219.05c-.231.071-.436.219-.584.421-.655.712-.898 2.074-.757 3.68h-.255c-.292-2.265.293-4.151 1.476-4.585 1.184-.434 1.862.35 2.166 1.486a.044.044 0 0 1 0 .028c.488 1.802.178 4.448.178 4.448Z"
			fill="#4F6B89"
		/>
		<path
			d="M24.175 5.703h-.271c-.14-1.605.102-2.967.756-3.679-.52.697-.57 2.062-.485 3.68ZM35.1 7.638c.393 0 .71-.286.71-.638 0-.351-.317-.637-.71-.637-.392 0-.71.286-.71.637 0 .352.319.638.71.638Z"
			fill="#8EA1B4"
		/>
		<path
			d="M35.408 7.025s0 .175-.26.175-.292-.182-.292-.182.327-2.498-.064-4.147a.033.033 0 0 0 0-.022c-.214-.859-.626-1.48-1.385-1.35h-.015c-.312.041-.596.21-.792.472-.652.713-.894 2.074-.754 3.68h-.254c-.292-2.265.292-4.151 1.476-4.585 1.183-.434 1.861.35 2.165 1.486a.041.041 0 0 1 0 .025c.482 1.806.175 4.448.175 4.448Z"
			fill="#4F6B89"
		/>
		<path
			d="M32.11 5.657h-.27c-.141-1.606.101-2.967.753-3.68-.52.684-.567 2.062-.482 3.68ZM43.038 7.56c.392 0 .71-.286.71-.638 0-.352-.318-.637-.71-.637-.392 0-.71.285-.71.637 0 .352.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M43.344 6.947s0 .174-.26.174-.292-.18-.292-.18.327-2.5-.068-4.151a.023.023 0 0 0 0-.019c-.21-.859-.622-1.48-1.385-1.352-.073.01-.145.028-.216.053-.23.072-.434.218-.584.418-.652.712-.894 2.077-.757 3.68h-.251c-.293-2.262.277-4.151 1.46-4.582 1.184-.431 1.865.346 2.166 1.486a.03.03 0 0 1 0 .025c.494 1.802.187 4.448.187 4.448Z"
			fill="#4F6B89"
		/>
		<path
			d="M40.048 5.575h-.275c-.137-1.602.106-2.966.757-3.679-.52.688-.567 2.074-.482 3.68ZM50.972 7.51c.392 0 .71-.286.71-.638 0-.351-.318-.637-.71-.637-.392 0-.71.286-.71.637 0 .352.318.638.71.638Z"
			fill="#8EA1B4"
		/>
		<path
			d="M51.279 6.896s0 .172-.258.172c-.257 0-.292-.178-.292-.178s.328-2.499-.067-4.15a.023.023 0 0 0 0-.02c-.21-.862-.622-1.48-1.385-1.352a.918.918 0 0 0-.22.053 1.15 1.15 0 0 0-.584.419c-.654.712-.894 2.073-.756 3.679h-.255c-.292-2.265.278-4.15 1.461-4.585 1.184-.434 1.865.35 2.166 1.487a.05.05 0 0 1 0 .028c.5 1.802.19 4.447.19 4.447Z"
			fill="#4F6B89"
		/>
		<path
			d="M47.983 5.532h-.272c-.137-1.606.102-2.967.757-3.68-.52.678-.57 2.056-.485 3.68ZM58.907 7.456c.393 0 .71-.285.71-.637 0-.352-.317-.637-.71-.637-.392 0-.71.285-.71.637 0 .352.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M59.224 6.844s0 .175-.257.175c-.258 0-.292-.181-.292-.181s.324-2.499-.068-4.151a.023.023 0 0 0 .002-.01c0-.003 0-.006-.002-.009-.213-.859-.622-1.48-1.385-1.352-.074.01-.148.028-.219.053a1.145 1.145 0 0 0-.584.422c-.655.709-.897 2.073-.757 3.675h-.255c-.292-2.26.278-4.15 1.476-4.581 1.198-.431 1.862.35 2.165 1.487a.04.04 0 0 1 0 .025c.468 1.805.176 4.447.176 4.447Z"
			fill="#4F6B89"
		/>
		<path
			d="M55.92 5.473h-.272c-.14-1.602.102-2.967.757-3.676-.52.684-.57 2.061-.486 3.676ZM66.845 7.4c.392 0 .71-.286.71-.638 0-.352-.318-.637-.71-.637-.392 0-.71.285-.71.637 0 .352.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M67.15 6.782s0 .175-.26.175-.292-.182-.292-.182.328-2.498-.064-4.15a.023.023 0 0 0 0-.019c-.213-.862-.625-1.48-1.385-1.352a.916.916 0 0 0-.22.053c-.23.07-.435.217-.584.418-.651.712-.894 2.077-.754 3.68h-.254c-.292-2.265.278-4.151 1.476-4.585 1.198-.434 1.861.35 2.165 1.486a.05.05 0 0 1 0 .028c.48 1.809.173 4.448.173 4.448Z"
			fill="#4F6B89"
		/>
		<path
			d="M63.855 5.416h-.272c-.14-1.602.103-2.967.754-3.679-.52.687-.567 2.062-.482 3.68ZM74.78 7.34c.393 0 .71-.286.71-.637 0-.352-.317-.638-.71-.638-.392 0-.71.286-.71.638 0 .351.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M75.089 6.728s0 .175-.26.175-.292-.181-.292-.181.327-2.499-.065-4.151a.02.02 0 0 0 0-.019c-.21-.859-.622-1.48-1.385-1.349-.311.04-.596.21-.792.472-.651.709-.894 2.073-.756 3.675h-.252c-.292-2.26.292-4.147 1.476-4.581 1.183-.434 1.861.35 2.165 1.487a.041.041 0 0 1 0 .025c.468 1.805.161 4.447.161 4.447Z"
			fill="#4F6B89"
		/>
		<path
			d="M71.788 5.36h-.274c-.138-1.602.105-2.966.757-3.675-.518.68-.564 2.058-.483 3.675ZM82.714 7.284c.392 0 .71-.285.71-.637 0-.352-.318-.637-.71-.637-.392 0-.71.285-.71.637 0 .352.318.637.71.637Z"
			fill="#8EA1B4"
		/>
		<path
			d="M83.02 6.672s0 .175-.257.175-.292-.181-.292-.181.328-2.499-.067-4.15a.023.023 0 0 0 0-.02c-.21-.858-.622-1.48-1.385-1.352-.074.01-.146.028-.216.053-.23.072-.435.219-.585.419-.654.712-.894 2.077-.757 3.679h-.254c-.292-2.261.292-4.15 1.476-4.582 1.183-.43 1.864.347 2.165 1.487a.04.04 0 0 1 0 .025c.482 1.802.173 4.447.173 4.447Z"
			fill="#4F6B89"
		/>
		<path d="M79.725 5.301h-.272c-.137-1.602.103-2.967.757-3.679-.532.687-.57 2.064-.485 3.679Z" fill="#8EA1B4" />
		<path
			opacity=".3"
			d="m77.808 19.006-.187-.7L66 18.474l.192.708 3.194 11.95L81 30.443l-3.192-11.436ZM53.452 36.88l-.245-.897L38 36.2l.251.903 4.179 15.28 15.2-1.236-4.178-14.266Z"
			fill="#93BFC9"
		/>
		<path
			d="M84.995 26.87c.28.462-12.44 1.056-12.44 1.056L69.2 16.106l-.201-.7 11.758-.305.194.691s1.851 7.46 4.043 11.078ZM64.971 45.809c.403.659-17.825 1.508-17.825 1.508l-4.819-16.886-.292-1 16.855-.437.292.99s2.648 10.66 5.789 15.825Z"
			fill="#93BFC9"
		/>
		<path
			d="M38.54 25.024c-4.315.37-10.974.58-26.197.61-1.626 0-11.697.473-12.332-.24-.188-.211 2.169-.211 2.264-.61.494-2.067 3.983-5.934 5.871-4.543 2.995 2.2 2.38-3.549 8.376-2.491 1.461.266 3.56-3.417 5.66-3.653a6.8 6.8 0 0 1 1.673-.026c6.532.688 6.625 4.662 8.181 6.437 2.871 3.244 8.627 4.347 6.504 4.516Z"
			fill="#E4ECEF"
		/>
		<path
			d="M20.673 21.646c-1.516-3.225-9.76-4.292-8.103-1.613 1.658 2.68-2.164 1.735-2.06 2.422.102.687.985 2.183-2.454.474C4.618 21.22 2 24.72 2 24.72c.503-2.054 4.063-5.898 5.986-4.515 3.053 2.183 2.426-3.527 8.538-2.476 1.49.264 3.63-3.396 5.771-3.634A7.466 7.466 0 0 1 24 14.068c-5.238 1.496-1.802 10.815-3.327 7.578Z"
			fill="#BED9DF"
		/>
		<path
			d="M124.507 38.542c-4.834 1.343-14.638 1.066-14.638 1.066-1.142.099-2.288.099-3.43 0-1.3-.128.519-.064 2.443-.8a6.41 6.41 0 0 0 1.155-.543 4.543 4.543 0 0 0 1.611-1.439c.247-.41.545-.77.884-1.066l.026-.032c.067-.057.136-.11.208-.16a.383.383 0 0 0 .104-.095h.026c.026-.032.052-.032.078-.064 1.299-.928 2.573-.736 3.534-1.311.122-.066.235-.152.338-.256a.187.187 0 0 1 .104-.064c1.326-.991 2.553-.554 3.281 1.492.727 2.046 9.108 1.897 4.276 3.272Z"
			fill="#E4ECEF"
		/>
		<path
			d="M116.703 34.338c-.996.574-2.342.415-3.688 1.34h.027l.027-.032a.596.596 0 0 1-.135.096.38.38 0 0 1-.107.095c-.068.06-.14.114-.216.16-.003 0-.007 0-.01.002a.042.042 0 0 0-.009.007.107.107 0 0 0-.006.01.05.05 0 0 0-.002.013 6.623 6.623 0 0 0-.915 1.063A4.659 4.659 0 0 1 110 38.528c.35-.355 2.719 1.34 2.638-2.393.189-1.382 5.68 1.17 4.065-1.797Z"
			fill="#BED9DF"
		/>
		<path
			d="M56 40.147s-1.287-2.873-1.282-2.905c.352-.403.675-.835.967-1.292.125-.24.2-.506.223-.78a.188.188 0 0 0-.064-.128.161.161 0 0 0-.13-.04c-.282.058-.554.169-.803.326a11.44 11.44 0 0 0-1.2 1.091c-.029 0-3.286-.912-3.286-.912a.096.096 0 0 0-.126 0l-.188.206a.085.085 0 0 0 0 .116l2.903 1.244c-.077.1-.15.206-.227.317a19.45 19.45 0 0 0-1.06 1.687.087.087 0 0 1-.046.034.08.08 0 0 1-.056-.003l-1.32-.342a.077.077 0 0 0-.083 0l-.208.226c-.034.037 0 .1.043.127l1.36.596h.039v.031l.789 1.223c.019.02.044.031.07.031a.098.098 0 0 0 .07-.03l.203-.227a.067.067 0 0 0 0-.08l-.522-1.212-.034-.063c.574-.39 1.13-.81 1.665-1.26l.3-.253 1.582 2.51a.096.096 0 0 0 .06.02.096.096 0 0 0 .061-.02l.189-.206a.074.074 0 0 0 .062.01.075.075 0 0 0 .029-.015.085.085 0 0 0 .02-.027ZM78.83 22.658s-.714-1.519-.71-1.534a6.22 6.22 0 0 0 .698-.637.913.913 0 0 0 .182-.397.072.072 0 0 0-.01-.035.093.093 0 0 0-.026-.03.128.128 0 0 0-.04-.019.155.155 0 0 0-.046-.006c-.189.02-.37.07-.533.144-.293.159-.57.333-.83.523-.019 0-2.099-.571-2.099-.571a.075.075 0 0 0-.043-.013.076.076 0 0 0-.044.013l-.13.098a.04.04 0 0 0-.015.03.04.04 0 0 0 .015.03l1.851.736a10.87 10.87 0 0 0-.928.99.047.047 0 0 1-.027.017.06.06 0 0 1-.036-.003l-.848-.218a.069.069 0 0 0-.055 0l-.143.11a.039.039 0 0 0-.011.018.032.032 0 0 0 0 .02.04.04 0 0 0 .01.017.057.057 0 0 0 .021.011l.86.35h.024a.033.033 0 0 0 0 .021l.462.652a.044.044 0 0 0 .016.015.063.063 0 0 0 .023.009.073.073 0 0 0 .025 0 .062.062 0 0 0 .023-.01l.146-.107a.034.034 0 0 0 .007-.02.034.034 0 0 0-.007-.022l-.288-.642v-.033a12.926 12.926 0 0 0 1.346-.72l.963 1.38c.01.008.025.012.04.012a.072.072 0 0 0 .039-.012l.13-.098a.044.044 0 0 0 .013-.017.035.035 0 0 0 .003-.02.04.04 0 0 0-.01-.018.057.057 0 0 0-.018-.014Z"
			fill="#fff"
		/>
	</svg>
</template>
