<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.563 22.416V4.764m.195 3.543 2.146-2.218m-2.381 6.259L8.918 8.622m12.786.02a3.527 3.527 0 0 0-.81-1.434 3.373 3.373 0 0 0-1.359-.887 5.644 5.644 0 0 0-.234-1.853 4.81 4.81 0 0 0-1.258-2.247 4.573 4.573 0 0 0-2.21-1.233 4.474 4.474 0 0 0-2.508.146c-.807.287-1.521.8-2.063 1.481a3.112 3.112 0 0 0-1.51-.849 3.046 3.046 0 0 0-1.718.1 3.607 3.607 0 0 0-1.923 1.858 3.79 3.79 0 0 0-.16 2.715c.033.104.066.202.104.306-.3.014-.598.073-.882.177A3.598 3.598 0 0 0 3.25 8.776a3.779 3.779 0 0 0-.16 2.714c.126.52.372 1 .718 1.398.347.398.782.703 1.268.887a3.081 3.081 0 0 0 2.945-.412 5.332 5.332 0 0 0 2.646 1.625 5.199 5.199 0 0 0 3.075-.147 5.645 5.645 0 0 0 2.785-2.257c.417.374.921.627 1.463.734a3.038 3.038 0 0 0 1.622-.123 3.607 3.607 0 0 0 1.921-1.847c.39-.846.45-1.814.172-2.706v0ZM7.484 23.12h10.447"
			stroke="currentColor"
			stroke-width="1.8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
