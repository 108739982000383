<template>
	<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14 7.853c0-.695-.411-1.29-.99-1.528l-.791-3.283c-.245-1.014-1.138-1.779-2.079-1.779h-.506L9.59.747C9.55.3 9.161 0 8.62 0H5.403c-.541 0-.93.3-.97.747l-.045.516h-.506c-.94 0-1.834.765-2.078 1.778L1.01 6.324c-.578.239-.99.834-.99 1.529v3.994a1.43 1.43 0 0 0-.021.244v.573C0 13.401.567 14 1.264 14h.496c.697 0 1.264-.6 1.264-1.336v-.573a1.5 1.5 0 0 0-.005-.107h7.959a1.363 1.363 0 0 0-.002.073v.573c0 .736.567 1.335 1.264 1.335h.496c.697 0 1.264-.599 1.264-1.335v-.573l-.002-.073H14V7.853Zm-1.276 4.764h-.472v-.548h.472v.548ZM1.297 10.636V7.853c0-.152.11-.277.25-.29h10.927c.14.013.25.138.25.29v2.783H1.297Zm1.742-7.261c.11-.457.524-.764.842-.764h6.26c.317 0 .732.307.842.764l.684 2.838H2.354l.685-2.838Zm-1.29 9.277h-.473v-.548h.472v.548Z"
			fill="currentColor"
		/>
		<path
			d="M4.279 8.35H2.385a.493.493 0 0 0-.479.505v.52c0 .278.215.505.479.505h1.894a.493.493 0 0 0 .478-.506v-.519a.492.492 0 0 0-.478-.505ZM11.732 8.35H9.838a.493.493 0 0 0-.479.505v.52c0 .278.215.505.479.505h1.894a.493.493 0 0 0 .478-.506v-.519a.492.492 0 0 0-.478-.505Z"
			fill="currentColor"
		/>
	</svg>
</template>
