<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.445 9.75h3.232a.827.827 0 1 0 0-1.655H8.272v-3.35a.827.827 0 1 0-1.654 0v4.177c0 .457.37.827.827.827Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.343 13.657A7.946 7.946 0 0 0 8 16a7.95 7.95 0 0 0 5.657-2.343A7.947 7.947 0 0 0 16 8a7.947 7.947 0 0 0-2.343-5.657A7.948 7.948 0 0 0 8 0a7.946 7.946 0 0 0-5.657 2.343A7.947 7.947 0 0 0 0 8c0 2.137.832 4.146 2.343 5.657Zm1.17-10.144A6.304 6.304 0 0 1 8 1.654c1.695 0 3.288.66 4.487 1.859A6.304 6.304 0 0 1 14.345 8c0 1.695-.66 3.288-1.858 4.487A6.304 6.304 0 0 1 8 14.345a6.304 6.304 0 0 1-4.487-1.858A6.305 6.305 0 0 1 1.654 8c0-1.695.66-3.289 1.859-4.487Z"
			fill="currentColor"
		/>
	</svg>
</template>
