<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1095 991">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M257.988 44.835C169.978 94.936 91.35 181.726 47.63 267.074c-25.379 49.543-65.028 171.475-35.058 303.378 29.953 131.829 129.482 273.78 382.751 363.388 268.174 94.88 462.031 56.88 576.291-29.06 114.296-85.961 148.996-219.86 99.056-317.13-43-83.746-121.06-97.192-202.171-107.44-4.046-.511-8.1-1.014-12.157-1.518-77.156-9.577-155.602-19.314-206.78-87.305-49.408-65.639-48.022-120.775-46.734-172.052.115-4.575.229-9.119.307-13.638.471-27.578-.431-54.15-10.829-80.922-10.393-26.759-30.304-53.792-67.982-82.203-81.121-61.17-178.33-47.836-266.336 2.263Zm-.772-1.355c88.298-50.265 186.234-63.844 268.047-2.153 37.855 28.545 57.977 55.797 68.497 82.883 10.514 27.072 11.406 53.903 10.934 81.514-.078 4.522-.191 9.067-.306 13.638-1.284 51.247-2.654 105.892 46.42 171.087 50.768 67.446 128.572 77.111 205.883 86.715 4.003.497 8.006.994 12.003 1.499 81.059 10.241 159.956 23.73 203.366 108.275 50.36 98.091 15.25 232.777-99.508 319.088-114.794 86.334-309.237 124.284-577.749 29.284C141.11 845.552 41.141 703.227 11.051 570.797c-30.073-132.357 9.698-254.668 35.19-304.434C90.09 180.768 168.923 93.743 257.217 43.48h-.001Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M263.689 96.508c-81.246 47.226-153.42 127.879-193.235 207.125-23.229 46.606-58.7 158.355-30.817 278.869 28.249 121.203 120.53 251.122 352.932 332.965 248.985 87.443 429.817 53.843 536.555-25.208C1035.28 811.46 1068.4 687.7 1022.23 595.035c-16.06-32.216-37.788-53.603-62.889-68.472-23.127-13.7-49.22-21.784-76.668-27.613-18.605-3.952-37.792-6.862-57.087-9.787-9.188-1.393-18.4-2.79-27.586-4.305-56.884-9.381-112.578-23.264-153.565-68.899l.57-.512-.57.512a180.803 180.803 0 0 1-8.837-10.625l-.001-.002c-45.604-59.675-46.245-109.855-46.837-156.151-.05-4.448-.12-8.895-.211-13.342-.55-25.001-2.389-48.961-12.836-72.936-10.442-23.964-29.517-48.015-64.665-73.105-75.97-54.232-166.112-40.515-247.359 6.71Zm-.783-1.348c81.528-47.389 172.382-61.361 249.048-6.631 35.322 25.214 54.609 49.471 65.189 73.751 10.574 24.267 12.414 48.484 12.965 73.525.097 4.421.154 8.868.212 13.348.594 46.264 1.233 95.977 46.516 155.232a179.073 179.073 0 0 0 8.759 10.532c40.6 45.204 95.807 59.027 152.658 68.402 9.142 1.508 18.332 2.901 27.508 4.293 19.321 2.929 38.586 5.851 57.236 9.812 27.527 5.847 53.806 13.975 77.139 27.797 25.348 15.016 47.294 36.622 63.484 69.118 46.58 93.477 13.06 218.019-93.567 297.172h-.001c-107.273 79.449-288.687 112.979-538 25.427h-.001C159.228 834.947 66.506 704.657 38.118 582.856v-.001c-27.988-120.969 7.603-233.094 30.94-279.918l.001-.002C109 223.437 181.38 142.549 262.906 95.16Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M269.194 148.091C194.653 192.413 128.942 266.93 93.278 340.194c-21.223 43.597-52.373 145.23-26.577 254.358 26.546 110.577 111.579 228.464 323.114 302.54 229.8 79.998 397.606 50.808 496.821-21.354 98.01-71.638 129.544-185.237 87.154-273.319-14.332-29.776-34.002-49.956-56.725-64.379-35.343-22.434-78.503-30.401-122.456-38.514-7.856-1.45-15.737-2.905-23.604-4.448-51.796-10.16-102.858-24.14-141.081-66.04a168.548 168.548 0 0 1-8.287-9.757c-41.989-53.584-44.624-98.805-47.035-140.175-.255-4.373-.507-8.703-.803-12.996-1.548-22.444-4.286-43.823-14.747-65.027-10.456-21.195-28.666-42.286-61.274-64.057-70.841-47.3-154.04-33.257-228.583 11.065h-.001Zm-.797-1.34c74.816-44.485 158.706-58.787 230.246-11.022 32.778 21.885 51.202 43.167 61.807 64.664 10.601 21.488 13.353 43.12 14.904 65.61.296 4.302.549 8.636.805 13.009 2.412 41.338 5.025 86.118 46.704 139.308a166.929 166.929 0 0 0 8.212 9.667c37.855 41.497 88.464 55.407 140.23 65.561 7.826 1.535 15.682 2.984 23.523 4.431 43.941 8.107 87.45 16.134 123.072 38.744 22.954 14.57 42.829 34.966 57.294 65.02 42.776 88.882 10.86 203.259-87.64 275.255l-.001.001c-99.754 72.551-268.137 101.671-498.252 21.565h-.001C177.346 824.342 91.872 706.088 65.185 594.915v-.003c-25.904-109.579 5.363-211.587 26.691-255.4 35.79-73.524 101.705-148.276 176.521-192.761Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M274.785 199.73c-67.793 41.435-127.027 109.815-158.682 177.022-19.138 40.633-46.32 132.176-22.337 229.849 24.56 100.018 102.622 205.806 293.294 272.116 210.613 72.553 365.393 47.773 457.086-17.5 89.867-64.477 119.792-167.891 81.203-251.415-12.625-27.326-30.221-46.306-50.566-60.289-31.892-21.919-71.163-30.746-111.336-39.776-6.467-1.453-12.957-2.912-19.443-4.431-46.708-10.942-93.136-25.026-128.592-63.189-2.636-2.827-5.234-5.774-7.737-8.888-38.142-47.442-42.792-87.614-47.003-123.983-.504-4.359-1.003-8.663-1.552-12.918-2.563-19.874-6.222-38.657-16.713-57.079-10.487-18.416-27.84-36.537-57.897-54.993-65.74-40.366-141.928-25.963-209.725 15.474Zm-.813-1.331c68.06-41.597 144.915-56.268 211.354-15.472 30.221 18.556 47.789 36.852 58.437 55.551 10.644 18.692 14.334 37.722 16.904 57.65.551 4.269 1.05 8.581 1.556 12.942 4.21 36.338 8.816 76.101 46.667 123.182 2.475 3.078 5.047 5.997 7.663 8.803l.001.001c35.112 37.792 81.125 51.796 127.806 62.731 6.457 1.513 12.93 2.967 19.388 4.418 40.152 9.021 79.757 17.919 111.918 40.022 20.559 14.131 38.348 33.322 51.099 60.921 38.952 84.312 8.662 188.497-81.71 253.336l-.002.002C752.818 928.14 597.465 952.84 386.55 880.19h-.002c-191.083-66.453-269.59-172.601-294.297-273.217-24.094-98.125 3.205-190.043 22.441-230.885l.704.331-.704-.331c31.78-67.474 91.217-136.089 159.28-177.689Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M280.364 251.38c-61.034 38.549-113.773 100.788-141.435 161.926-17.046 37.676-40.07 119.077-18.1 205.341 22.775 89.416 93.549 183.504 263.475 241.693 191.295 65.51 333.338 44.955 417.356-13.649 81.866-57.101 110.003-150.51 75.248-229.511-10.938-24.864-26.439-42.646-44.41-56.201-28.493-21.49-63.974-31.241-100.473-41.271-4.995-1.373-10.009-2.751-15.028-4.165-41.617-11.727-83.407-25.924-116.094-60.345-2.441-2.558-4.861-5.213-7.188-8.02-34.137-41.173-40.778-76.192-46.718-107.509-.842-4.443-1.67-8.811-2.561-13.111-3.583-17.3-8.168-33.486-18.687-49.128-10.517-15.64-27.009-30.797-54.499-45.943-60.676-33.432-129.847-18.66-190.886 19.893Zm-.832-1.319c61.291-38.712 131.099-53.755 192.47-19.94 27.647 15.233 44.352 30.542 55.042 46.439 10.688 15.894 15.322 32.316 18.919 49.682.895 4.321 1.726 8.701 2.57 13.15 5.938 31.29 12.513 65.94 46.382 106.791 2.298 2.771 4.691 5.397 7.116 7.938l.001.002c32.371 34.089 73.79 48.197 115.388 59.919a2298.728 2298.728 0 0 0 15.008 4.158c36.464 10.017 72.26 19.851 101.009 41.534 18.164 13.7 33.843 31.688 44.898 56.818 35.093 79.771 6.613 173.948-75.783 231.418-84.564 58.985-227.174 79.45-418.753 13.846-170.336-58.33-241.555-152.774-264.48-242.784-22.086-86.715 1.05-168.489 18.189-206.369l.71.322-.71-.322c27.786-61.411 80.727-123.887 142.024-162.602Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M285.927 303.053c-54.257 35.664-100.478 91.753-124.171 146.807-14.944 34.725-33.808 105.975-13.863 180.833 20.998 78.814 84.576 160.906 233.654 211.271 172.091 58.14 301.153 41.96 377.628-9.796 73.738-49.904 100.165-133.094 69.292-207.609-9.277-22.389-22.663-38.977-38.259-52.117-25.179-21.211-57.029-31.999-90.023-43.175-3.404-1.15-6.806-2.307-10.205-3.47-36.524-12.519-73.672-26.839-103.587-57.517-2.244-2.288-4.483-4.65-6.636-7.149l.574-.494-.574.494c-29.875-34.698-38.417-64.395-45.95-90.588-1.348-4.685-2.663-9.259-4.063-13.728-4.609-14.721-10.122-28.31-20.669-41.178-10.548-12.868-26.172-25.065-51.075-36.915-55.662-26.486-117.81-11.336-172.073 24.331ZM586.393 468.28v-.001l.558-.544-.557.546-.001-.001ZM285.071 301.75c54.505-35.826 117.252-51.248 173.599-24.436 25.05 11.919 40.881 24.244 51.611 37.335 10.731 13.092 16.318 26.9 20.951 41.7 1.41 4.502 2.732 9.096 4.084 13.794 7.531 26.172 15.983 55.546 45.623 89.97 2.122 2.464 4.335 4.799 6.569 7.076l.001.001c29.634 30.39 66.461 44.616 102.976 57.132 3.414 1.17 6.826 2.325 10.229 3.478 32.942 11.154 65.081 22.037 90.498 43.45 15.766 13.282 29.313 30.067 38.695 52.712 31.184 75.264 4.433 159.219-69.858 209.497-77.025 52.129-206.647 68.212-379.001 9.983-149.482-50.502-213.506-132.943-234.662-212.348-20.064-75.305-1.093-146.927 13.937-181.85l.716.308-.716-.308c23.815-55.336 70.237-111.664 124.748-147.494Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M291.469 354.766c-47.459 32.772-87.131 82.692-106.885 131.645l-.723-.292.723.292c-12.827 31.783-27.526 92.87-9.628 156.325 19.239 68.21 75.612 138.287 203.835 180.852 152.895 50.755 268.961 38.953 337.901-5.945 65.596-42.719 90.256-115.631 63.333-185.709-7.646-19.901-18.894-35.295-32.113-48.037-22.012-21.218-50.515-33.265-80.309-45.858-1.554-.656-3.108-1.313-4.661-1.971-31.424-13.316-63.918-27.774-91.056-54.703l-.001-.001.549-.554-.547.556-.001-.001c-2.048-2.018-4.106-4.088-6.084-6.278l.578-.523-.578.523c-25.165-27.871-35.336-51.956-44.185-72.911-2.201-5.212-4.321-10.23-6.568-15.065-5.642-12.134-12.092-23.126-22.668-33.225-10.579-10.102-25.329-19.351-47.618-27.925-50.716-19.51-105.83-3.971-153.294 28.805Zm-.886-1.283c47.697-32.937 103.361-48.743 154.74-28.978 22.424 8.626 37.368 17.971 48.135 28.253 10.771 10.286 17.32 21.469 23.005 33.695 2.274 4.891 4.409 9.947 6.621 15.183 8.843 20.935 18.902 44.748 43.875 72.405 1.948 2.157 3.98 4.201 6.023 6.214l.002.002c26.901 26.694 59.134 41.056 90.567 54.375 1.583.671 3.165 1.339 4.744 2.007 29.717 12.558 58.472 24.71 80.699 46.135 13.361 12.878 24.749 28.46 32.486 48.601 27.201 70.801 2.242 144.475-63.937 187.575-69.494 45.259-186.111 56.952-339.243 6.118-128.62-42.696-185.44-113.112-204.845-181.909-18.023-63.898-3.222-125.354 9.683-157.332 19.871-49.244 59.742-99.404 107.445-132.344Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M297.552 404.692c-40.818 30.484-74.28 75.432-90.14 118.264-10.684 28.855-21.215 79.761-5.395 131.818 17.505 57.605 66.657 115.639 174.016 150.438 133.714 43.341 236.758 35.927 298.18-2.096 57.434-35.554 80.234-98.11 57.369-163.811-6.054-17.396-15.141-31.597-25.972-43.966-18.575-21.215-43.384-34.844-69.734-48.981l-2.32-1.245c-25.626-13.739-52.534-28.167-76.174-50.662-1.851-1.748-3.725-3.527-5.531-5.406l.562-.54-.562.54c-19.463-20.265-30.615-37.573-40.244-52.518-4.005-6.216-7.747-12.024-11.714-17.465-6.763-9.277-14.207-17.544-24.768-25.089-10.567-7.548-24.286-14.395-43.618-20.787-45.086-14.907-93.13 1.017-133.955 31.506Zm-.933-1.25c41.041-30.65 89.618-46.867 135.378-31.736 19.436 6.426 33.306 13.335 44.034 20.998 10.733 7.667 18.29 16.068 25.122 25.439 4.044 5.547 7.824 11.415 11.852 17.667 9.603 14.907 20.617 32.003 39.971 52.155 1.776 1.849 3.625 3.603 5.478 5.353l.003.003c23.484 22.347 50.229 36.688 75.891 50.448l2.265 1.214c26.315 14.119 51.38 27.868 70.17 49.328 10.941 12.495 20.142 26.866 26.272 44.481 23.105 66.39.035 129.711-58.021 165.65-61.981 38.368-165.564 45.661-299.482 2.253-107.744-34.923-157.345-93.281-175.027-151.467-15.953-52.493-5.331-103.767 5.424-132.813l.732.271-.732-.271c15.97-43.127 49.621-88.317 90.67-118.973Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M485.712 447.914c-15.439-13.823-33.452-23.138-67.351-27.48-41.087-5.263-82.083 11.104-115.857 38.032-33.772 26.927-60.237 64.351-72.262 101.029-8.504 25.939-14.858 66.637-1.167 107.311 15.818 46.992 57.718 92.947 144.197 120.029 114.56 35.876 204.542 32.872 258.467 1.752 24.649-14.225 42.139-34.365 51.152-58.593 9.013-24.23 9.567-52.604.246-83.326-4.515-14.879-11.419-27.881-19.837-39.907-14.046-20.068-33.327-35.157-54.542-50.1-4.357-3.068-8.796-6.131-13.286-9.23-17.348-11.97-35.467-24.472-52.59-39.891l-.001-.001.522-.58-.52.582-.001-.001-.684-.611c-1.432-1.277-2.882-2.571-4.291-3.921-11.855-11.35-21.033-21.663-29.184-30.868l-.655-.739c-7.885-8.907-14.817-16.738-22.356-23.487Zm53.273 53.968-.539.563.539-.563c1.388 1.329 2.815 2.602 4.249 3.881l.688.615.003.002c17.041 15.345 35.054 27.775 52.393 39.74 4.501 3.106 8.956 6.18 13.338 9.266 21.233 14.956 40.709 30.175 54.922 50.48 8.493 12.134 15.48 25.283 20.051 40.349 9.405 30.997 8.873 59.726-.277 84.323-9.15 24.599-26.9 45.01-51.833 59.399-54.486 31.445-144.994 34.312-259.713-1.614-86.848-27.197-129.198-73.456-145.209-121.019-13.833-41.097-7.405-82.159 1.163-108.294l.741.242-.741-.242c12.128-36.993 38.783-74.663 72.772-101.763 33.988-27.099 75.395-43.693 117.027-38.36 34.164 4.376 52.485 13.801 68.193 27.865 7.607 6.811 14.594 14.702 22.454 23.58l.685.774c8.148 9.203 17.29 19.474 29.094 30.776Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M471.176 478.465c-17.585-8.565-37.406-12.564-65.96-10.812-36.342 2.23-70.23 19.04-97.12 42.959-26.892 23.921-46.737 54.909-55.021 85.412-6.256 23.032-8.421 53.477 3.053 82.801 14.223 36.353 48.801 70.184 114.38 89.632 95.448 28.307 172.307 29.763 218.768 5.596 20.547-10.689 35.398-26.414 43.371-46.574 7.976-20.168 9.093-44.842 2.045-73.457-3.043-12.355-7.751-24.155-13.705-35.866-18.048-35.503-50.718-62.679-85.725-91.799a7436.044 7436.044 0 0 1-6.873-5.722c-.522-.434-1.051-.868-1.582-1.304a244.77 244.77 0 0 1-2.832-2.349l-1.019-.865c-19.271-16.338-34.462-29.216-51.78-37.652Zm53.808 37.327-.505.595.505-.595c.926.786 1.859 1.552 2.796 2.321.534.438 1.069.877 1.605 1.321l.002.002c2.322 1.938 4.64 3.865 6.949 5.786 34.929 29.051 67.842 56.426 86.041 92.227 5.995 11.793 10.752 23.705 13.829 36.2 7.106 28.851 6.013 53.866-2.109 74.403-8.125 20.545-23.257 36.541-44.102 47.384-47.021 24.459-124.389 22.85-219.93-5.484-65.922-19.55-100.95-53.654-115.389-90.559-11.63-29.722-9.421-60.527-3.106-83.778 8.373-30.83 28.4-62.071 55.49-86.168 27.093-24.099 61.299-41.095 98.061-43.351 28.766-1.765 48.865 2.262 66.738 10.967 17.497 8.522 32.82 21.514 52.008 37.782l1.117.947Z" fill="#FDF5E9" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M513.905 533.734c-31.817-23.714-73.132-34.645-121.789-18.904-62.776 20.308-106.829 69.061-116.207 117.705-4.686 24.304-.72 48.575 13.869 69.311 14.594 20.743 39.873 38.027 77.957 48.232 38.181 10.23 73.2 16.875 103.566 18.814 30.376 1.94 56.031-.833 75.527-9.379 19.459-8.53 32.806-22.818 38.62-44.019 5.826-21.246 4.107-49.508-6.771-85.98-10.584-35.486-32.928-72.045-64.772-95.78Zm-122.269-20.388c49.176-15.909 91.012-4.854 123.2 19.138 32.161 23.971 54.674 60.838 65.336 96.584 10.918 36.606 12.715 65.195 6.78 86.839-5.948 21.688-19.641 36.33-39.498 45.034-19.82 8.688-45.759 11.455-76.253 9.508-30.504-1.949-65.631-8.619-103.87-18.865-38.336-10.272-63.972-27.724-78.829-48.841-14.861-21.123-18.88-45.836-14.124-70.503 9.503-49.3 54.042-98.443 117.258-118.894Z" fill="#FDF5E9" />
	</svg>
</template>
