<script lang="ts" setup>
import { router } from '@inertiajs/vue3'
import { invoke } from '@vueuse/core'
import { useModal } from 'momentum-modal'
import BaseInertiaDialog from '@/components/BaseInertiaDialog.vue'
import { useWizard } from '@/composables/useWizard'
import { match } from '~/ts/utils/match'
import { Document } from '~/ts/passengers'
import { route } from '@/composables/route'
import LegData = Domain.BookingCaptainJet.Data.LegData
import CreatePassengerData = Domain.BookingCaptainJet.Data.CreatePassengerData

const props = defineProps<{
	leg: LegData
}>()

const form = useForm<CreatePassengerData>({
	species: 'cat',
	gender: 'female',
	type: 'person',
	travel_documents: [],
	first_name: '',
	weight: undefined,
	last_name: undefined,
})

const stepper = useWizard([
	'create-passenger',
	'create-document',
] as const)

/*
|--------------------------------------------------------------------------
| Documents
|--------------------------------------------------------------------------
*/

const documentBeingCreated = ref<Partial<Document>>({})

function createDocument() {
	documentBeingCreated.value.type = 'id_card'
	stepper.goTo('create-document')
}

function removeDocument(index: number) {
	form.travel_documents.splice(index, 1)
}

function isDocumentBeingCreatedValid() {
	if (!documentBeingCreated.value.type) {
		return false
	}

	if (!documentBeingCreated.value.country?.name) {
		return false
	}

	if (!documentBeingCreated.value.attachments?.filter(Boolean).length) {
		return false
	}

	return true
}

/*
|--------------------------------------------------------------------------
| Wizard
|--------------------------------------------------------------------------
*/

const metadata = computed(() => match(stepper.current.value, {
	'create-passenger': {
		title: 'Create passenger',
		subtitle: undefined,
		progress: form.travel_documents.length ? 3 : 1,
		canContinue: invoke(() => {
			if (form.type === 'person') {
				return form.first_name?.trim() && form.last_name?.trim() && form.gender && form.travel_documents.length
			}

			if (form.type === 'pet') {
				return form.first_name?.trim() && form.weight && form.species && form.travel_documents.length
			}

			return false
		}),
		continueText: form.travel_documents.length ? 'Create passenger' : 'Continue',
	},
	'create-document': {
		title: 'Create travel document',
		progress: 2,
		canContinue: isDocumentBeingCreatedValid(),
		continueText: 'Continue',
	},
}))

function back() {
	if (stepper.currentStepIs('create-passenger')) {
		router.visit(route('captainjet.web.leg.passengers.show', { leg: props.leg }), {
			onBefore: () => useModal().close(),
		})

		return
	}

	if (stepper.currentStepIs('create-document')) {
		documentBeingCreated.value = {}
		stepper.goTo('create-passenger')
	}
}

function next() {
	if (stepper.currentStepIs('create-document')) {
		if (isDocumentBeingCreatedValid()) {
			form.travel_documents.push({
				type: documentBeingCreated.value.type!,
				country_iso_code: documentBeingCreated.value.country!.iso2.toLowerCase(),
				attachments: documentBeingCreated.value.attachments!,
			})
			back()
		}

		return
	}

	if (stepper.currentStepIs('create-passenger')) {
		form.post(route('captainjet.web.passenger.store', { leg: props.leg.id }))
	}
}
</script>

<template>
	<BaseInertiaDialog
		title-size="sm"
		:title="metadata.title"
		:subtitle="metadata.subtitle"
	>
		<template #back>
			<button
				class="absolute left-6 flex items-center justify-center space-x-2 text-sm text-blue-200"
				type="button"
				@click="back"
			>
				<icon-chevron-narrow-left class="w-3.5" />
				<span>Back</span>
			</button>
		</template>

		<div class="relative flex min-h-[420px] flex-col">
			<div class="h-1 bg-turquoise-50">
				<div class="h-full bg-turquoise-500" :style="{ width: `${(metadata.progress / 4) * 100}%` }" />
			</div>

			<!-- Create Passenger -->
			<form v-if="stepper.active('create-passenger')" id="create-passenger" class="flex-1" @submit.prevent>
				<div class="px-6 py-4">
					<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
						Type
					</span>

					<div class="mt-3 flex space-x-3">
						<selectable-button
							size="sm"
							class="flex-1 space-x-2.5 text-sm"
							icon="User"
							icon-class="w-4 h-4"
							:checked="form.type === 'person'"
							@click="form.type = 'person'"
						>
							Person
						</selectable-button>
						<selectable-button
							size="sm"
							class="flex-1 space-x-2.5 text-sm"
							icon="Dinosaur"
							icon-class="w-4 h-4"
							:checked="form.type === 'pet'"
							@click="form.type = 'pet'"
						>
							Pet
						</selectable-button>
					</div>
				</div>

				<div class="space-y-6 border-t border-turquoise-100 px-6 py-4">
					<!-- Inputs -->
					<div>
						<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
							Information
						</span>

						<div class="mt-3 space-y-4">
							<template v-if="form.type === 'person'">
								<BaseInput
									id="first_name"
									v-model="form.first_name"
									name="first_name"
									label="First name"
									type="text"
									:error="form.errors.first_name"
									@clear="form.reset('first_name')"
								/>

								<BaseInput
									id="last_name"
									v-model="form.last_name"
									name="last_name"
									label="Last name"
									type="text"
									:error="form.errors.last_name"
									@clear="form.reset('last_name')"
								/>
							</template>

							<template v-if="form.type === 'pet'">
								<BaseInput
									id="pet_name"
									v-model="form.first_name"
									name="pet_name"
									label="Pet name"
									type="text"
									:error="form.errors.first_name"
									@clear="form.reset('first_name')"
								/>

								<BaseInput
									id="pet_weight"
									v-model="form.weight"
									name="weight"
									label="Weight (kg)"
									type="number"
									:step="0.1"
									:error="form.errors.weight"
									@clear="form.reset('weight')"
								/>
							</template>
						</div>
					</div>

					<!-- Basic fields (informations) -->
					<div>
						<span class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
							<template v-if="form.type === 'person'">
								Gender
							</template>
							<template v-if="form.type === 'pet'">
								Species
							</template>
						</span>

						<div class="mt-3 flex space-x-3">
							<template v-if="form.type === 'person'">
								<selectable-button
									size="sm"
									class="flex-1 space-x-2.5 text-sm"
									icon="Female"
									icon-class="w-4 h-4"
									:checked="form.gender === 'female'"
									@click="form.gender = 'female'"
								>
									Female
								</selectable-button>
								<selectable-button
									size="sm"
									class="flex-1 space-x-2.5 text-sm"
									icon="Male"
									icon-class="w-4 h-4"
									:checked="form.gender === 'male'"
									@click="form.gender = 'male'"
								>
									Male
								</selectable-button>
							</template>

							<template v-if="form.type === 'pet'">
								<selectable-button
									size="sm"
									class="flex-1 space-x-2.5 text-sm"
									icon="Cat"
									icon-class="w-4 h-4"
									:checked="form.species === 'cat'"
									@click="form.species = 'cat'"
								>
									Cat
								</selectable-button>
								<selectable-button
									size="sm"
									class="flex-1 space-x-2.5 text-sm"
									icon="Dog"
									icon-class="w-4 h-4"
									:checked="form.species === 'dog'"
									@click="form.species = 'dog'"
								>
									Dog
								</selectable-button>
								<selectable-button
									size="sm"
									class="flex-1 space-x-2.5 text-sm"
									icon="Mouse"
									icon-class="w-4 h-4"
									:checked="form.species === 'other'"
									@click="form.species = 'other'"
								>
									Other
								</selectable-button>
							</template>
						</div>
					</div>

					<!-- Documents -->
					<div>
						<p class="text-xs font-medium uppercase leading-3 tracking-widest text-blue-200">
							Documents
						</p>

						<button
							class="mt-3 flex w-full flex-col items-center"
							:class="{ 'rounded-xl border border-turquoise-200 p-4 transition hover:border-turquoise-400': form.travel_documents.length === 0 }"
							@click="createDocument"
						>
							<div v-if="form.travel_documents.length === 0" class="flex flex-col items-center">
								<illustration-travel-document class="h-14" />
								<p class="mt-3.5 text-sm text-blue-300">
									Please provide a valid travel document
								</p>
							</div>

							<div v-else class="mb-1.5 w-full space-y-4">
								<booking-passenger-document
									v-for="(document, id) in form.travel_documents"
									:key="document.type + id"
									:create="document"
									@remove="removeDocument(id)"
								/>
							</div>

							<base-button
								size="sm"
								variant="secondary"
								class="mt-4 space-x-2 px-4 text-15"
								icon="Download"
								icon-class="h-4.5 w-4.5"
								@click.prevent.stop="createDocument"
							>
								Add document
							</base-button>
						</button>
					</div>
				</div>
			</form>

			<!-- Create travel document -->
			<booking-documents-create
				v-if="stepper.active('create-document')"
				v-model="documentBeingCreated"
			/>

			<div class="border-t border-turquoise-100 px-6 py-4">
				<base-button
					form="create-passenger"
					type="submit"
					size="lg"
					class="w-full space-x-2.5"
					icon="ChevronRight"
					icon-placement="right"
					icon-class="h-3.5 -mb-0.5"
					variant="primary"
					:disabled="!metadata.canContinue"
					:loading="form.processing"
					@click="next"
				>
					{{ metadata.continueText }}
				</base-button>
			</div>
		</div>
	</BaseInertiaDialog>
</template>
