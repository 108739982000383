<script setup lang="ts">
const props = defineProps<{
	count: number
	selectedIndex: number
	onClickPrevious?: (event: MouseEvent) => void
	onClickIndex?: (value: number) => void
	onClickNext?: (event: MouseEvent) => void
}>()
</script>

<template>
	<div class="flex items-center justify-between">
		<!--Previous button-->
		<button
			class="stroke-white p-6 transition-[stroke] disabled:stroke-blue-500"
			:disabled="props.selectedIndex <= 0"
			@click="props.onClickPrevious"
		>
			<svg fill="none" viewBox="0 0 9 17" width="9">
				<path stroke-width="2" d="M8 1 2 8.5 8 16" />
			</svg>
		</button>
		<!--Index toggles-->
		<div class="flex items-center">
			<label
				v-for="n in count"
				:key="n"
				class="cursor-pointer p-[5px]"
			>
				<input
					class="peer sr-only"
					type="radio"
					name="step"
					:checked="(n - 1) === selectedIndex"
					@change="() => props.onClickIndex && props.onClickIndex(n - 1)"
				/>
				<div class="size-1.5 rounded-full bg-blue-400 transition-[width,color] peer-checked:w-5 peer-checked:bg-white" />
			</label>
		</div>
		<!--Next button-->
		<button
			class="stroke-white p-6 transition-[stroke] disabled:stroke-blue-500"
			:disabled="props.selectedIndex >= (props.count - 1)"
			@click="props.onClickNext"
		>
			<svg fill="none" viewBox="0 0 9 17" width="9">
				<path stroke-width="2" d="m1 16 6-7.5L1 1" />
			</svg>
		</button>
	</div>
</template>
