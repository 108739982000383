<script setup lang="ts">
import { parsePhoneNumberFromString } from 'libphonenumber-js'

const $props = defineProps<{
	address?: string
	phone?: string
}>()

const addressWithoutPhone = computed(() => {
	if (!$props.address) {
		return
	}

	return $props.address
		.split('\n')
		.filter((part) => !part.startsWith('+')).join('\n')
})

const googleMapsUrl = computed(() => {
	if (!addressWithoutPhone.value) {
		return
	}

	return `https://www.google.com/maps/search/?api=1&query=${addressWithoutPhone.value.replaceAll('\n', '+')}`
})

const phone = computed(() => {
	if ($props.phone) {
		return $props.phone
	}

	if (!$props.address) {
		return
	}

	return $props.address
		.split('\n')
		.find((part) => part.startsWith('+'))
})

const phoneUrl = computed(() => {
	if (!phone.value) {
		return
	}

	return `tel:${phone.value}`
})

const formattedPhone = computed(() => {
	if (!phone.value) {
		return
	}

	return parsePhoneNumberFromString(phone.value)?.formatInternational() ?? phone.value
})
</script>

<template>
	<p>
		<component
			:is="googleMapsUrl ? 'a' : 'p'"
			v-if="addressWithoutPhone || ! phone"
			:href="googleMapsUrl"
			class="block whitespace-pre-wrap"
			v-text="addressWithoutPhone ?? 'None'"
		/>
		<a
			v-if="phone"
			:href="phoneUrl"
		>
			Phone: {{ formattedPhone }}
		</a>
	</p>
</template>
