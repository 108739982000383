<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.734 8.905a18.24 18.24 0 0 0-4.416-.62c1.404-.116 2.51-1.256 2.51-2.641V3.93c0-1.462-1.233-2.651-2.748-2.651h-.17c-1.516 0-2.75 1.19-2.75 2.651v1.716c0 1.385 1.108 2.522 2.51 2.64-1.486.025-2.968.235-4.415.622-1.158.31-1.975 1.346-1.975 2.515v2.773c0 .29.242.523.543.523h12.354c.3 0 .543-.234.543-.523v-2.773c0-1.169-.817-2.205-1.986-2.519Zm-4.752.6-.203-.178h.387l-.184.178ZM6.253 5.644V3.93c0-.882.745-1.605 1.664-1.605h.17c.915 0 1.664.719 1.664 1.605v1.716c0 .883-.745 1.605-1.664 1.605h-.17c-.919-.004-1.664-.722-1.664-1.608Zm-3.888 5.78c0-.701.488-1.322 1.19-1.51.89-.241 1.798-.405 2.709-.5l1.194 1.043v3.217H4.969v-.66a.532.532 0 0 0-.542-.523c-.3 0-.543.234-.543.523v.66H2.365v-2.25Zm11.27 2.25h-1.548v-.66a.532.532 0 0 0-.543-.523c-.3 0-.542.234-.542.523v.66h-2.46v-3.23l1.09-1.04c.947.09 1.887.258 2.813.51.702.188 1.19.809 1.19 1.51v2.25Z"
			fill="currentColor"
		/>
		<path
			d="M10.829 10.457h-.76a.713.713 0 0 0-.723.698c0 .384.325.698.723.698h.76a.713.713 0 0 0 .723-.698.71.71 0 0 0-.723-.697Z"
			fill="currentColor"
		/>
	</svg>
</template>
