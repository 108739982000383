import { router } from '@inertiajs/vue3'

if (import.meta.env.VITE_APP_ENV === 'production') {
	router.on('navigate', (event) => {
		gtag('event', 'page_view', {
			page_location: event.detail.page.url,
		})

		fbq('track', 'PageView')
	})
}
