<template>
	<svg viewBox="0 0 91 73" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M60.6813 53.8359H48.8203V72.9865H60.6813V53.8359Z" fill="#8EA1B4" />
		<path d="M29.1479 9.0957H68.7258C74.4724 9.0957 79.9838 11.3776 84.0485 15.4398C88.1133 19.5019 90.3987 25.0119 90.4023 30.7585V51.0897C90.4023 51.8179 90.113 52.5162 89.5981 53.0311C89.0832 53.546 88.3849 53.8353 87.6567 53.8353H29.1479C27.6624 53.8353 26.2378 53.2452 25.1874 52.1948C24.137 51.1444 23.5469 49.7198 23.5469 48.2343V14.6967C23.5469 13.2112 24.137 11.7866 25.1874 10.7362C26.2378 9.68581 27.6624 9.0957 29.1479 9.0957V9.0957Z" fill="#CFD8E0" />
		<path d="M48.8069 27.505V51.0485C48.8069 51.7767 48.5177 52.4751 48.0028 52.99C47.4879 53.5049 46.7895 53.7941 46.0613 53.7941H15.7361C15.0015 53.7944 14.2959 53.5073 13.77 52.9943C13.2442 52.4813 12.9398 51.783 12.9219 51.0485V26.9421C12.9231 24.5185 13.4154 22.1202 14.369 19.892C15.3225 17.6638 16.7176 15.6519 18.4701 13.9777C20.2226 12.3034 22.2961 11.0016 24.5655 10.1508C26.8349 9.29992 29.2532 8.91767 31.6743 9.02708C36.3536 9.32574 40.7397 11.4092 43.9277 14.8474C47.1156 18.2857 48.8622 22.8165 48.8069 27.505V27.505Z" fill="#4F6B89" />
		<path d="M37.7823 37.7743L48.2293 52.4221C48.902 53.3694 48.2293 54.1244 46.6918 54.1244H16.2979C15.5569 54.1305 14.8227 53.9823 14.142 53.6893C13.4613 53.3962 12.8491 52.9647 12.3443 52.4221L1.6776 37.4449C-2.82519 31.1437 2.04825 26.0506 12.5639 26.3526C22.4893 26.6272 33.4717 31.8301 37.7823 37.7743Z" fill="#CFD8E0" />
		<path d="M65.5388 28.4666H60.5419L64.3857 30.6356C63.9112 31.1912 63.3218 31.6373 62.6582 31.943C61.9946 32.2487 61.2725 32.4069 60.5419 32.4065C59.4852 32.4366 58.4436 32.1507 57.5503 31.5855C56.6569 31.0203 55.9526 30.2015 55.5272 29.2337C55.1018 28.266 54.9749 27.1933 55.1625 26.153C55.3501 25.1127 55.8438 24.152 56.5804 23.3938C57.317 22.6356 58.2631 22.1144 59.2976 21.8969C60.3321 21.6794 61.4079 21.7754 62.3875 22.1727C63.3671 22.57 64.206 23.2505 64.7967 24.1271C65.3874 25.0038 65.7032 26.0367 65.7036 27.0938C65.7117 27.5568 65.6562 28.0187 65.5388 28.4666V28.4666Z" fill="white" />
		<path d="M81.7819 27.1619C81.7823 27.6217 81.7456 28.0808 81.6721 28.5347H60.5447C60.1806 28.5347 59.8314 28.39 59.5739 28.1326C59.3165 27.8751 59.1719 27.526 59.1719 27.1619C59.1719 26.7978 59.3165 26.4486 59.5739 26.1911C59.8314 25.9337 60.1806 25.7891 60.5447 25.7891H80.4091C80.7732 25.7891 81.1224 25.9337 81.3798 26.1911C81.6373 26.4486 81.7819 26.7978 81.7819 27.1619V27.1619Z" fill="#8EA1B4" />
		<path d="M81.9178 27.107V32.0628L79.1722 30.5665L76.7148 32.2138V25.7754H80.5862C80.936 25.7857 81.2688 25.9294 81.5163 26.1769C81.7638 26.4244 81.9074 26.7571 81.9178 27.107Z" fill="#4F6B89" />
		<path d="M9.39062 38.625H28.4314L29.9826 41.0411H10.7772L9.39062 38.625Z" fill="#4F6B89" />
		<path d="M10.9901 0H25.4401C25.9705 0 26.4792 0.210712 26.8543 0.585785C27.2294 0.960858 27.4401 1.46957 27.4401 2V16.45C27.4401 16.9804 27.2294 17.4891 26.8543 17.8642C26.4792 18.2393 25.9705 18.45 25.4401 18.45H22.6601V21.33L18.6601 18.45H11.0001C10.4697 18.45 9.96096 18.2393 9.58589 17.8642C9.21081 17.4891 9.0001 16.9804 9.0001 16.45V2.02C8.99746 1.75652 9.04691 1.49512 9.14561 1.25081C9.24431 1.0065 9.39034 0.784098 9.57527 0.59639C9.76019 0.408682 9.98035 0.25936 10.2232 0.157013C10.466 0.0546664 10.7266 0.00130427 10.9901 0Z" fill="#93BFC9" />
		<path d="M14.5234 9.78027L17.1134 12.9203L22.0434 6.28027" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
	</svg>
</template>
