<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.441 7.464h.069c2.897 0 3.422.597 3.49.85v.045c-.091.23-.73.919-3.08 2.136A48.455 48.455 0 0 1 15.125 13c.457-.46.753-.781.753-.781.092-.092.114-.207.046-.322-.069-.115-.205-.16-.32-.115l-5.224 1.654c-.045.023-.114.069-.137.115l-.319.505a15.244 15.244 0 0 1-3.764-.436c1.14-.184 2.281-.437 3.034-.666.137-.046.274-.092.388-.161.228-.138.388-.299.502-.505a.796.796 0 0 0 .068-.552l-.023-.045c-.114-.345-.57-1.746-.73-2.274 1.05-.597 2.92-1.447 3.924-1.838 1.186-.459 3.673-1.217 4.974-1.056.042.01.085.016.126.022a.697.697 0 0 1 .125.024c.009 0 .022.004.038.008.022.007.049.015.076.015 0 0 .365.069.296.115-.388.252-.775.55-.775.55a.21.21 0 0 0-.023.254l.342.987c.114.16.365.092.433.023 0 0 .046-.023.365-.253.074-.052.163-.117.259-.187.323-.235.724-.528.882-.616Zm-7.71 3.468a.857.857 0 0 0 .478-.414.831.831 0 0 0 .046-.62.86.86 0 0 0-.41-.482.816.816 0 0 0-1.14.988c.067.206.227.39.41.482.182.091.41.115.615.046Zm3.307-1.057a.857.857 0 0 0 .48-.413.83.83 0 0 0 .045-.62.809.809 0 0 0-1.027-.529.856.856 0 0 0-.479.414.83.83 0 0 0-.046.62.86.86 0 0 0 .411.482c.183.092.41.115.616.046Z"
			fill="currentColor"
		/>
		<path
			d="M5.247 9.944c-.479.23-.935.46-1.369.689-.41.23-.775.436-1.072.62-.068.023-.114.023-.16-.023L.023 8.06C-.023 7.993 0 7.9.09 7.878l.639-.23h.023l.274-.069a.069.069 0 0 1 .09 0l4.13 2.16c.091.045.091.16 0 .206ZM9.376 11.827c.023.07 0 .161-.045.23a1.208 1.208 0 0 1-.342.184c-.662.206-1.597.413-2.601.597l-.547.09c-.363.06-.706.116-1.027.162l-.548.07-.32.022h-.068c-.034 0-.074.006-.114.012-.04.005-.08.011-.114.011-.06 0-.114.007-.165.013a1.12 1.12 0 0 1-.131.01h-.069c-.098 0-.189.007-.274.013-.073.005-.141.01-.205.01-.16 0-.274 0-.342-.023-.091 0-.16-.069-.183-.16l-.09-.276c-.024-.092 0-.184.068-.23a.518.518 0 0 0 .08-.056c.024-.018.05-.039.08-.059a.5.5 0 0 0 .056-.034.501.501 0 0 1 .057-.034.904.904 0 0 1 .092-.07s.022 0 .022-.022c.023-.012.04-.023.057-.035.018-.011.035-.023.058-.034.022-.023.09-.07.09-.07.024 0 .046-.022.07-.045.82-.528 2.075-1.194 3.262-1.723a25.906 25.906 0 0 1 1.412-.593.12.12 0 0 0 .048-.027l.068-.023a.42.42 0 0 1 .16-.046h.023c.034-.011.062-.023.09-.034l.092-.035H8.1c.011 0 .023-.005.034-.011.012-.006.023-.011.034-.011.046-.023.069-.023.092-.023.068-.023.16-.023.228-.023A.24.24 0 0 1 8.6 9.6c.296.528.775 2.227.775 2.227ZM12.962 14.408c.657-.608 1.23-1.137 1.479-1.364.023-.045 0-.091-.046-.068l-3.422 1.01a.694.694 0 0 0-.296.207L8.144 17.43c-.022.046 0 .069.046.069.153-.036.484-.084.811-.131.306-.044.609-.088.763-.122a.543.543 0 0 0 .251-.137c.406-.35 1.804-1.645 2.947-2.702Z"
			fill="currentColor"
		/>
	</svg>
</template>
