<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import { openDialog } from '~/ts/dialog'
import { route } from '@/composables/route'
import UserData = SupportCaptainJet.Data.UserData

const $props = defineProps<{
	marketing?: boolean
}>()

const user = useProperty<UserData>('security.user')
const currentRoute = useProperty<string>('security.current_route')
const isScrollingDown = ref(false)
const isScrollingUp = ref(false)
const prevScroll = ref(0)
const isMobileMenuOpen = ref(false)
const variant = computed(() => isMobileMenuOpen.value || isScrollingUp.value ? 'primary' : ($props.marketing ? 'white' : 'primary'))

useEventListener('scroll', () => {
	const isScrolling = window.scrollY > 0
	isScrollingDown.value = isScrolling && window.scrollY > prevScroll.value
	isScrollingUp.value = isScrolling && !isScrollingDown.value
	prevScroll.value = window.scrollY
})
</script>

<template>
	<header
		:class="[
			marketing && 'fixed inset-x-0 z-navbar transition-[top,margin] duration-300',
			isScrollingDown && !isMobileMenuOpen ? '-top-20' : 'top-0',
			isScrollingUp && 'md:m-8',
		]"
	>
		<nav
			class="transition-colors duration-300 md:rounded-full md:px-8 md:py-5"
			:class="{
				'bg-white/80 backdrop-blur-xl': isScrollingUp,
			}"
		>
			<div class="relative z-navbar flex items-center justify-between p-3.5 pl-4 md:p-0">
				<HeaderLogo
					:variant="variant"
					:class="{
						'md:mt-1': isScrollingUp,
					}"
				/>

				<!-- Mobile menu button -->
				<button
					type="button"
					class="group flex size-10 flex-col items-center justify-center space-y-0.5 rounded-full md:hidden"
					:class="[isMobileMenuOpen ? 'bg-blue-600' : 'bg-white']"
					@click="isMobileMenuOpen = !isMobileMenuOpen"
				>
					<span :class="[isMobileMenuOpen ? '-translate-y-px translate-x-0.5 rotate-45 bg-white' : 'bg-turquoise-500', 'h-0.5 w-3.5 origin-left rounded-full transition-all duration-300']" />
					<span :class="[isMobileMenuOpen ? 'w-0 bg-white' : 'w-3.5 bg-turquoise-500', 'h-0.5 rounded-full transition-all duration-300']" />
					<span :class="[isMobileMenuOpen ? 'translate-x-0.5 translate-y-px -rotate-45 bg-white' : 'bg-turquoise-500', 'h-0.5 w-3.5 origin-left rounded-full transition-all duration-300']" />
				</button>

				<!-- Desktop links -->
				<div class="hidden items-center space-x-8 md:flex">
					<HeaderLink
						v-if="user"
						:variant="variant"
						:href="route('captainjet.web.logout')"
						as="button"
						method="delete"
					>
						Logout
					</HeaderLink>

					<HeaderLink
						v-if="user?.is_admin"
						:variant="variant"
						:href="route('filament.captainjet.pages.dashboard')"
						:external="true"
					>
						Administration
					</HeaderLink>

					<HeaderLink
						v-if="user"
						:variant="variant"
						:active="currentRoute.includes('settings')"
						:href="route('captainjet.web.settings.index')"
					>
						Settings
					</HeaderLink>

					<HeaderLink
						:active="currentRoute === 'index'"
						:variant="variant"
						:href="route('captainjet.web.index')"
					>
						Search
					</HeaderLink>

					<HeaderLink
						:active="currentRoute === 'about'"
						:variant="variant"
						:href="route('captainjet.web.about')"
					>
						About us
					</HeaderLink>

					<HeaderLink
						v-if="user"
						:variant="variant"
						:href="route('captainjet.web.booking.index')"
						:active="currentRoute.startsWith('captainjet.web.booking')"
					>
						Trips
					</HeaderLink>

					<BaseButton
						v-if="!user"
						variant="blue"
						size="sm"
						icon="User"
						@click="openDialog('login')"
					>
						Login
					</BaseButton>
				</div>
			</div>

			<!-- Mobile menu links -->
			<transition
				enter-active-class="transition duration-300 ease-out origin-top"
				enter-from-class="-translate-y-16 opacity-0"
				enter-to-class="-translate-y-0 opacity-100"
				leave-active-class="transition duration-300 ease-in origin-top"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="-translate-y-16 opacity-0"
			>
				<div v-if="isMobileMenuOpen" class="absolute inset-x-0 top-0 z-navbar-dropdown rounded-b-2xl bg-white pt-16 shadow md:hidden">
					<div class="flex flex-col border-t border-turquoise-50 pt-3">
						<HeaderMenuLink
							v-if="user?.is_admin"
							:href="route('filament.captainjet.pages.dashboard')"
							:external="true"
						>
							Administration
						</HeaderMenuLink>

						<HeaderMenuLink
							v-if="user"
							:active="currentRoute.includes('settings')"
							:href="route('captainjet.web.settings.index')"
						>
							Settings
						</HeaderMenuLink>

						<HeaderMenuLink
							:active="currentRoute === 'index'"
							:href="route('captainjet.web.index')"
						>
							Search
						</HeaderMenuLink>

						<HeaderMenuLink
							:active="currentRoute === 'about'"
							:href="route('captainjet.web.about')"
						>
							About us
						</HeaderMenuLink>

						<HeaderMenuLink
							v-if="user"
							:href="route('captainjet.web.booking.index')"
							:active="currentRoute.startsWith('captainjet.web.booking')"
						>
							Trips
						</HeaderMenuLink>

						<BaseButton
							v-if="!user"
							variant="blue"
							size="sm"
							class="!my-3 mx-4 lg:mt-0"
							icon="User"
							@click="openDialog('login')"
						>
							Login
						</BaseButton>

						<InertiaLink
							v-if="user"
							:href="route('captainjet.web.logout')"
							method="delete"
							as="button"
							class="flex items-center space-x-2.5 border-t border-turquoise-50 px-4 py-3 font-medium text-danger-500 transition hover:text-danger-400"
						>
							<IconPowerOff class="size-4" />
							<span>Logout</span>
						</InertiaLink>
					</div>
				</div>
			</transition>
		</nav>
	</header>
</template>
