<script setup lang="ts">
import { SwitchGroup, SwitchLabel } from '@headlessui/vue'
import IconWifi from '@/components/Icon/Wifi.vue'
import IconLavatory from '@/components/Icon/Lavatory.vue'
import IconCigarette from '@/components/Icon/Cigarette.vue'
import FilterEstimatesData = Domain.BookingCaptainJet.Data.FilterEstimatesData

const props = defineProps<{
	filters: FilterEstimatesData
	canSortBy: boolean
}>()

const emit = defineEmits<{
	(e: 'update', filters: Partial<FilterEstimatesData>): void
	(e: 'close'): void
}>()

const turboprops = ref(true)
const directFlightsOnly = ref(false)
const age = ref(0)

const aircraftTypes = reactive([
	{
		key: 'small',
		label: 'Small',
		checked: true,
	},
	{
		key: 'medium',
		label: 'Medium',
		checked: true,
	},
	{
		key: 'large',
		label: 'Large',
		checked: true,
	},
])

const sortBy = reactive([
	{
		key: 'positioning_time',
		label: 'Positioning time',
		checked: false,
	},
	{
		key: 'estimated_price',
		label: 'Best price',
		checked: true,
	},
])

const amenities = reactive([
	{
		key: 'smoking_allowed',
		label: 'Smoking',
		icon: markRaw(IconCigarette),
		checked: true,
	},
	{
		key: 'wifi',
		label: 'Internet',
		icon: markRaw(IconWifi),
		checked: true,
	},
	{
		key: 'lavatory',
		label: 'Lavatory',
		icon: markRaw(IconLavatory),
		checked: true,
	},
])

function toggleSortBy(key?: string, checked?: boolean) {
	sortBy.forEach((item) => {
		if (item.key === key) {
			item.checked = checked ?? !item.checked
		} else {
			item.checked = false
		}
	})
}

function reset() {
	turboprops.value = true
	directFlightsOnly.value = false
	age.value = 100
	aircraftTypes.forEach((type) => type.checked = true)
	amenities.forEach((amenity) => amenity.checked = true)
	sortBy.forEach((sortBy) => sortBy.checked = true)
}

function synchronizeWithProps() {
	turboprops.value = props.filters.turbo_prop !== false
	directFlightsOnly.value = Boolean(props.filters.direct_flight)
	age.value = props.filters.max_aircraft_age ?? 100
	aircraftTypes.forEach((type) => type.checked = Boolean(props.filters.sizes?.includes(type.key)))
	amenities.forEach((amenity) => amenity.checked = Boolean(props.filters.amenities?.includes(amenity.key)))

	if (props.canSortBy) {
		toggleSortBy(props.filters.sort_by ?? sortBy.at(1)?.key, true)
	}
}

function apply() {
	emit('update', {
		sizes: aircraftTypes.filter((type) => type.checked === true).map((type) => type.key),
		turbo_prop: turboprops.value,
		max_aircraft_age: age.value,
		direct_flight: directFlightsOnly.value,
		amenities: amenities.filter((amenity) => amenity.checked === true).map((amenity) => amenity.key),
		currency: props.filters.currency,
		sort_by: sortBy.find((sortBy) => sortBy.checked === true)?.key,
	})
	emit('close')
}

onMounted(synchronizeWithProps)
watch(props, synchronizeWithProps)

watch(aircraftTypes, () => {
	if (aircraftTypes.every((type) => type.checked === false)) {
		aircraftTypes.forEach((type) => type.checked = true)
	}
}, { immediate: true })
</script>

<template>
	<div class="border-b border-turquoise-100 py-3 text-center font-display font-bold uppercase tracking-widest text-blue-500">
		Filters
	</div>

	<div class="divide-y divide-turquoise-50">
		<div class="p-4">
			<div class="text-xs font-medium uppercase tracking-widest text-blue-200">
				Aircraft type
			</div>
			<div class="mt-2 flex items-center space-x-4">
				<SelectableButton
					v-for="type in aircraftTypes"
					:key="type.label"
					class="h-8 px-3 text-sm"
					:checked="type.checked"
					:title="type.label"
					size="custom"
					@click="type.checked = !type.checked"
				>
					{{ type.label }}
				</SelectableButton>
			</div>
		</div>

		<div class="flex items-center justify-between p-4">
			<div class="text-sm font-medium tracking-tight text-blue-500">
				Include turboprops
			</div>
			<InputSwitch v-model="turboprops" label="Include turboprops" />
		</div>

		<div class="divide-y divide-turquoise-50 border-y border-turquoise-100">
			<div v-if="canSortBy" class="p-4">
				<div class="text-xs font-medium uppercase tracking-widest text-blue-200">
					Sort by
				</div>
				<div class="mt-2 flex items-center space-x-4">
					<SelectableButton
						v-for="sort in sortBy"
						:key="sort.label"
						class="h-8 px-3 text-sm"
						:checked="sort.checked"
						:title="sort.label"
						size="custom"
						@click="toggleSortBy(sort.key)"
					>
						{{ sort.label }}
					</SelectableButton>
				</div>
			</div>

			<div class="p-4">
				<div class="text-xs font-medium uppercase tracking-widest text-blue-200">
					Options
				</div>
				<div class="mt-3 flex space-x-3.5">
					<button
						v-for="option in amenities"
						:key="option.label"
						type="button"
						:class="[
							{ 'border border-turquoise-500 bg-turquoise-500 text-white hover:bg-opacity-95 focus-visible:ring-offset-2': option.checked },
							{ 'border border-turquoise-100 text-turquoise-500 hover:border-turquoise-300': !option.checked },
							'flex size-26 flex-col justify-between rounded-xl border p-5 font-display text-sm font-medium transition-colors duration-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-600',
						]"
						@click="option.checked = !option.checked"
					>
						<div class="flex w-full items-start justify-between">
							<component :is="option.icon" class="size-7" />
							<IconTrue v-if="option.checked" class="mt-1 w-3 text-white" />
						</div>
						<p v-text="option.label" />
					</button>
				</div>
			</div>

			<div class="p-4 pt-3">
				<div class="text-xs font-medium uppercase tracking-widest text-blue-200">
					Time
				</div>
				<SwitchGroup as="div" class="mt-2 flex items-center justify-between">
					<SwitchLabel as="div" class="cursor-default text-sm font-medium tracking-tight text-blue-500">
						Direct flights only
					</SwitchLabel>

					<InputSwitch v-model="directFlightsOnly" label="Direct flights only" />
				</SwitchGroup>
			</div>

			<div class="px-4 pb-6 pt-3">
				<div class="flex items-center justify-between font-medium">
					<span class="text-xs uppercase tracking-widest text-blue-200">Jet Age</span>
					<span class="text-sm tracking-tighter text-blue-500">{{ age }} years</span>
				</div>
				<div class="mt-5">
					<InputSlider v-model="age" />
				</div>
			</div>
		</div>

		<div class="flex items-center justify-between p-4">
			<button
				type="button"
				class="flex items-center justify-center rounded-full px-3 py-1.5 font-medium text-blue-300 transition-colors duration-75 hover:text-blue-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-600"
				@click="reset"
			>
				Erase
			</button>

			<BaseButton
				type="button"
				size="lg"
				variant="primary"
				class="px-6 font-display !font-bold"
				@click="apply"
			>
				Apply filters
			</BaseButton>
		</div>
	</div>
</template>
