<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { route } from '@/composables/route'
import { LegWithRequests, requestsFromLegData } from '~/ts/estimates'
import { useProperty } from '@/composables/useProperty'
import { useLoginDialog } from '~/ts/dialog'
import LegData = Domain.BookingCaptainJet.Data.LegData

const $props = defineProps<{
	bookingId: string
	legs: LegData[]
}>()

useHead({
	title: 'Request summary',
})

const { open: openLoginDialog } = useLoginDialog()
const user = useProperty('security.user')
const legsWithRequests: LegWithRequests[] = reactive([])

onMounted(() => {
	legsWithRequests.push(...$props.legs.map((leg) => ({
		leg,
		requests: requestsFromLegData(leg),
	})))
})

function onSubmitFlightRequest() {
	if (!user.value) {
		return openLoginDialog({
			onSuccess: () => submitFlightRequest(),
		})
	}

	submitFlightRequest()
}

function submitFlightRequest() {
	router.post(route('captainjet.web.booking.requests.store', { booking: $props.bookingId }))
}
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<page-container>
		<!-- Title and CTA -->
		<header class="flex items-center justify-between">
			<h1 class="font-display text-xl font-bold leading-9 text-blue-500">
				Summary
			</h1>
			<base-button
				size="md"
				class="space-x-4 px-5"
				variant="primary"
				icon="ChevronRight"
				icon-placement="right"
				icon-class="h-3"
				@click="onSubmitFlightRequest"
			>
				<template v-if="user">
					Submit flight request
				</template>
				<template v-else>
					Login to submit request
				</template>
			</base-button>
		</header>

		<!-- Flight summaries -->
		<section class="mt-3.5 space-y-3.5">
			<booking-flight-summary-entry
				v-for="(entry, index) in legsWithRequests"
				:key="index"
				v-model="entry.requests"
				:booking-id="bookingId"
				:leg="entry.leg"
				:number="legsWithRequests.length > 1 ? index + 1 : undefined"
			/>
		</section>
	</page-container>
</template>
