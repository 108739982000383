<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.723 11.63a.719.719 0 0 0-1.01.127l-.444.571H1.732l-.444-.571a.719.719 0 0 0-1.01-.126.723.723 0 0 0-.126 1.013l.66.85a.719.719 0 0 0 .568.278h13.24a.72.72 0 0 0 .568-.278l.66-.85a.722.722 0 0 0-.125-1.013ZM2.015 11.159h11.97a.72.72 0 0 0 .72-.722 6.726 6.726 0 0 0-5.893-6.673A1.1 1.1 0 0 0 8 1.92a1.1 1.1 0 0 0-.812 1.844 6.726 6.726 0 0 0-5.893 6.673.72.72 0 0 0 .72.722ZM8 5.157c2.66 0 4.864 1.987 5.216 4.558H2.783C3.136 7.145 5.341 5.157 8 5.157Z"
			fill="currentColor"
		/>
		<path
			d="M6.187 6.157c-.039.018-.957.441-1.747 1.498a.542.542 0 0 0 .432.866.538.538 0 0 0 .432-.217c.61-.816 1.314-1.154 1.33-1.161a.542.542 0 0 0-.447-.986Z"
			fill="currentColor"
		/>
	</svg>
</template>
