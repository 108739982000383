<script setup lang="ts">
import { useProperty } from '@/composables/useProperty'
import PdfUserData = SupportCaptainJet.Data.PdfUserData

const $props = defineProps<{
	title: string
	user?: PdfUserData
}>()

const company = useProperty('captainjet.company')
const authenticatedUser = useProperty('security.user')
const isBusinessGetter = computed(() => authenticatedUser.value?.is_business_getter || $props.user?.is_business_getter)
</script>

<template>
	<!-- Header -->
	<div
		class="print:bg-white"
		:style="{
			'--brand-color': isBusinessGetter ? 'var(--brand-neutral)' : 'var(--brand-captainjet)',
			'--brand-title-color': isBusinessGetter ? 'var(--brand-title-neutral)' : 'var(--brand-title-captainjet)'
		}"
	>
		<div class="print:fixed print:inset-x-0 print:top-0 print:p-5">
			<div class="bg-brand mb-4 flex h-16 items-center justify-between rounded px-5 text-white">
				<h1 class="text-justify font-display font-bold uppercase tracking-widest">
					{{ title }}
				</h1>
				<IconCaptainJet v-if="!isBusinessGetter" class="h-5" />
				<img v-if="isBusinessGetter && (authenticatedUser?.business_logo_url || $props.user?.business_logo_url)" class="h-full py-1" :src="authenticatedUser?.business_logo_url || $props.user?.business_logo_url" alt="Company logo" />
			</div>
		</div>

		<!-- Content -->
		<div class="text-brand p-5">
			<table class="w-full">
				<thead>
					<tr>
						<td>
							<!-- Top margin on each page -->
							<div class="print:h-20" />
						</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div class="-mt-4">
								<slot />
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<!-- Bottom margin on each page -->
						<td>
							<div class="print:h-20" />
						</td>
					</tr>
				</tfoot>
			</table>
		</div>

		<!-- Footer -->
		<div v-if="!isBusinessGetter" class="print:fixed print:inset-x-0 print:bottom-0 print:p-5">
			<div class="bg-brand flex w-full items-center justify-between rounded px-5 py-1 text-white">
				<h1 class="whitespace-nowrap text-sm tracking-tight">
					<span class="font-medium" v-text="company.name" />
					<span>, {{ company.address }}, {{ company.postal_code }} {{ company.served_city }},  {{ company.country }}.</span>
				</h1>
				<IconAircraftFlying v-if="!isBusinessGetter" class="h-9" />
			</div>
		</div>
	</div>
</template>

<style lang="postcss">
:root {
  --brand-captainjet: theme('colors.blue.500');
  --brand-title-captainjet: theme('colors.blue.200');
  --brand-neutral: theme('colors.black');
  --brand-title-neutral: theme('colors.black');

  --brand-color: var(--brand-captainjet);
  --brand-title-color: var(--brand-captainjet);
}

.bg-brand {
  background-color: var(--brand-color);
}

.text-brand {
  color: var(--brand-color);
}

.text-brand-title {
  color: var(--brand-title-color);
}
</style>
