<template>
	<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.795 1.975a.457.457 0 1 1 0 .916H7.78a.457.457 0 1 1 0-.916h1.016ZM10.141 1.975h.037a.457.457 0 1 1 0 .916h-.037a.457.457 0 1 1 0-.916Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.806 14.932a1.172 1.172 0 1 1 2.345 0 1.172 1.172 0 0 1-2.345 0Zm.916.003c0 .14.116.257.257.257.14 0 .256-.116.256-.257a.258.258 0 0 0-.256-.256.258.258 0 0 0-.257.256Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.296 0H5.66C4.525 0 3.6.925 3.6 2.06v13.883c0 1.135.925 2.06 2.06 2.06h6.636c1.136 0 2.06-.925 2.06-2.06V2.06A2.065 2.065 0 0 0 12.297 0Zm.837 15.94a.84.84 0 0 1-.84.839H5.66a.84.84 0 0 1-.84-.84V2.06c0-.464.376-.839.84-.839h6.636c.461 0 .84.375.84.84V15.94h-.003Z"
			fill="currentColor"
		/>
	</svg>
</template>
