<script setup lang="ts">
import { whenever } from '@vueuse/core'
import BaseDialog from '@/components/BaseDialog.vue'
import { resolveUserToken } from '~/ts/chat/token'
import { createNotification } from '~/ts/notifications/notifications'
import type { Notification } from '~/ts/notifications/types'
import { useProperty } from '@/composables/useProperty'
import { useChatDialog } from '~/ts/dialog'

const token = ref<string>()
const notification = ref<Notification>()
const timeout = ref<number>()
const isChatEnabled = useProperty('security.features.chat')
const { isOpen, close } = useChatDialog()

function notify(error: string) {
	dismiss()

	notification.value = createNotification({
		content: error,
		type: 'error',
	})

	timeout.value = setTimeout(dismiss, 8000) as any
}

function dismiss() {
	clearTimeout(timeout.value)
	notification.value = undefined
}

whenever(isOpen, async() => token.value = await resolveUserToken(), { immediate: true })
</script>

<template>
	<BaseDialog
		:show="isOpen && isChatEnabled"
		title="Chat with CaptainJet"
		size="3xl"
		class="bg-gray-50"
		@close="() => close()"
	>
		<template #before-wrapper>
			<transition
				enter-active-class="transform ease-out duration-300 transition"
				enter-from-class="-translate-y-2 opacity-0 sm:-translate-y-0 sm:-translate-x-2"
				enter-to-class="-translate-y-0 opacity-100 sm:-translate-x-0"
				leave-active-class="transition ease-in duration-500"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<NotificationItem
					v-if="notification"
					:notification="notification"
					class="absolute -top-18 left-0 z-notification mx-auto w-full rounded-xl"
					@dismiss="dismiss"
				/>
			</transition>
		</template>

		<template #default>
			<ChatWindow :token="token" :client="true" class="h-[500px]" @error="notify" />
		</template>
	</BaseDialog>
</template>
