<template>
	<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.357 3.2c2.31 0 4.41.823 6.02 2.263.21.137.35.274.49.48.28.274.28.686 0 .96a.686.686 0 0 1-.98 0 1.789 1.789 0 0 0-.34-.348c-.055-.045-.106-.089-.15-.132-1.4-1.166-3.15-1.852-5.04-1.852-1.89 0-3.64.686-5.04 1.852l-.49.48a.686.686 0 0 1-.98 0 .654.654 0 0 1 0-.96c.095-.14.224-.25.34-.348.054-.045.105-.089.15-.132 1.61-1.44 3.71-2.263 6.02-2.263Z"
			fill="currentColor"
		/>
		<path
			d="M11.297 7.794c-.84-.617-1.82-1.028-2.94-1.028-1.12 0-2.1.343-2.94 1.028-.21.137-.35.275-.56.48-.28.275-.21.686 0 .892.28.274.7.274.98 0 .14-.137.35-.343.56-.48.56-.412 1.26-.617 1.96-.617s1.4.205 1.96.617c.21.137.35.274.56.48.28.274.7.274.98 0 .28-.275.28-.686 0-.892-.14-.205-.35-.343-.56-.48ZM9.757 11.428c0 .755-.63 1.372-1.4 1.372-.77 0-1.4-.617-1.4-1.372v-.205c.07-.48.42-.892.91-1.097.14-.069.35-.069.49-.069s.35 0 .49.069c.49.137.84.548.91 1.097v.205Z"
			fill="currentColor"
		/>
	</svg>
</template>
