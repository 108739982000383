<template>
	<svg viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.426 9.814a1.098 1.098 0 0 0-.577-1.423 1.058 1.058 0 0 0-1.392.59 1.098 1.098 0 0 0 .576 1.422 1.058 1.058 0 0 0 1.393-.59ZM11.86 10.48a1.083 1.083 0 0 0 .956-1.19 1.072 1.072 0 0 0-1.165-.977 1.083 1.083 0 0 0-.956 1.191c.058.599.58 1.036 1.166.977ZM8.318 10.472c.58-.097.975-.656.881-1.25a1.069 1.069 0 0 0-1.223-.9c-.58.096-.975.656-.88 1.25.094.593.641.996 1.222.9ZM5.426 13.442a1.098 1.098 0 0 0-.577-1.423 1.058 1.058 0 0 0-1.392.59 1.097 1.097 0 0 0 .576 1.422 1.058 1.058 0 0 0 1.393-.59ZM11.86 14.108a1.083 1.083 0 0 0 .956-1.19 1.072 1.072 0 0 0-1.166-.977 1.083 1.083 0 0 0-.955 1.19c.058.6.58 1.037 1.165.977ZM8.318 14.1c.58-.097.975-.656.881-1.25a1.069 1.069 0 0 0-1.223-.9c-.58.096-.975.655-.88 1.25.094.593.641.996 1.222.9Z"
			fill="currentColor"
		/>
		<path
			d="M13.58 1.514h-.788V1.48a.806.806 0 0 0-.798-.815.806.806 0 0 0-.797.815v.034H5V1.48a.806.806 0 0 0-.797-.815.806.806 0 0 0-.798.815v.034h-.789c-1.23 0-2.232 1.023-2.232 2.28v11.204c0 1.259 1.001 2.282 2.232 2.282h10.964c1.23 0 2.232-1.023 2.232-2.282V3.796c0-1.258-1.001-2.281-2.232-2.281ZM2.618 3.143h.79v.373c0 .45.356.815.797.815.44 0 .797-.365.797-.815v-.373h6.196v.373c0 .45.357.815.798.815.44 0 .797-.365.797-.815v-.373h.79c.35 0 .637.292.637.651v1.58H1.979v-1.58c0-.36.287-.651.638-.651ZM13.581 15.65H2.617a.646.646 0 0 1-.637-.652V7.005h12.238V15a.645.645 0 0 1-.637.651Z"
			fill="currentColor"
		/>
	</svg>
</template>
