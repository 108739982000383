<script lang="ts" setup>
import { UseStepperReturn } from '@vueuse/core'

// todo: clean up

const $props = defineProps<{
	stepName: string
	step: any
	wizard: UseStepperReturn<any, any, any>
}>()

watch(() => $props.step, () => $props.wizard.goTo($props.stepName))

function allStepsBeforeAreValid(name: string): boolean {
	return !Array($props.stepName.length)
		.fill(null)
		.some(() => !$props.wizard.get(name)?.isValid())
}

function canGoTo(step: string) {
	if ($props.wizard.isPrevious(step)) {
		return true
	}

	if ($props.wizard.isCurrent(step)) {
		return false
	}

	if (allStepsBeforeAreValid(step)) {
		return true
	}

	if ($props.wizard.isNext(step) && $props.step.isValid()) {
		return true
	}

	return false
}

function setStep(step: string) {
	if (!canGoTo(step)) {
		return
	}

	$props.wizard.goTo(step)
}
</script>

<template>
	<div class="flex cursor-default items-center space-x-3 py-0.5">
		<div
			:class="[
				{ 'bg-turquoise-500': wizard.isAfter(stepName) },
				{ 'bg-turquoise-200': wizard.isCurrent(stepName) },
				'flex size-6 items-center justify-center rounded-full',
			]"
		>
			<IconTrue v-if="wizard.isAfter(stepName)" class="w-3 text-white" />
			<div
				v-else
				:class="[
					{ 'bg-blue-100': wizard.isBefore(stepName) },
					{ 'animate-pulse bg-turquoise-500': wizard.isCurrent(stepName) },
					'size-2 rounded-full',
				]"
			/>
		</div>
		<component
			:is="canGoTo(stepName) ? 'button' : 'div'"
			:class="[
				{ 'hover:text-blue-500': canGoTo(stepName) },
				{ 'text-blue-300': wizard.isAfter(stepName) || wizard.isBefore(stepName) },
				{ 'text-turquoise-500': wizard.isCurrent(stepName) },
				'flex-1 text-left text-sm font-medium transition',
			]"
			@click="setStep(stepName)"
		>
			{{ step.title }}
		</component>
	</div>
</template>
