<template>
	<svg viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5 1 1.143 5.5 5 10"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
