<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.853 4.74c-.262.06-.512.164-.738.31-.3.178-1.182 1.017-1.182 1.017-.028.01-2.727-.777-2.727-.777a.078.078 0 0 0-.11 0l-.191.19a.077.077 0 0 0-.007.105L7.26 6.676l.01.013a23.291 23.291 0 0 0-1.384 1.85.078.078 0 0 1-.095.031l-1.1-.286a.072.072 0 0 0-.074.018l-.208.207a.074.074 0 0 0 .018.117l1.101.52c.01.005.02.008.031.009l.015.006a.018.018 0 0 0 0 .012c0 .01.003.021.008.03l.522 1.102a.073.073 0 0 0 .115.018l.208-.208a.072.072 0 0 0 .018-.074l-.287-1.1-.02-.058c.162-.103.369-.244.633-.435.132-.095.922-.698.976-.74.102-.08.2-.16.293-.238l.012.01 1.04 2.265a.078.078 0 0 0 .103-.008l.191-.19a.08.08 0 0 0 0-.112c-.008-.03-.734-2.6-.725-2.628 0 0 .848-.89 1.027-1.198v-.005c.14-.22.24-.463.298-.718a.128.128 0 0 0-.134-.146Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M.167 7.41C.167 3.43 3.387.204 7.362.204c3.974 0 7.195 3.226 7.195 7.204a7.18 7.18 0 0 1-1.58 4.506l2.678 2.798a.64.64 0 1 1-.924.885L12.09 12.84a7.16 7.16 0 0 1-4.728 1.774c-3.975 0-7.195-3.227-7.195-7.205Zm7.195-5.925a5.92 5.92 0 0 0-5.915 5.924 5.92 5.92 0 0 0 5.915 5.925 5.92 5.92 0 0 0 5.915-5.925 5.92 5.92 0 0 0-5.915-5.924Z"
			fill="currentColor"
		/>
	</svg>
</template>
