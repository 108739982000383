<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { useProperty } from '@/composables/useProperty'

interface Platform {
	id: 'ios' | 'android'
	store: string
	label: string
	url?: string
}

const currentPlatform = useProperty('security.features.platform')
const platforms: Platform[] = [
	{ id: 'ios' as const, store: 'Apple', label: 'Download on iOS', url: useProperty('security.features.ios_download_link').value },
	{ id: 'android' as const, store: 'PlayStore', label: 'Download on Android', url: useProperty('security.features.android_download_link').value },
].filter(({ url }) => !!url)

const displaying = computed(() => {
	const current = platforms.find(({ id }) => id === currentPlatform.value)

	if (!current) {
		return platforms
	}

	return [current]
})
</script>

<template>
	<template v-for="platform in displaying" :key="platform.id">
		<!-- Desktop -->
		<div class="hidden lg:block">
			<Popover class="relative">
				<PopoverButton as="template">
					<BaseButton
						variant="secondary"
						size="lg"
						class="w-full space-x-2 whitespace-nowrap"
						:icon="platform.store"
						icon-class="h-6 w-6"
					>
						{{ platform.label }}
					</BaseButton>
				</PopoverButton>
				<transition
					enter-active-class="transition duration-200 ease-out"
					enter-from-class="scale-50 opacity-0"
					enter-to-class="scale-100 opacity-100"
					leave-active-class="transition duration-150 ease-in"
					leave-from-class="scale-100 opacity-100"
					leave-to-class="scale-50 opacity-0"
				>
					<PopoverPanel class="panel absolute -top-4 left-1/2 z-notification -translate-x-1/2 -translate-y-full rounded-2xl bg-white p-6 before:absolute before:-bottom-1.5 before:left-1/2 before:block before:size-3 before:-translate-x-1/2 before:rotate-45 before:bg-white">
						<component :is="platform.url ? 'a' : 'span'" :href="platform.url" target="_blank">
							<IconCaptainJetAppQRCode class="size-26 text-blue-500" />
						</component>
						<p class="-mx-2 mt-4 text-center text-xs font-medium text-blue-400">
							Scan with your phone to access the app
						</p>
					</PopoverPanel>
				</transition>
			</Popover>
		</div>
		<!-- Mobile -->
		<div class="lg:hidden">
			<BaseButton
				as="a"
				variant="secondary"
				size="lg"
				class="w-full space-x-2 whitespace-nowrap"
				:icon="platform.store"
				icon-class="h-6 w-6"
				:href="platform.url"
			>
				{{ platform.label }}
			</BaseButton>
		</div>
	</template>
</template>
