<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.535 19.186H6.479L3.24 5.928c-.168-.365-.61-1.499-.17-2.522.186-.433.604-1.01 1.538-1.31 1.039-.334 1.789-.13 2.235.1.959.494 1.254 1.453 1.284 1.561l1.702 6.248a.91.91 0 0 1 .684-.309h2.976a.912.912 0 0 1 0 1.824h-2.976a.912.912 0 0 1-.284-.045l.19.694 7.278.001c.806 0 1.563.381 2.023 1.019.46.637.568 1.453.287 2.182l-1.472 3.815Zm-10.57-1.808h9.265l1.017-2.637a.543.543 0 0 0-.068-.52.59.59 0 0 0-.482-.243l-8.727-.001-2.65-9.735c-.015-.04-.13-.337-.37-.456-.176-.087-.427-.079-.749.024-.317.102-.375.237-.397.287-.145.338.072.947.167 1.13l.05.095 2.944 12.056Z"
			fill="currentColor"
		/>
		<path
			d="M7.1 21.217c0-.477.386-.863.863-.863h8.132a.864.864 0 1 1 0 1.726H7.963a.864.864 0 0 1-.864-.863Z"
			fill="currentColor"
		/>
	</svg>
</template>
