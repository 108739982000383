<template>
	<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 5.31a2.437 2.437 0 0 1 2.434 2.434v1.197A2.437 2.437 0 0 1 9 11.375a2.437 2.437 0 0 1-2.435-2.434V7.744A2.437 2.437 0 0 1 9 5.31Zm-.97 3.631a.97.97 0 0 0 1.939 0V7.744a.97.97 0 0 0-1.938 0v1.197Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.967 17.08c-1.24.61-2.576.92-3.967.92a8.91 8.91 0 0 1-4.284-1.084 8.998 8.998 0 0 1-2.08-1.552A8.942 8.942 0 0 1 0 9c0-2.404.936-4.664 2.636-6.364A8.94 8.94 0 0 1 9 0a8.94 8.94 0 0 1 6.364 2.636A8.94 8.94 0 0 1 18 9a9 9 0 0 1-5.033 8.08ZM14.1 3.9A7.168 7.168 0 0 0 9 1.786 7.17 7.17 0 0 0 3.899 3.9a7.168 7.168 0 0 0-2.113 5.1c0 1.896.726 3.68 2.047 5.035a2.353 2.353 0 0 1 1.559-1.418 13.437 13.437 0 0 1 7.217 0c.727.202 1.296.74 1.558 1.417A7.213 7.213 0 0 0 16.214 9c0-1.927-.75-3.738-2.113-5.101Zm-1.242 11.197v-.221a.882.882 0 0 0-.643-.847 11.976 11.976 0 0 0-6.43 0 .882.882 0 0 0-.644.847v.221A7.179 7.179 0 0 0 9 16.213a7.14 7.14 0 0 0 3.86-1.117Z"
			fill="currentColor"
		/>
	</svg>
</template>
