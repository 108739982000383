<template>
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 23">
		<rect width="17" height="23" fill="#fff" rx="2" />
		<path fill="#8EA1B4" d="M5.93 11.908a.18.18 0 0 0-.252.031l-.11.143H2.432l-.111-.143a.18.18 0 1 0-.284.222l.165.212a.18.18 0 0 0 .142.07h3.31a.18.18 0 0 0 .142-.07l.165-.212a.18.18 0 0 0-.031-.253ZM2.504 11.79h2.992c.1 0 .18-.081.18-.18 0-.859-.644-1.568-1.473-1.67a.275.275 0 1 0-.406 0c-.829.102-1.473.811-1.473 1.67 0 .099.08.18.18.18ZM4 10.29a1.32 1.32 0 0 1 1.304 1.139H2.696A1.32 1.32 0 0 1 4 10.289Z" />
		<path fill="#8EA1B4" d="M3.547 10.54c-.01.004-.24.11-.437.374a.136.136 0 0 0 .108.216.134.134 0 0 0 .108-.054c.153-.204.328-.288.332-.29a.135.135 0 0 0-.111-.247ZM3.2 4.544c.048 0 .096.048.096.112V4.8a.11.11 0 0 1-.112.112h-.528c-.064 0-.096-.048-.096-.112v-.144c0-.064.048-.112.096-.112H3.2ZM5.312 4.544a.11.11 0 0 1 .112.112V4.8a.11.11 0 0 1-.112.112h-.544a.11.11 0 0 1-.112-.112v-.144a.11.11 0 0 1 .112-.112h.544Z" />
		<path fill="#8EA1B4" fill-rule="evenodd" d="M5.68 3.968a.448.448 0 0 1 .256.4h.032v1.36c0 .192-.16.352-.336.352h-.144a.342.342 0 0 1-.336-.352V5.52H2.8l.016.032v.192c0 .192-.16.352-.336.352h-.144A.342.342 0 0 1 2 5.744V5.52l.016-1.168c0-.176.096-.336.256-.4h.016l.224-.96c.064-.272.304-.48.56-.48h.176l.016-.16c.016-.112.112-.192.24-.192h.928c.128 0 .24.08.256.192l.016.176h.176c.256 0 .496.208.56.48l.224.96h.016Zm-2.608-1.12c-.096 0-.24.096-.272.24l-.192.848h2.736l-.208-.848c-.032-.144-.176-.24-.272-.24H3.072ZM2.4 4.256c-.048 0-.096.048-.096.112V5.2h3.328v-.832c0-.048-.032-.112-.096-.112H2.4Zm-.096 1.296v.224h.192v-.224h-.192Zm3.136-.016v.224h.192v-.224H5.44Z" clip-rule="evenodd" />
		<g fill="#8EA1B4" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
			<path d="M4.568 18.702h.585c.112 0 .168.066.177.171v.459c0 .066-.019.114-.046.143a.18.18 0 0 1-.13.048h-.586c-.111 0-.167-.058-.167-.163v-.496c0-.105.056-.162.167-.162Zm.474.515v-.21c0-.019-.019-.029-.028-.029h-.307c-.018 0-.027.01-.027.03v.21c0 .018.009.028.027.028h.307c.019 0 .028-.01.028-.029Z" />
			<path d="M5.674 18.711a2.226 2.226 0 0 0-.093-.553c-.047-.163-.102-.316-.158-.468a5.907 5.907 0 0 1-.078-.226 9.83 9.83 0 0 0-.052-.156 1.1 1.1 0 0 1-.056-.363c-.006-.089-.004-.178-.002-.267l.002-.134v-.286c0-.162-.084-.248-.25-.258h-.26c-.168.01-.242.086-.252.258v.028c0 .045 0 .09-.002.135-.002.093-.004.186.002.276a1.95 1.95 0 0 1-.148.878c-.038.086-.065.172-.093.258a1.751 1.751 0 0 1-.075.2c-.102.258-.139.525-.13.802v.84c0 .21.102.306.298.306h1.03c.215 0 .307-.096.307-.325v-.229a6.11 6.11 0 0 0 .01-.716Zm-.706-2.291c0 .02-.01.029-.028.029h-.158c-.019 0-.028-.01-.028-.029v-.143c0-.02.01-.029.028-.029h.158c.018 0 .028.01.028.029v.143Zm-.195.43V16.765c.001-.003.004-.005.009-.01l.01-.01s.008-.01.018-.01h.111c.019 0 .028.01.028.029v.133a1.593 1.593 0 0 0 .075.53l.009.034h-.335l.01-.076c.025-.183.048-.36.065-.535Zm.613 2.634v.134c0 .029 0 .048-.01.057-.009.01-.018.01-.037.01h-.957c-.018 0-.037 0-.037-.01-.01-.01-.01-.028-.01-.057v-.63c-.046-.305.02-.668.205-1.145.028-.067.047-.087.112-.087h.01c.13.01.268.01.399 0 .065 0 .092.02.12.087.168.42.242.82.214 1.23-.01.144-.01.278-.01.411ZM3.509 17.146c0-.163-.037-.2-.195-.2h-.79c-.158 0-.204.037-.204.21v1.154c0 .287.13.487.39.592.046.02.065.038.065.096V19.572c.002.053.004.102-.019.132 0 0-.009.01-.046.01h-.176a.145.145 0 0 0-.14.143c0 .076.065.143.14.143h.743c.074 0 .14-.067.14-.153v-.028c-.02-.067-.075-.115-.14-.115H3.1c-.018 0-.046-.01-.037-.076v-.63c0-.029.037-.077.065-.086a.609.609 0 0 0 .381-.592v-1.174Zm-.288 1.222c0 .143-.14.267-.297.267a.307.307 0 0 1-.307-.277c-.01-.325-.01-.65 0-.983v-.134h.613v.315c-.009.277-.009.544-.009.812Z" />
		</g>
		<path fill="#8EA1B4" d="M14.407 4.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h5.867a.54.54 0 0 1 0 1.079ZM14.407 11.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h5.867a.54.54 0 0 1 0 1.079ZM14.407 18.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h5.867a.54.54 0 0 1 0 1.079ZM11.407 6.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h2.867a.54.54 0 0 1 0 1.079ZM11.407 13.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h2.867a.54.54 0 0 1 0 1.079ZM11.407 20.079H8.539c-.3 0-.539-.24-.539-.54 0-.3.24-.539.54-.539h2.867a.54.54 0 0 1 0 1.079Z" />
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M2 16h4v4H2z" />
			</clipPath>
		</defs>
	</svg>
</template>
