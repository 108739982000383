<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import PassengerData = Domain.BookingCaptainJet.Data.PassengerData

defineEmits(['toggle', 'edit', 'remove'])

const props = defineProps<{
	passenger: PassengerData
	selected: boolean
}>()

const acronym = computed(() => {
	const split = props.passenger.full_name.split(' ')
	return split.length === 1
		? split[0].substring(0, 2)
		: split[0].charAt(0) + split[1].charAt(0)
})
</script>

<template>
	<div
		:class="[
			{ 'bg-turquoise-200': selected },
			{ 'bg-turquoise-50': !selected },
			'flex cursor-pointer items-center space-x-3.5 rounded-xl px-3.5 py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-turquoise-600',
		]"
		tabindex="0"
		@keypress.prevent.enter="$emit('toggle')"
		@keypress.prevent.space="$emit('toggle')"
		@click="$emit('toggle')"
	>
		<div
			:class="[
				{ 'bg-turquoise-500 text-white': selected },
				{ 'bg-turquoise-100 text-blue-300': !selected },
				'flex size-8 items-center justify-center rounded-full font-display text-sm font-medium uppercase',
			]"
		>
			<span v-text="acronym" />
		</div>
		<div
			:class="[
				{ 'text-turquoise-500': selected },
				{ 'text-blue-500': !selected },
				'flex flex-1 items-center justify-between pr-1.5 font-medium',
			]"
		>
			<span v-text="passenger.full_name" />

			<IconTrue v-if="selected" class="h-2.5" />

			<Menu v-if="!selected" v-slot="{ open }" as="div" class="relative">
				<MenuButton
					tabindex="-1"
					:class="[
						{ 'text-turquoise-400': open },
						{ 'text-blue-500 hover:text-blue-400': !open },
						'flex size-6 items-center justify-center rounded-full',
					]"
					@click.stop=""
				>
					<IconDotsHorizontal class="w-4" />
				</MenuButton>

				<transition
					v-show="open"
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="scale-95 opacity-0 origin-top"
					enter-to-class="scale-100 opacity-100"
					leave-active-class="transition duration-75 ease-in origin-top"
					leave-from-class="scale-100 opacity-100"
					leave-to-class="scale-95 opacity-0"
				>
					<MenuItems class="absolute -right-4 z-20 divide-y divide-turquoise-100 rounded-2xl bg-white shadow focus:outline-none">
						<!-- <MenuItem v-slot="{ active }">
							<button
								type="button"
								:class="[
									{ 'text-blue-300': active },
									{ 'text-blue-400': !active },
									'flex items-center space-x-2 px-4 py-4 font-medium text-base',
								]"
								@click.stop="$emit('edit')"
							>
								<IconEdit class="h-4 w-4" />
								<span>Edit</span>
							</button>
						</MenuItem> -->
						<MenuItem v-slot="{ active }">
							<button
								type="button"
								:class="[
									{ 'text-danger-400': active },
									{ 'text-danger-500': !active },
									'flex items-center space-x-2 p-4 text-base font-medium',
								]"
								@click.stop="$emit('remove')"
							>
								<IconTrash class="size-4" />
								<span>Remove</span>
							</button>
						</MenuItem>
					</MenuItems>
				</transition>
			</Menu>
		</div>
	</div>
</template>
