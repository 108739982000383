<template>
	<svg viewBox="0 0 84 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M47.512 37.007c19.701 0 35.672-.583 35.672-1.302 0-.72-15.971-1.302-35.672-1.302-19.701 0-35.672.583-35.672 1.302 0 .719 15.97 1.302 35.672 1.302Z"
			fill="#E4ECEF"
		/>
		<path
			d="M41.177 34.869c.834 0 1.51-.678 1.51-1.515 0-.837-.676-1.516-1.51-1.516-.833 0-1.509.679-1.509 1.516s.676 1.515 1.509 1.515Z"
			fill="#595959"
		/>
		<path
			d="M38.089 30.393a57.16 57.16 0 0 1-.553-.078s-7.963-2.191-11.308-3.486c0 0 3.897-1.8 12.087-1.978.078.15.73 1.444 1.247 2.02.546.62-1.473 3.522-1.473 3.522ZM52.833 30.678c.148-.15.248-.306.248-.462 0-.74-3.231-1.302-4.067-1.815-.836-.512-5.243-2.838-5.243-2.838s-2.65-.62-4.662-.712c.63-.007 1.283 0 1.956.014 10.06.228 20.241 4.554 20.241 4.554-2.89.647-5.767 1.038-8.473 1.26Z"
			fill="#E4ECEF"
		/>
		<path
			d="M80.172 29.724c-1.417.256-9.373.029-14.495-.15-.978-.035-1.85-.063-2.558-.092-1.127-.042-1.821-.07-1.821-.07s-10.18-4.326-20.241-4.554a60.42 60.42 0 0 0-1.956-.014c-.269 0-.531.007-.793.014-8.19.178-12.087 1.978-12.087 1.978-3.337-1.288-8.09-2.192-8.09-2.192l-1.538-2.454h-5.654l-1.02-2.17L4.775 9.027s1.155.065 2.7.057c1.247 0 2.742-.042 4.095-.192l.687-.085h.007c.305-.043.326-.477.028-.555L6.596 6.751h6.894l7.956 9.284 9.777 1.75 1.134.99s25.916 1.295 30.762 1.522c.97.043 1.82.107 2.558.178 2.947.285 4.116.712 4.116.712l1.495.81 3.882 2.1.149.085s4.166 2.056 6.213 3.422c2.048 1.366.758 1.743-1.36 2.12Z"
			fill="#CAD3DC"
		/>
		<path
			d="m36.826 23.03.645.028a.384.384 0 0 0 .39-.363l.042-1.074a.38.38 0 0 0-.361-.391l-.645-.029a.379.379 0 0 0-.39.363l-.042 1.074a.38.38 0 0 0 .361.392ZM68.05 23.94s4.733.47 7.12.15l-3.882-2.107-3.847.356.61 1.601ZM40.738 23.186l-.645-.029a.38.38 0 0 1-.361-.391l.043-1.074a.379.379 0 0 1 .39-.363l.644.028a.38.38 0 0 1 .361.392l-.042 1.074a.379.379 0 0 1-.39.363ZM43.281 23.286l.645.028a.379.379 0 0 0 .39-.362l.042-1.075a.38.38 0 0 0-.361-.391l-.645-.029a.379.379 0 0 0-.39.363l-.042 1.075a.38.38 0 0 0 .361.391ZM51.308 23.606l-.645-.029a.38.38 0 0 1-.361-.39l.042-1.075a.379.379 0 0 1 .39-.363l.645.028a.38.38 0 0 1 .361.392l-.043 1.074a.379.379 0 0 1-.39.363ZM46.626 23.42l.645.03a.383.383 0 0 0 .39-.363l.042-1.075a.38.38 0 0 0-.361-.391l-.645-.029a.379.379 0 0 0-.39.363l-.042 1.075a.38.38 0 0 0 .361.39ZM54.893 23.748l-.645-.029a.38.38 0 0 1-.36-.39l.042-1.075a.379.379 0 0 1 .39-.363l.644.029a.38.38 0 0 1 .361.39l-.042 1.075a.379.379 0 0 1-.39.363ZM57.884 23.869l.645.028a.379.379 0 0 0 .39-.362l.042-1.075a.38.38 0 0 0-.361-.391l-.645-.029a.379.379 0 0 0-.39.363l-.042 1.075a.38.38 0 0 0 .361.39Z"
			fill="#23466B"
		/>
		<path
			d="M41.78 32.109a1.371 1.371 0 0 0-.538-.107c-.794 0-1.431.647-1.431 1.437 0 .797.644 1.437 1.43 1.437a1.44 1.44 0 0 0 1.432-1.437 1.428 1.428 0 0 0-.893-1.33Zm-.503 2.205a.803.803 0 0 1-.765-.555.809.809 0 0 1 .765-1.067c.092 0 .184.014.27.05a.809.809 0 0 1 .538.768.799.799 0 0 1-.808.804Z"
			fill="#1A3550"
		/>
		<path
			d="M42.09 33.503a.809.809 0 0 1-.808.81.802.802 0 0 1-.765-.554.809.809 0 0 1 .765-1.067c.092 0 .184.014.27.05a.809.809 0 0 1 .538.76Z"
			fill="#CFD8E0"
		/>
		<path
			d="M44.28 30.905v.868c0 .847-.686 1.537-1.53 1.537a1.38 1.38 0 0 0-.85-1.273v-1.288l2.38.156Z"
			fill="#7289A1"
		/>
		<path
			d="M53.073 30.209c0 .156-.092.313-.248.462-.545.54-1.806.96-1.806.96-4.5 1.38-12.93-1.244-12.93-1.244s2.019-2.903 1.473-3.515c-.51-.576-1.879-2.645-1.956-2.802.262-.007.524-.007.793-.014 2.012.085 4.662.711 4.662.711s5.117 3.108 5.953 3.62c.828.52 4.06 1.082 4.06 1.822Z"
			fill="#8EA1B4"
		/>
		<path
			opacity=".3"
			d="M18.045 21.486s3.776 3.03 8.445 2.974c4.669-.057 4.768-2.583 4.768-2.583l-13.213-.391Z"
			fill="#7289A1"
		/>
		<path
			d="M31.251 19.48v2.404a.487.487 0 0 1-.212.406c-.156.1-.397.249-.723.398-.857.406-2.317.861-4.378.761-3.132-.156-7.007-1.608-7.893-1.956a.5.5 0 0 1-.269-.256c-.24-.555-.517-1.181-.716-1.622a.486.486 0 0 1 .369-.676 66.116 66.116 0 0 1 8.289-.64c2.466-.036 3.833.192 4.598.44.383.121.61.257.744.356.12.086.191.228.191.384Z"
			fill="#E4ECEF"
		/>
		<path
			d="M31.252 19.48v2.405a.488.488 0 0 1-.213.405c-.156.1-.397.25-.723.399v-3.95c.383.122.61.257.744.357.12.085.192.227.192.384Z"
			fill="#4F6B89"
		/>
		<path
			opacity=".3"
			d="M12.257 8.814 9.919 20.02 4.775 9.028s1.155.064 2.7.057c1.247 0 2.742-.043 4.095-.193.233-.02.46-.05.687-.078Z"
			fill="#7289A1"
		/>
		<path
			d="M11.57 8.9c-1.354.15-2.848.192-4.095.192l-4.967-2.34-2.444-.883c-.1-.035-.078-.185.028-.185h2.182L11.57 8.9Z"
			fill="#CFD8E0"
		/>
		<path
			d="M7.474 9.092c-1.544 0-2.7-.057-2.7-.057s-1.281-.157-2.649-1.146c-1.367-.989.376-1.138.376-1.138l4.973 2.34Z"
			fill="#8EA1B4"
		/>
		<path
			d="M12.25 8.814c-.22.036-.454.064-.688.086l-9.28-3.216c1.664 0 4.321 1.067 4.321 1.067l5.69 1.509c.29.078.268.512-.043.554Z"
			fill="#4F6B89"
		/>
		<path d="M76.332 32.108h-.318v-2.27h1.077l-.758 2.27Z" fill="#7289A1" />
		<path
			d="M77.689 33.658a1.512 1.512 0 0 0-1.478-1.82c-.834 0-1.51.679-1.51 1.516 0 .796.611 1.448 1.388 1.51a1.44 1.44 0 0 0 1.6-1.206Z"
			fill="#1A3550"
		/>
		<path
			d="M77.123 33.503a.809.809 0 0 1-.808.81.802.802 0 0 1-.765-.554.809.809 0 0 1 .765-1.067c.093 0 .185.014.27.05a.809.809 0 0 1 .538.76Z"
			fill="#CFD8E0"
		/>
		<circle cx="46.974" cy="7.526" r="5.526" fill="#61A7B7" />
		<path d="m45.133 7.814 1.326 1.555 2.358-2.764" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" />
		<path
			d="M75.243 12.648c-2.042.156-5.19.245-12.4.256-.77 0-5.537.201-5.838-.1-.09-.09 1.027-.09 1.072-.257.234-.87 1.886-2.5 2.779-1.92 1.417.927 1.127-1.495 3.962-1.049.692.112 1.686-1.44 2.68-1.54.245-.034.512-.034.792-.011 3.091.29 3.136 1.964 3.873 2.712 1.361 1.384 4.074 1.83 3.08 1.909Z"
			fill="#F2F5F7"
		/>
		<path
			d="M66.749 11.23c-.703-1.362-4.532-1.82-3.762-.68.77 1.127-1.004.736-.96 1.026.045.301.458.927-1.138.201-1.596-.725-2.813.76-2.813.76.235-.872 1.886-2.501 2.78-1.92 1.417.926 1.127-1.496 3.962-1.05.692.112 1.685-1.44 2.679-1.54.245-.034.513-.034.792-.011-2.433.625-.837 4.576-1.54 3.214Z"
			fill="#E4ECEF"
		/>
		<path
			d="M33.364 3.002c-2.911.658-10.034 0-10.034 0s-.845.078-2.066 0c-.782-.063.313-.031 1.471-.391a4.31 4.31 0 0 0 .705-.266c.376-.173.736-.407.97-.705.173-.219.345-.391.533-.516l.015-.016c.047-.031.094-.063.126-.078a.215.215 0 0 0 .062-.047h.016c.015-.016.031-.016.047-.032.782-.453 1.55-.36 2.129-.641.078-.032.14-.079.203-.126a.119.119 0 0 1 .063-.03c.798-.486 2.755.25 3.193 1.251.438 1.018 5.479.924 2.567 1.597Z"
			fill="#F2F5F7"
		/>
		<path
			d="M27.415.325c-.579.282-1.362.204-2.144.658h.015l.016-.016c-.016.016-.047.032-.078.047-.016.016-.032.032-.063.047-.031.031-.078.047-.125.078 0 0-.016 0-.016.016-.203.188-.156.094-.532.517-.235.297-.595.532-.97.704.203-.172 1.58.657 1.534-1.174.11-.673 3.287.564 2.363-.877Z"
			fill="#E4ECEF"
		/>
	</svg>
</template>
