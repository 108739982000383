<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 26">
		<path d="M18.037 7.717c0 .618-.51 1.12-1.138 1.12a1.128 1.128 0 0 1-1.137-1.12c0-.618.51-1.12 1.137-1.12.628 0 1.137.502 1.137 1.12Z" fill="currentColor" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9 4.691a3.077 3.077 0 0 0-2.155.88 2.982 2.982 0 0 0-.894 2.121c0 1.07 1.135 2.63 1.927 3.602a.67.67 0 0 0-.239.745.67.67 0 0 0 .244.332.687.687 0 0 0 .395.13h.036c.076 0 .153-.004.23-.008a5.32 5.32 0 0 1 .197-.008.69.69 0 0 0 .553-.281.525.525 0 0 0 .125-.098c.447-.479 2.629-2.923 2.629-4.414a2.982 2.982 0 0 0-.895-2.12 3.077 3.077 0 0 0-2.154-.88Zm0 6.183c-.908-1.057-1.914-2.507-1.914-3.182 0-.5.202-.978.56-1.332A1.929 1.929 0 0 1 16.9 5.81c.508 0 .994.198 1.353.551.36.354.56.833.56 1.332 0 .673-1.012 2.123-1.913 3.182Z" fill="currentColor" />
		<path d="M13.77 11.545a5.43 5.43 0 0 0-.454.14.68.68 0 0 0-.365.29.661.661 0 0 0 .141.855.716.716 0 0 0 .666.127c.132-.045.266-.085.402-.123a.687.687 0 0 0 .433-.311.664.664 0 0 0-.267-.934.689.689 0 0 0-.534-.044h-.023ZM11.112 12.7c-.137.085-.273.172-.404.261a.66.66 0 0 0-.018 1.092.688.688 0 0 0 .79.012 6.31 6.31 0 0 1 .353-.223.676.676 0 0 0 .303-.418.662.662 0 0 0-.268-.697.685.685 0 0 0-.756-.023V12.7Z" fill="currentColor" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="m21.22 16.168 3.598 4.281a.778.778 0 0 1 .085.866.793.793 0 0 1-.702.414.804.804 0 0 1-.613-.28l-3.631-4.33a8.533 8.533 0 0 1-5.489 1.206 8.474 8.474 0 0 1-5.036-2.463.69.69 0 0 1-.815-.063.667.667 0 0 1-.061-.945l.014-.013a8.24 8.24 0 0 1-1.532-4.452A8.222 8.222 0 0 1 8.199 5.83a8.403 8.403 0 0 1 3.485-3.212 8.57 8.57 0 0 1 9.109.955 8.323 8.323 0 0 1 2.719 3.864 8.2 8.2 0 0 1 .16 4.697 8.3 8.3 0 0 1-2.451 4.034ZM9.777 6.311a6.683 6.683 0 0 0-1.157 3.755 6.712 6.712 0 0 0 2.013 4.776 6.94 6.94 0 0 0 8.665.842 6.781 6.781 0 0 0 2.527-3.033 6.66 6.66 0 0 0 .39-3.904 6.725 6.725 0 0 0-1.878-3.46 6.898 6.898 0 0 0-3.514-1.849 6.964 6.964 0 0 0-3.965.385 6.842 6.842 0 0 0-3.08 2.488Z" fill="currentColor" />
		<path d="M5.185 20.177c0 .618-.509 1.12-1.137 1.12a1.128 1.128 0 0 1-1.137-1.12c0-.619.51-1.12 1.137-1.12.628 0 1.137.501 1.137 1.12Z" fill="currentColor" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M1.894 18.03a3.075 3.075 0 0 1 2.154-.879c.808 0 1.584.316 2.156.878a2.98 2.98 0 0 1 .895 2.12c0 1.491-2.193 3.936-2.63 4.412a.572.572 0 0 1-.42.18.578.578 0 0 1-.42-.18C3.19 24.085 1 21.64 1 20.15a2.98 2.98 0 0 1 .894-2.12Zm.24 2.12c0 .673 1.006 2.123 1.914 3.182.901-1.06 1.916-2.51 1.914-3.182 0-.5-.202-.979-.56-1.332a1.929 1.929 0 0 0-1.354-.552c-.507 0-.994.199-1.353.552a1.87 1.87 0 0 0-.56 1.332Z" fill="currentColor" />
		<path d="M7.832 16.435a.687.687 0 0 1 .56.506.66.66 0 0 1-.081.51 5.945 5.945 0 0 0-.227.366.676.676 0 0 1-.593.337.678.678 0 0 1-.656-.493.66.66 0 0 1 .066-.51c.077-.132.157-.266.243-.402a.676.676 0 0 1 .422-.303.69.69 0 0 1 .266-.011Z" fill="currentColor" />
	</svg>
</template>
