<script setup lang="ts">
const $props = defineProps<{
	modelValue?: File
	label?: string
	accept?: string
	error?: string
	currentPreviewUrl?: string
}>()

const $emit = defineEmits<{
	(e: 'update:modelValue', value?: File): void
	(e: 'remove'): void
}>()

const fileInput = ref<HTMLInputElement>()
const preview = ref<string>()
const id = computed(() => `input-file-${Math.random().toString(36).substring(2)}`)
const dragging = ref(false)

function change(event: Event) {
	update((event.target as HTMLInputElement).files)
}

function onDrop(event: DragEvent) {
	dragging.value = false
	update(event.dataTransfer?.files)
}

function update(files?: FileList | null) {
	if (files) {
		$emit('update:modelValue', files[0])
		preview.value = URL.createObjectURL(files[0])
	}
}

function browse() {
	fileInput.value?.click()
}

function remove() {
	$emit('update:modelValue', undefined)
	$emit('remove')
	preview.value = undefined
}

function filesize(bytes?: number) {
	const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB']

	if (!bytes) {
		return '0 byte'
	}

	const i = Math.floor(Math.log(bytes) / Math.log(1024))
	return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
}

const helpText = computed(() => {
	const fileSizeInBytes = $props.modelValue?.size

	if (fileSizeInBytes) {
		return `${$props.label} (${filesize(fileSizeInBytes)})`
	}

	return 'JPG, PNG and WEBP'
})

function onDragOver() {
	dragging.value = true
}

function onDragExit() {
	dragging.value = false
}
</script>

<template>
	<div
		v-auto-animate
		class="flex flex-col gap-y-2 rounded-xl border border-dashed"
		:class="{
			'border-transparent': !dragging,
			'border-blue-300': dragging
		}"
		@drop.stop.prevent="onDrop"
		@dragover.stop.prevent="onDragOver"
		@dragexit.stop.prevent="onDragExit"
	>
		<div class="flex gap-x-4">
			<!-- Hidden actual input -->
			<input
				:id="id"
				ref="fileInput"
				type="file"
				:accept="accept"
				class="hidden"
				@change="change"
			/>

			<!-- Image preview -->
			<div class="aspect-square size-18 cursor-pointer overflow-hidden rounded-xl bg-gray-100" @click="browse">
				<img
					v-if="preview ?? currentPreviewUrl"
					:src="preview ?? currentPreviewUrl"
					alt="Preview"
					class="aspect-square size-full object-contain"
				/>
				<div v-else class="flex size-full items-center justify-center">
					<IconAircraftFlying class="size-8 text-blue-200" />
				</div>
			</div>

			<!-- Controls -->
			<div class="flex flex-col justify-center gap-y-2">
				<!-- Buttons -->
				<div class="flex gap-x-4">
					<BaseButton variant="secondary" size="xs" @click.prevent="browse">
						Choose
					</BaseButton>

					<button @click.prevent="remove">
						<IconTrash class="size-5 text-blue-300" />
					</button>
				</div>
				<!-- Help text -->
				<p class="text-sm font-medium text-blue-300 opacity-80" v-text="helpText" />
			</div>
		</div>
		<!-- Errors -->
		<div v-if="error" class="flex items-center space-x-2 text-sm font-medium text-danger-500">
			<IconExclamationCircle class="size-3.5 shrink-0" />
			<span>{{ error }}</span>
		</div>
	</div>
</template>
