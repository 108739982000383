import { Ref } from 'vue'
import { Message } from './types'

interface MessageCache {
	messages: Message[]
	hasPrevious: boolean
	scrollY?: number
}

export const cache = ref(new Map<string, MessageCache>())

export function useMessageCache(token: Ref<string | undefined>) {
	const messages = ref<Message[]>(cache.value.get(token.value!)?.messages ?? [])
	const hasPrevious = ref(cache.value.get(token.value!)?.hasPrevious ?? true)
	const scrollY = ref(cache.value.get(token.value!)?.scrollY)

	// When the token changes, sets the messages to what the cache contains.
	watch(token, () => {
		messages.value = cache.value.get(token.value!)?.messages ?? []
		hasPrevious.value = cache.value.get(token.value!)?.hasPrevious ?? true
		scrollY.value = cache.value.get(token.value!)?.scrollY
	})

	// When messages are mutated, synchronize them with the cache.
	watch([messages, hasPrevious, scrollY], () => {
		if (token.value) {
			cache.value.set(token.value, {
				messages: messages.value ?? [],
				hasPrevious: hasPrevious.value ?? true,
				scrollY: scrollY.value,
			})
		}
	})

	return {
		messages,
		hasPrevious,
		scrollY,
	}
}
