<template>
	<svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.382 3.524C16.366 1.592 13.762.5 10.99.5 8.218.5 5.614 1.592 3.598 3.524 1.666 5.54.574 8.144.574 10.916c0 2.772 1.092 5.376 3.024 7.392 1.932 1.932 4.62 3.024 7.392 3.024 2.772 0 5.376-1.092 7.392-3.024 1.932-2.016 3.024-4.62 3.024-7.392 0-2.772-1.092-5.376-3.024-7.392ZM10.99 19.736a8.819 8.819 0 0 1-8.82-8.82 8.819 8.819 0 0 1 8.82-8.82 8.819 8.819 0 0 1 8.82 8.82 8.819 8.819 0 0 1-8.82 8.82Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.686 7.466a.84.84 0 0 1 0 1.188l-5.302 5.302a.84.84 0 0 1-1.188 0l-2.495-2.495a.84.84 0 0 1 1.188-1.188l1.901 1.901 4.708-4.708a.84.84 0 0 1 1.188 0Z"
			fill="currentColor"
		/>
	</svg>
</template>
