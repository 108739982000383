<script setup lang="ts">
const $props = defineProps<{
	confirmedFlights?: number
}>()

const confirmedFlightsFlip = computed(() => {
	return $props.confirmedFlights?.toString().split('').map((i) => ({
		from: 9,
		to: i,
	}))
})
</script>

<template>
	<div class="relative z-[2] bg-blue-600 pb-16 text-center lg:pb-20 lg:text-left">
		<transition
			appear
			mode="out-in"
			enter-active-class="delay-200 duration-500 transition-all"
			enter-from-class="opacity-0 -translate-x-2"
			enter-to-class="opacity-100 translate-x-0"
			leave-active-class="duration-500 transition-all"
			leave-from-class="opacity-100 translate-x-0"
			leave-to-class="opacity-0 -translate-x-2"
		>
			<marketing-container>
				<p class="pt-10 text-center font-medium uppercase tracking-widest text-warning-500 lg:pt-3">
					Friendly • Reliable • Digital
				</p>
				<p class="mt-5 text-center font-inter text-3xl font-bold leading-tight tracking-wide text-white lg:text-[40px] lg:font-medium">
					Join a new generation of private jet travelers<br />
					<span class="font-extrabold tracking-widest">using CaptainJet worldwide.</span>
				</p>
				<div class="mt-14 grid justify-items-center gap-10 text-white sm:grid-cols-2 sm:gap-14 lg:grid-cols-4 lg:gap-18">
					<div class="max-w-xs">
						<div class="split-flaps">
							<marketing-split-flap
								v-for="(data, i) in confirmedFlightsFlip"
								:key="i"
								:initial-char="String(data.from)"
								:target-char="String(data.to)"
							/>
						</div>
						<p class="mt-4 font-inter text-lg font-bold">
							Confirmed flights
						</p>
						<p class="mt-4 font-medium text-blue-200">
							Our clients keep coming back thanks to over a decade of experience and trustworthy service.
						</p>
					</div>
					<div class="max-w-xs">
						<div class="split-flaps">
							<marketing-split-flap
								v-for="(data, i) in Array(
									{ from: 6, to: 3 },
									{ from: 0, to: 8 },
									{ from: 9, to: 0 },
									{ from: 8, to: 0 }
								)"
								:key="i"
								:initial-char="String(data.from)"
								:target-char="String(data.to)"
							/>
						</div>
						<p class="mt-4 font-inter text-lg font-bold">
							Aircraft available
						</p>
						<p class="mt-4 font-medium text-blue-200">
							Global access to luxurious small, medium, and long-range private jets through our network of operators.
						</p>
					</div>
					<div class="max-w-xs">
						<div class="split-flaps">
							<marketing-split-flap
								v-for="(data, i) in Array(
									{ from: 0, to: 1 },
									{ from: 8, to: 4 },
									{ from: 9, to: 0 },
									{ from: 7, to: 0 }
								)"
								:key="i"
								:initial-char="String(data.from)"
								:target-char="String(data.to)"
							/>
						</div>
						<p class="mt-4 font-inter text-lg font-bold">
							Trusted operators
						</p>
						<p class="mt-4 font-medium text-blue-200">
							Our long-term partnerships with leading operators worldwide ensure only the best deals and smoothest service.
						</p>
					</div>
					<div class="max-w-xs">
						<div class="split-flaps">
							<marketing-split-flap
								v-for="(data, i) in Array(
									{ from: '8', to: '1' },
									{ from: '9', to: '0' },
									{ from: ' ', to: ' ' },
									{ from: 's', to: 'm' },
									{ from: 'o', to: 'i' },
									{ from: 'p', to: 'n' },
								)"
								:key="i"
								:initial-char="String(data.from)"
								:target-char="String(data.to)"
							/>
						</div>
						<p class="mt-4 font-inter text-lg font-bold">
							Average time for a firm quote
						</p>
						<p class="mt-4 font-medium text-blue-200">
							We value your time and provide rapid offers directly from the operators minimising the waiting period.
						</p>
					</div>
				</div>
			</marketing-container>
		</transition>
	</div>
</template>

<style scoped lang="postcss">
.split-flaps {
  @apply flex gap-0.5 justify-center lg:justify-start font-inter text-3xl
}
</style>
