<script setup lang="ts">
import { router } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'
import axios from 'axios'
import { route } from '@/composables/route'
import { useEcho } from '~/ts/chat/echo'
import { useProperty } from '@/composables/useProperty'
import { openDialog } from '~/ts/dialog'
import { pushNotification, useNotifications } from '~/ts/notifications/notifications'
import UserData = SupportCaptainJet.Data.UserData
import BookingData = Domain.BookingCaptainJet.Data.BookingData
import QuotationData = Domain.BookingCaptainJet.Data.QuotationData
import BillingAddressData = Domain.BookingCaptainJet.Data.BillingAddressData

const { listen } = useEcho()
const user = useProperty<UserData>('security.user')

const $props = defineProps<{
	booking: BookingData
	quotations: Paginator<QuotationData>
	billingAddresses: Paginator<BillingAddressData>
}>()

useHead({
	title: 'Offers',
})

listen(`user.${user.value.id}.quotations`, {
	QuotationUpdated: () => router.reload({ only: ['quotations'] }),
})

function selectMoreAircraft() {
	router.get(route('captainjet.web.booking.estimates.show', { booking: $props.booking.id }))
}

function reviewContract(quotation: QuotationData) {
	router.get(route('captainjet.web.quotations.process', { quotation }))
}

function askForContract(quotation: QuotationData) {
	openDialog('select-billing-address', {
		newIfEmpty: true,
		quotation,
		onSelect: () => {
			router.post(route('captainjet.web.quotations.accept', { quotation: quotation.id }), {}, {
				onError: ({ error }) => pushNotification({
					type: 'error',
					content: error,
				}),
			})
		},
	})
}

function cancelBooking() {
	router.delete(route('captainjet.web.booking.cancel', { booking: $props.booking.id }))
}

const createSimilarUrl = computed(() => route('captainjet.web.index', { from: $props.booking.id }))

/*
|--------------------------------------------------------------------------
| Business getter
|--------------------------------------------------------------------------
*/

const selection = ref<Set<number>>(new Set())
const downloadingPdf = ref(false)
const isBusinessGetter = computed(() => user.value.is_business_getter)

const OverridenNameDialog = createTemplatePromise({ singleton: true })
const clientName = ref<string>()

function toggleAircraft(id: number) {
	if (!isBusinessGetter.value) {
		return
	}

	if (isAircraftSelected(id)) {
		selection.value.delete(id)
	} else {
		selection.value.add(id)
	}
}

function isAircraftSelected(id: number) {
	if (!isBusinessGetter.value) {
		return false
	}

	return selection.value.has(id)
}

function canExportAsPdf() {
	if (!isBusinessGetter.value) {
		return false
	}

	return selection.value.size > 0
}

async function exportAsPdf(customSelection?: number[]) {
	const quotations = Array.from(customSelection ?? selection.value ?? [])

	if (!isBusinessGetter.value || quotations.length === 0) {
		return
	}

	if (!await OverridenNameDialog.start()) {
		return
	}

	downloadingPdf.value = true

	const { data, status, headers } = await axios.post(route('captainjet.web.booking.quotations.download', { booking: $props.booking }), {
		quotations,
		clientName: clientName.value,
	}, {
		validateStatus: () => true,
		responseType: 'blob',
	})

	downloadingPdf.value = false

	if (status !== 200) {
		return useNotifications().push({
			type: 'error',
			content: status === 422
				? JSON.parse(await data.text()).message
				: 'Could not generate the PDF. Please try again later.',
		})
	}

	const url = URL.createObjectURL(new Blob([data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', headers['content-disposition'].split('filename=')?.[1]?.replace(/"/g, '') ?? 'quotations.pdf')
	link.target = '_blank'
	link.click()
}
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<page-container v-auto-animate>
		<!-- Back -->
		<InertiaLink
			type="button"
			class="flex items-center space-x-2 text-sm text-blue-200 hover:text-blue-300"
			:href="route('captainjet.web.booking.index')"
		>
			<icon-chevron-left class="h-2.5" />
			<span>Trips</span>
		</InertiaLink>

		<div class="flex gap-x-4">
			<!-- Header and offers -->
			<section class="w-full max-w-full grow">
				<!-- Actions -->
				<header>
					<div class="flex flex-col items-center justify-between sm:flex-row">
						<!-- Navigation-->
						<div class="flex w-full justify-between sm:w-auto sm:flex-col sm:justify-start">
							<!-- Title -->
							<div class="flex items-center space-x-4">
								<h2 class="font-display text-xl font-bold text-blue-500">
									Offers
								</h2>
								<a
									v-if="user.is_admin"
									:href="route('filament.captainjet.resources.bookings.view', { record: booking.id })"
									class="text-turquoise-300 transition hover:text-turquoise-500"
								>
									<icon-cog
										class="size-6"
									/>
								</a>
							</div>
						</div>
						<!-- Select more aircraft -->
						<button
							class="mt-4 flex w-full items-center justify-center gap-x-6 rounded-2xl bg-white p-5 text-turquoise-500 transition hover:text-turquoise-600 hover:shadow focus-visible:ring-2 focus-visible:ring-turquoise-500 focus-visible:ring-offset-2 sm:mt-0 sm:w-auto"
							@click="selectMoreAircraft"
						>
							<icon-aircraft-check class="h-10" />
							<span class="text-right text-lg font-medium leading-tight md:text-left">Select more aircraft</span>
							<icon-chevron-right class="size-4" />
						</button>
					</div>
				</header>
				<!-- Trip details -->
				<section class="my-6 flex flex-col rounded-2xl bg-white">
					<Disclosure v-slot="{ open }">
						<DisclosureButton
							as="h1"
							class="flex items-center justify-between p-4 font-display font-semibold uppercase tracking-widest text-blue-200"
						>
							<div class="flex items-center gap-x-4">
								<span>Trip</span>
								<!-- Menu -->
								<Menu v-slot="{ open: menuOpen }" as="div" class="relative">
									<Float
										portal
										placement="bottom-start"
										:show="menuOpen"
										:shift="10"
										enter="transition duration-100 ease-out"
										enter-from="scale-95 opacity-0 origin-top"
										enter-to="scale-100 opacity-100"
										leave="transition duration-75 ease-in origin-top"
										leave-from="scale-100 opacity-100"
										leave-to="scale-95 opacity-0"
										:offset="10"
									>
										<MenuButton
											:class="[
												{
													'bg-turquoise-300 focus-visible:ring-0': menuOpen,
													'hover:border-turquoise-300': !menuOpen,
												},
												'flex size-9 items-center justify-center rounded-full border border-turquoise-200 bg-white text-turquoise-500 focus-visible:ring-2 focus-visible:ring-turquoise-600',
											]"
											@click.stop
										>
											<icon-dots-horizontal class="w-4" />
										</MenuButton>
										<MenuItems class="z-20 flex origin-top-left flex-col divide-y divide-turquoise-50 overflow-hidden rounded-2xl bg-white shadow focus:outline-none">
											<MenuItem v-slot="{ active }">
												<InertiaLink
													:class="[
														{ 'bg-turquoise-50 text-blue-500': active },
														{ 'text-blue-400': !active },
														'flex items-center space-x-2 px-4 py-3 font-medium',
													]"
													:href="createSimilarUrl"
												>
													<icon-calendar class="w-4" />
													<span>Create a similar booking</span>
												</InertiaLink>
											</MenuItem>
											<MenuItem v-slot="{ active }">
												<button
													:class="[
														{ 'bg-danger-100/50 text-danger-600': active },
														{ 'text-danger-500': !active },
														'flex items-center space-x-2 px-4 py-3 font-medium',
													]"
													@click="cancelBooking"
												>
													<icon-x class="w-4" />
													<span>Cancel this booking</span>
												</button>
											</MenuItem>
										</MenuItems>
									</Float>
								</Menu>
							</div>
							<IconChevronDown
								:class="open ? 'rotate-180 transform' : ''"
								class="size-3 text-blue-300 transition"
							/>
						</DisclosureButton>
						<transition
							enter-active-class="transition duration-100 ease-out"
							enter-from-class="transform scale-95 opacity-0"
							enter-to-class="transform scale-100 opacity-100"
							leave-active-class="transition duration-75 ease-out"
							leave-from-class="transform scale-100 opacity-100"
							leave-to-class="transform scale-95 opacity-0"
						>
							<DisclosurePanel class="border-t border-blue-50 p-4 text-sm text-gray-500">
								<ul class="flex items-center gap-4 overflow-x-auto pb-4">
									<li
										v-for="(leg, index) in booking.legs"
										:key="index"
										class="min-w-[300px]"
									>
										<booking-routing-summary-card
											:name="booking.legs!.length > 1 ? `Flight n°${index + 1}` : 'Flight'"
											:departs-at="leg.departs_at_local_time"
											:departure-airport="leg.departure_airport!"
											:arrival-airport="leg.arrival_airport!"
											:passengers="leg.passenger_count!"
										/>
									</li>
								</ul>
							</DisclosurePanel>
						</transition>
					</Disclosure>
				</section>
				<!-- Business getter features -->
				<section v-if="isBusinessGetter" class="my-6 flex flex-col rounded-2xl bg-white">
					<Disclosure v-slot="{ open }" default-open>
						<DisclosureButton
							as="h1"
							class="flex items-center justify-between p-4 font-display font-semibold uppercase tracking-widest text-blue-200"
						>
							<span>Business getter</span>
							<IconChevronDown
								:class="open ? 'rotate-180 transform' : ''"
								class="size-3 text-blue-300 transition"
							/>
						</DisclosureButton>
						<transition
							enter-active-class="transition duration-100 ease-out"
							enter-from-class="transform scale-95 opacity-0"
							enter-to-class="transform scale-100 opacity-100"
							leave-active-class="transition duration-75 ease-out"
							leave-from-class="transform scale-100 opacity-100"
							leave-to-class="transform scale-95 opacity-0"
						>
							<DisclosurePanel class="border-t border-blue-50 p-4 text-sm text-gray-500">
								<div>
									<BaseButton
										variant="primary"
										size="xs"
										icon="Download"
										icon-class="w-4 h-4"
										:disabled="!canExportAsPdf()"
										:loading="downloadingPdf"
										@click="exportAsPdf()"
									>
										Export quotations as PDF
									</BaseButton>
								</div>
							</DisclosurePanel>
						</transition>
					</Disclosure>
				</section>
				<!-- Offers -->
				<section class="mt-4">
					<ul class="flex flex-col gap-4">
						<li
							v-for="quotation in quotations.data"
							:key="quotation.id"
						>
							<booking-aircraft-entry
								:booking="booking"
								:quotation="quotation"
								:is-business-getter="isBusinessGetter"
								:selected="isAircraftSelected(quotation.id)"
								@review-contract="reviewContract(quotation)"
								@ask-for-contract="askForContract(quotation)"
								@toggle="toggleAircraft(quotation.id)"
								@export-as-pdf="exportAsPdf([quotation.id])"
							/>
						</li>
					</ul>
				</section>
			</section>
		</div>

		<!-- Dialogs -->
		<OverridenNameDialog v-slot="{ resolve }">
			<BaseDialog show title="Specify client name" @close="resolve(false)">
				<form class="px-4 py-6" @submit.prevent="resolve(true)">
					<BaseInput
						v-model="clientName"
						type="text"
						label="Client name"
						:placeholder="booking.user?.full_name ?? 'Keep default'"
						clearable
					/>
					<div class="flex justify-center gap-x-2">
						<BaseButton class="mt-4" variant="secondary" type="button" size="sm" @click="resolve(false)">
							Cancel
						</BaseButton>
						<BaseButton class="mt-4" variant="primary" type="submit" size="sm">
							Submit
						</BaseButton>
					</div>
				</form>
			</BaseDialog>
		</OverridenNameDialog>
	</page-container>
</template>
