<script setup lang="ts">
import type { CreateLegData } from '~/ts/legs'

const $emit = defineEmits<{
	(e: 'update:modelValue', value: CreateLegData): void
	(e: 'remove'): void
	(e: 'search'): void
}>()

const $props = withDefaults(defineProps<{
	modelValue?: CreateLegData
	searchable?: boolean
	searchDisabled?: boolean
	removable?: boolean
	index: number
}>(), {
	removable: false,
	searchable: false,
})

const leg = useVModel($props, 'modelValue', $emit)

function swapAirports() {
	[leg.value!.departure_airport, leg.value!.arrival_airport] = [
		leg.value!.arrival_airport,
		leg.value!.departure_airport,
	]
}

function clearAirport(type: 'departure_airport' | 'arrival_airport') {
	leg.value![type] = undefined
}

function updateAirport(type: 'departure_airport' | 'arrival_airport', airport: Resource.Airport) {
	leg.value![type] = airport
}

function updateDate(date: string) {
	leg.value!.departure_date = date
}
</script>

<template>
	<div class="relative lg:flex">
		<div class="md:flex lg:flex-1">
			<LegInputGroup
				:clearable="!!leg?.departure_airport"
				class="group rounded-t-xl border-b-0 pb-1 sm:pb-0 md:flex-1 md:rounded-tr-none md:border-r-0 md:pr-2 lg:rounded-l-full lg:rounded-tr-none lg:border-b lg:pl-5"
				@clear="clearAirport('departure_airport')"
			>
				<template #label>
					<LegInputLabel
						icon="IconFlightFrom"
						:for="'departure_airport_' + index"
					>
						From
					</LegInputLabel>
				</template>
				<LegInputAirport
					:id="'departure_airport_' + index"
					:model-value="leg?.departure_airport"
					options-class="mt-3.5 lg:-mr-7 lg:-ml-3.5"
					input-class="group-focus-within:placeholder:!text-warning-500"
					@update:model-value="updateAirport('departure_airport', $event)"
				/>
			</LegInputGroup>

			<div
				class="flex h-0 items-center justify-center overflow-visible md:h-auto md:w-0 md:justify-start"
			>
				<button
					tabindex="-1"
					class="z-10 flex shrink-0 items-center justify-center rounded-full border border-turquoise-200 bg-white p-1.5 hover:border-turquoise-300 focus-visible:ring-1 focus-visible:ring-turquoise-600 md:-translate-x-1/2"
					type="button"
					@click.prevent="swapAirports"
				>
					<IconBidirectionalChevronArrows class="size-4 text-turquoise-500" />
				</button>
			</div>

			<LegInputGroup
				:clearable="!!leg?.arrival_airport"
				class="group border-b-0 md:flex-1 md:rounded-tr-xl lg:rounded-none lg:border"
				@clear="clearAirport('arrival_airport')"
			>
				<template #label>
					<LegInputLabel
						icon="IconFlightTo"
						:for="'arrival_airport_' + index"
					>
						To
					</LegInputLabel>
				</template>
				<LegInputAirport
					:id="'arrival_airport_' + index"
					:model-value="leg?.arrival_airport"
					options-class="mt-2.5"
					input-class="group-focus-within:placeholder:!text-warning-500"
					@update:model-value="updateAirport('arrival_airport', $event)"
				/>
			</LegInputGroup>
		</div>

		<div class="flex flex-wrap lg:w-2/5 lg:flex-nowrap xl:w-1/3">
			<LegInputGroup
				class="w-1/2 border-b-0 border-r-0 sm:w-1/3 sm:rounded-bl-xl sm:border-b lg:min-w-max lg:flex-1 lg:rounded-bl-none lg:border-l-0"
			>
				<template #label>
					<LegInputLabel icon="IconDate" :for="'departure_date' + index">
						Date
					</LegInputLabel>
				</template>
				<LegInputDate
					:id="'departure_date_' + index"
					:model-value="leg?.departure_date"
					@update:model-value="updateDate($event)"
				/>
			</LegInputGroup>

			<LegInputGroup class="w-1/2 border-b-0 sm:w-1/3 sm:border-b sm:border-r-0 lg:flex-1">
				<template #label>
					<LegInputLabel icon="IconClock" :for="'time' + index">
						Time
					</LegInputLabel>
				</template>
				<LegInputTime
					:id="'time_' + index"
					:model-value="leg?.departure_date"
					@update:model-value="updateDate($event)"
				/>
			</LegInputGroup>

			<LegInputGroup
				class="w-full rounded-b-xl sm:w-1/3 sm:rounded-bl-none lg:w-2/5 lg:rounded-br-none lg:border-r-0"
			>
				<template #label>
					<LegInputLabel
						icon="IconUserCircle"
						:for="'passengers_' + index"
					>
						Passengers
					</LegInputLabel>
				</template>
				<LegInputPax
					:id="'passengers_' + index"
					v-model="leg"
				/>
			</LegInputGroup>
		</div>

		<div class="lg:rounded-r-full lg:border lg:border-turquoise-200 lg:bg-white lg:p-1.5 lg:pl-4">
			<div class="lg:flex lg:h-full lg:items-center">
				<div v-if="searchable" class="hidden lg:flex lg:pl-0.5">
					<button
						class="flex items-center justify-center rounded-full bg-warning-500 p-4.5 text-white transition-colors duration-100 hover:bg-warning-400 focus-visible:ring-2 focus-visible:ring-warning-600 focus-visible:ring-offset-2 disabled:bg-turquoise-100"
						:disabled="searchDisabled"
						@click.prevent="$emit('search')"
					>
						<IconSearch class="size-5" />
					</button>
				</div>
				<div
					v-if="removable"
					class="absolute -right-3 -top-3 z-10 lg:static lg:inset-auto lg:z-auto lg:flex lg:items-center lg:p-1.5"
				>
					<button
						class="flex items-center justify-center rounded-full border border-turquoise-100 bg-white p-2.5 text-blue-100 transition-colors duration-100 hover:border-turquoise-300 hover:text-turquoise-300 focus-visible:ring-2 focus-visible:ring-turquoise-600 lg:m-px lg:border-0 lg:p-3.5"
						@click.prevent="$emit('remove')"
					>
						<IconX class="size-3.5 lg:size-4" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
