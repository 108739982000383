import type { Dialog, Dialogs, DialogName, DialogProperties, DialogResult } from './types'
import { useProperty } from '@/composables/useProperty'

function defineDialog<Properties = undefined, Result = undefined>(): Dialog<Properties, Result> {
	return {
		open: false,
		properties: undefined,
		result: undefined,
	}
}

const dialogs = reactive<Dialogs>({
	'chat': defineDialog(),
	'login': defineDialog(),
	'register': defineDialog(),
	'challenge': defineDialog(),
	'aircraft-details': defineDialog(),
	'edit-billing-address': defineDialog(),
	'select-billing-address': defineDialog(),
	'wire-transfer': defineDialog(),
	'select-credit-card': defineDialog(),
	'new-credit-card': defineDialog(),
	'payment-confirmation': defineDialog(),
	'password-recovery': defineDialog(),
	'new-password': defineDialog(),
})

export function useDialog<T extends DialogName>(name: T) {
	const initialState = useProperty(`dialog.${name}`).value

	if (initialState !== undefined) {
		dialogs[name].open = Boolean(initialState)
	}

	const properties = computed<DialogProperties<T>>(() => dialogs[name].properties)
	const result = computed<DialogResult<T>>(() => dialogs[name].result)
	const isOpen = computed(() => dialogs[name].open)
	const setResult = (result?: DialogResult<T>) => dialogs[name].result = result
	const toggle = (open?: boolean) => {
		dialogs[name].open = open === undefined
			? !dialogs[name].open
			: open
	}

	return {
		name,
		isOpen,
		toggle,
		properties,
		result,
		async open(properties?: DialogProperties<T>): Promise<DialogResult<T>> {
			if (properties) {
				dialogs[name].properties = properties
			} else {
				dialogs[name].properties = undefined
			}

			toggle(true)
			await until(isOpen).toBe(false)
			return result.value
		},
		close: (result?: DialogResult<T>) => {
			setResult(result)
			toggle(false)
		},
	}
}

export function makeDialogComposable<T extends DialogName>(name: T) {
	return ()	=> useDialog<T>(name)
}

export function openDialog<T extends DialogName>(name: T, properties?: DialogProperties<T>) {
	return useDialog<T>(name).open(properties)
}

/*
|--------------------------------------------------------------------------
| Composables
|--------------------------------------------------------------------------
*/

export const useChatDialog = makeDialogComposable('chat')
export const useLoginDialog = makeDialogComposable('login')
export const useRegisterDialog = makeDialogComposable('register')
export const useChallengeDialog = makeDialogComposable('challenge')
export const useAircraftDetailsDialog = makeDialogComposable('aircraft-details')
export const useEditBillingAddressDialog = makeDialogComposable('edit-billing-address')
export const useSelectBillingAddressDialog = makeDialogComposable('select-billing-address')
export const useWireTransferDialog = makeDialogComposable('wire-transfer')
export const useSelectCreditCardDialog = makeDialogComposable('select-credit-card')
export const useNewCreditCardDialog = makeDialogComposable('new-credit-card')
export const usePaymentConfirmationDialog = makeDialogComposable('payment-confirmation')
export const usePasswordRecoveryDialog = makeDialogComposable('password-recovery')
export const useNewPasswordDialog = makeDialogComposable('new-password')
