<script setup lang="ts">
const props = withDefaults(defineProps<{
	as?: string
	linkType?: string
	type?: 'button' | 'submit'
	variant?: 'primary' | 'secondary' | 'secondary-hero' | 'blue' | 'warning' | 'danger'
	size?: 'xs' | 'sm' | 'md' | 'lg'
	icon?: string
	iconClass?: string
	iconPlacement?: 'left' | 'right'
	loading?: boolean
	disabled?: boolean
	text?: string
}>(), {
	linkType: 'a',
	iconPlacement: 'left',
	iconClass: 'w-3.5 h-3.5',
	as: 'button',
	type: 'button',
	size: 'md',
})

const iconName = computed(() => props.icon ? `Icon${props.icon}` : null)
</script>

<template>
	<component
		:is="as"
		:as="as === 'InertiaLink' ? linkType : undefined"
		:type="as === 'InertiaLink' ? undefined : type"
		class="relative flex items-center justify-center rounded-full px-4 font-medium transition-colors disabled:cursor-not-allowed"
		:class="{
			'h-[36px]': size === 'xs',
			'h-[38px]': size === 'sm',
			'h-[42px]': size === 'md',
			'h-[45px]': size === 'lg',
			'bg-turquoise-500 text-white hover:bg-turquoise-600 focus-visible:ring-2 focus-visible:ring-turquoise-600 focus-visible:ring-offset-2 disabled:opacity-30 disabled:hover:bg-turquoise-500': variant === 'primary',
			'border border-turquoise-200 bg-white text-turquoise-500 hover:text-turquoise-400 focus-visible:ring-2 focus-visible:ring-turquoise-600 disabled:opacity-30 disabled:hover:text-turquoise-500': variant === 'secondary',
			'border border-turquoise-200 bg-white text-turquoise-500 hover:text-turquoise-400 focus-visible:ring-2 focus-visible:ring-turquoise-600 disabled:opacity-30 disabled:hover:text-turquoise-500 lg:border-[#FBFAF9] lg:bg-transparent lg:text-white lg:hover:text-[rgba(255,255,255,0.78)] lg:disabled:hover:text-white': variant === 'secondary-hero',
			'bg-blue-500 text-white hover:bg-blue-400 focus-visible:ring-2 focus-visible:ring-blue-600 disabled:opacity-30 disabled:hover:bg-blue-500': variant === 'blue',
			'bg-warning-500 text-white hover:bg-warning-400 focus-visible:ring-2 focus-visible:ring-warning-600 focus-visible:ring-offset-2 disabled:bg-turquoise-100': variant === 'warning',
			'border border-red-500 text-red-500 hover:border-red-300 hover:text-red-300 focus-visible:ring-2 focus-visible:ring-red-600 focus-visible:ring-offset-2 disabled:text-red-300': variant === 'danger',
		}"
		:disabled="disabled || loading"
	>
		<Icon
			v-if="icon && iconPlacement === 'left'"
			:name="iconName!"
			:class="[iconClass, 'mr-2 shrink-0']"
		/>

		<slot v-if="!loading">
			{{ text }}
		</slot>

		<IconSpinner v-if="loading" class="size-4" />

		<Icon
			v-if="icon && iconPlacement === 'right'"
			:name="iconName!"
			:class="[iconClass, 'ml-2 shrink-0']"
		/>
	</component>
</template>
