<script setup lang="ts">
import { openDialog } from '~/ts/dialog'
import { match } from '~/ts/utils/match'
import { route } from '@/composables/route'
import { pushNotification } from '~/ts/notifications/notifications'
import DocumentData = Domain.BookingCaptainJet.Data.DocumentData
import OrderData = Domain.Payment.Data.OrderData
import PaymentInputData = Domain.Payment.Data.PaymentInputData
import PaymentMethods = Domain.Payment.Enums.PaymentMethods
import PaymentMethodData = Domain.Payment.Data.PaymentMethodData

const $props = defineProps<{
	quotationId: string
	signatureUrl?: string
	paymentMethods: PaymentMethodData[]
	documents: DocumentData[]
	order: OrderData
}>()

useHead({
	title: 'Payment',
})

const contractForm = useForm({
	signature: '',
})

const paymentForm = useForm<Partial<PaymentInputData>>({
	payment_method: undefined,
	payment_method_id: undefined,
})

const wizard = useStepper({
	contract: {
		title: 'Contract',
		subtitle: 'Please review the following document and sign the contract.',
		isValid: () => contractForm.signature !== '',
		submit: async() => {
			contractForm.post(route('captainjet.web.quotations.sign', { quotation: $props.quotationId }), {
				onSuccess: () => wizard.goToNext(),
			})
		},
	},
	payment: {
		title: 'Payment',
		subtitle: undefined,
		isValid: () => match(paymentForm.payment_method!, {
			credit_card: !!paymentForm.payment_method_id,
			wire_transfer: true,
			default: false,
		}),
		submit: async() => {
			if (paymentForm.payment_method === 'wire_transfer') {
				const result = await openDialog('wire-transfer', {
					details: $props.order.wire_transfer_details!,
				})

				if (!result?.confirmed) {
					return
				}
			}

			paymentForm.post(route('captainjet.web.quotations.pay', { quotation: $props.quotationId }), {
				onSuccess: () => openDialog('payment-confirmation'),
				onError: ({ error }) => pushNotification({
					type: 'error',
					content: error,
				}),
			})
		},
	},
}, $props.signatureUrl ? 'payment' : 'contract')

async function submitCurrentStep() {
	if (!wizard.current.value.isValid()) {
		return
	}

	await wizard.current.value.submit()
}

/*
|--------------------------------------------------------------------------
| Payment methods
|--------------------------------------------------------------------------
*/

// When the wizard is at the payment step, select
// the default credit card if there is any.
whenever(() => wizard.isCurrent('payment'), () => {
	const defaultCreditCard = $props.paymentMethods.find(({ is_default }) => is_default)?.id ?? $props.paymentMethods.at(0)?.id
	if (defaultCreditCard) {
		paymentForm.payment_method = 'credit_card'
		paymentForm.payment_method_id = defaultCreditCard
	}
}, { immediate: true })

const selectedCreditCard = computed(() => $props.paymentMethods.find(({ id }) => paymentForm.payment_method_id === id))

function selectCreditCard(stripeId?: string) {
	if (!stripeId) {
		paymentForm.payment_method_id = undefined
		return
	}

	paymentForm.payment_method_id = stripeId
}

function startCardCreationProcess() {
	openDialog('new-credit-card', { onSave: selectCreditCard })
}

function startCardSelectionProcess() {
	openDialog('select-credit-card', {
		selected: selectedCreditCard.value?.id,
		onSelect: selectCreditCard,
	})
}

function togglePaymentMethod(method: PaymentMethods) {
	paymentForm.payment_method = paymentForm.payment_method === method
		? undefined
		: method

	return paymentForm.payment_method === method
}

function toggleCreditCard() {
	// If we already selected "credit card" but we have no payment method,
	// we open the dialog to create one. This is a temporary workaround
	// because the "create credit card" button is missing from design.
	if (paymentForm.payment_method === 'credit_card' && !$props.paymentMethods.length) {
		startCardCreationProcess()
		return
	}

	if (!togglePaymentMethod('credit_card')) {
		return
	}

	if ($props.paymentMethods.length) {
		selectCreditCard($props.paymentMethods.find(({ is_default }) => is_default)?.id)
	}

	if (!$props.paymentMethods.length) {
		startCardCreationProcess()
	} else if (!selectedCreditCard.value) {
		startCardSelectionProcess()
	}
}

async function toggleWireTransfer() {
	togglePaymentMethod('wire_transfer')
}
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<page-container>
		<div class="relative flex items-center justify-between pt-5">
			<button
				v-if="!wizard.isFirst"
				type="button"
				class="absolute left-0 top-0 hidden items-center justify-center space-x-2 text-sm text-blue-200 hover:text-blue-300 lg:flex"
				@click="wizard.goToPrevious"
			>
				<icon-chevron-left class="h-3" />
				<span>Back</span>
			</button>

			<h1 class="font-display text-xl font-bold text-blue-500 sm:pt-1 sm:text-2xl">
				Booking process
			</h1>

			<!-- Button has specific ID for GTM tracking. -->
			<base-button
				id="cj-quotations-process-continue"
				size="md"
				class="pl-8 pr-5"
				variant="primary"
				icon="ChevronRight"
				icon-placement="right"
				icon-class="h-3 ml-3 sm:ml-4"
				:disabled="!wizard.current.value.isValid()"
				:loading="contractForm.processing || paymentForm.processing"
				:data-order-total-price="order.total_price"
				:data-order-currency="order.currency"
				@click="submitCurrentStep"
			>
				Continue
			</base-button>
		</div>

		<div class="mt-4 w-full rounded-xl bg-white lg:flex">
			<div class="border-b border-turquoise-100 lg:border-b-0 lg:border-r">
				<div class="hidden flex-col space-y-3.5 p-5 pr-8 lg:flex">
					<booking-step-label
						v-for="(step, name) in wizard.steps.value"
						:key="name"
						:step-name="(name as string)"
						:step="step"
						:wizard="wizard"
					/>
				</div>
				<div class="p-3.5 sm:p-5 lg:hidden">
					<div class="relative flex items-center justify-center">
						<button
							v-if="!wizard.isFirst"
							type="button"
							class="absolute inset-y-0 left-0 flex items-center justify-center space-x-2 text-sm text-blue-200 hover:text-blue-300 focus-visible:text-blue-400"
							@click="wizard.goToPrevious"
						>
							<icon-chevron-left class="h-3" />
							<span>Back</span>
						</button>
						<p class="cursor-default text-sm text-blue-300">
							{{ `Step ${wizard.index.value + 1}/${wizard.stepNames.value.length}` }}
						</p>
					</div>
					<div class="mt-3.5 h-1.5 rounded-full bg-turquoise-50">
						<div class="h-full rounded-full bg-turquoise-500" :style="{ width: `${((wizard.index.value + 1)/4) * 100}%` }" />
					</div>
				</div>
			</div>

			<div class="flex flex-1 flex-col p-3.5 sm:p-5">
				<div class="mb-3.5 space-y-1">
					<h2 class="text-15 font-medium uppercase tracking-widest text-blue-200" v-text="wizard.current.value.title" />
					<h3 v-if="wizard.current.value.subtitle" class="text-xs text-blue-500" v-text="wizard.current.value.subtitle" />
				</div>

				<form class="grow" @submit.prevent="submitCurrentStep">
					<!-- Contract -->
					<div v-if="wizard.isCurrent('contract')">
						<div class="space-y-3.5">
							<booking-document-link
								v-for="document in documents"
								:key="document.url"
								:title="document.name"
								:href="document.url"
								target="_blank"
							/>
						</div>
						<div class="mt-5">
							<signature-pad v-model="contractForm.signature" :current-signature-url="signatureUrl" />
						</div>
					</div>

					<!-- Carbon offsetting -->
					<!-- <div v-if="currentStepIs('carbon-offsetting')">
						<div class="flex flex-col items-center mt-3 text-center sm:flex-row sm:text-left">
							<div class="overflow-hidden shrink-0 w-32 h-[5.5rem] rounded-2xl">
								<div
									class="object-cover w-full h-full bg-blue-100"
									src=""
									alt=""
								/>
							</div>
							<div class="mt-4 font-display text-lg leading-6 sm:mt-0 sm:ml-4 lg:ml-7">
								<h3 class="font-bold text-blue-500">
									Offset your carbon emissions
								</h3>
								<p class="mt-0.5 text-blue-300">
									Quick description lorem ipsum dolor sit amet consectetur
								</p>
							</div>
						</div>

						<div class="mt-7">
							<div class="rounded-xl border border-turquoise-100 sm:flex">
								<div class="flex-1 p-5 sm:pr-9">
									<div class="flex justify-between items-center">
										<div class="flex items-center space-x-3 text-15 font-medium tracking-widest text-success-500 uppercase">
											<IconAircraftFlying class="w-6 h-6" />
											<span>Flight</span>
										</div>
										<p class="text-sm tracking-tighter text-blue-400">
											21 Feb. 20
										</p>
									</div>
									<div class="flex mt-3">
										<div class="flex">
											<div class="flex flex-col justify-between text-xs font-medium leading-5 text-blue-500">
												<p>{{ '19h30' }}</p>
												<p>{{ '19h30' }}</p>
											</div>

											<div class="flex flex-col flex-1 justify-between items-center py-0.5 mx-4 h-full sm:mx-6">
												<div class="shrink-0 p-1 bg-success-500 rounded-full">
													<div class="p-1 bg-white rounded-full" />
												</div>

												<div class="flex-1 border-l-2 border-turquoise-300 border-dashed" />

												<div class="shrink-0 p-1 bg-success-500 rounded-full">
													<div class="p-1 bg-white rounded-full" />
												</div>
											</div>
										</div>

										<div class="flex flex-col space-y-4 text-lg font-medium tracking-tighter leading-5 text-blue-500">
											<p>{{ 'La Môle' }}</p>
											<p>{{ 'La Môle La Môle' }}</p>
										</div>
									</div>
								</div>

								<div class="relative sm:hidden">
									<div class="border-t border-turquoise-100 border-dashed" />
									<div class="absolute -top-2 -left-px mt-px w-2.5 h-4 bg-white rounded-r-full border border-turquoise-100 border-l-white" />
									<div class="absolute -top-2 -right-px mt-px w-2.5 h-4 bg-white rounded-l-full border border-turquoise-100 border-r-white" />
								</div>

								<div class="hidden relative border-l border-turquoise-100 border-dashed sm:block">
									<div class="absolute top-0 -left-2 -mt-px w-4 h-2 bg-white rounded-b-full border border-turquoise-100 border-t-white" />
									<div class="absolute bottom-0 -left-2 -mb-px w-4 h-2 bg-white rounded-t-full border border-turquoise-100 border-b-white" />
								</div>

								<div class="flex justify-center items-center p-4 sm:flex-col sm:py-5 sm:px-9">
									<div class="flex items-center space-x-1.5 tracking-tighter text-success-500 sm:-ml-1">
										<IconApproximation class="w-4.5 h-4.5" />
										<span class="font-bold">XX kg</span>
									</div>
									<span class="sm:hidden">&nbsp;</span>
									<p class="text-sm text-blue-400 sm:mt-1">
										Carbon Footprint
									</p>
								</div>
							</div>
						</div>

						<SwitchGroup as="div" class="flex justify-between items-center p-4 mt-6 rounded-xl border border-turquoise-100 sm:px-6">
							<SwitchLabel as="div" class="flex items-center space-x-3 font-medium tracking-tighter text-blue-500 cursor-default">
								<IconTree class="shrink-0 w-5" />
								<span>Carbon offsetting fee</span>
							</SwitchLabel>

							<InputSwitch v-model="form.offset_carbon_emissions" label="Carbon offsetting fee" />
						</SwitchGroup>
					</div> -->

					<!-- step 4 -->
					<div v-if="wizard.isCurrent('payment')">
						<div>
							<div class="rounded-xl border border-turquoise-100 bg-turquoise-50">
								<div class="p-5">
									<h3 class="mb-5 text-lg font-bold text-blue-500">
										Costs
									</h3>
									<div class="space-y-2">
										<div
											v-for="item in order.items"
											:key="item.name"
											class="flex items-center justify-between font-medium tracking-tighter text-blue-300"
										>
											<p v-text="item.name" />
											<p v-text="item.formatted_price" />
										</div>
									</div>
								</div>
								<div class="border-t border-dashed border-blue-100">
									<div class="flex items-center justify-between px-5 py-4 font-bold uppercase text-blue-500">
										<p>Total</p>
										<p v-text="order.formatted_price" />
									</div>
								</div>
							</div>
							<div class="mt-8 space-y-1">
								<h3 class="text-15 font-medium uppercase tracking-widest text-blue-200">
									Payment method
								</h3>
								<p class="text-xs text-blue-500">
									Please select a payment method.
								</p>
							</div>

							<!-- Payment method selection -->
							<div class="flex flex-wrap items-center">
								<!-- Credit card -->
								<selectable-button
									class="mr-3 mt-5 space-x-3 px-4.5"
									icon="CreditCard"
									icon-class="w-4 h-4 shrink-0"
									:checked="paymentForm.payment_method === 'credit_card'"
									title="Credit card"
									@click="toggleCreditCard"
								>
									Credit card
								</selectable-button>

								<!-- Wire transfer -->
								<selectable-button
									v-if="order.available_payment_methods.includes('wire_transfer')"
									class="mr-3 mt-5 space-x-3 px-4.5"
									icon="WireTransfer"
									icon-class="w-5 h-5 shrink-0"
									:checked="paymentForm.payment_method === 'wire_transfer'"
									title="Wire transfer"
									@click="toggleWireTransfer"
								>
									Wire transfer
								</selectable-button>
							</div>

							<!-- TODO: missing "add credit card" -->

							<!-- Credit card -->
							<div v-if="paymentForm.payment_method === 'credit_card'" class="mt-8">
								<div class="flex flex-col">
									<h4 class="text-15 font-medium uppercase tracking-widest text-blue-200">
										Credit card
									</h4>

									<!-- Add credit card -->
									<div
										v-if="!paymentMethods.length"
										class="mb-1 mt-4"
									>
										<base-button
											variant="primary"
											size="md"
											icon="Plus"
											icon-class="h-3.5 w-3.5 shrink-0"
											class="px-6 tracking-tight"
											@click="startCardCreationProcess"
										>
											Add new credit card
										</base-button>
									</div>

									<!-- Selected credit card -->
									<button
										v-else
										type="button"
										class="mt-3 flex w-full items-center justify-between rounded-xl border border-turquoise-200 px-5 py-4 focus-within:ring-turquoise-600 hover:border-turquoise-300 focus-visible:ring-2"
										@click="startCardSelectionProcess"
									>
										<div class="flex items-center space-x-4 text-turquoise-500">
											<icon-credit-card class="size-5 shrink-0" />
											<span v-if="selectedCreditCard" class="flex items-center">
												<icon-asterisk v-for="i in 12" :key="i" class="w-2.5" :class="{ 'mr-1.5': i % 4 === 0 }" />
												{{ selectedCreditCard.last_four }}
											</span>
											<span v-else>---- ---- ---- ----</span>
										</div>
										<icon-chevron-right class="h-3.5 shrink-0 text-turquoise-400" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</page-container>
</template>
