<template>
	<svg viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M36.675 7.645v46.461c0 .641-.525 1.157-1.163 1.157H1.152A1.151 1.151 0 0 1 0 54.106V7.645c0-.641.513-1.17 1.151-1.17h34.373c.638 0 1.151.529 1.151 1.17Z"
			fill="#8EA1B4"
		/>
		<path
			d="M36.677 7.645v45.342a3.442 3.442 0 0 1-1.139.201H3.455a1.146 1.146 0 0 1-1.139-1.144V7.633c0-.403.063-.792.188-1.145h33.022c.638-.012 1.15.516 1.15 1.157Z"
			fill="#CFD8E0"
		/>
		<path
			d="M19.694 14.259c-5.355 0-9.71 4.376-9.71 9.757 0 5.382 4.355 9.758 9.71 9.758 5.356 0 9.71-4.376 9.71-9.758 0-5.381-4.354-9.757-9.71-9.757Zm7.77 8.814h-3.09c-.1-2.477-.625-4.627-1.414-6.186a7.866 7.866 0 0 1 4.505 6.186Zm-7.77 8.802c-1.063 0-2.577-2.666-2.777-6.916h5.568c-.2 4.263-1.715 6.916-2.79 6.916Zm-2.777-8.802c.2-4.25 1.714-6.916 2.777-6.916 1.064 0 2.578 2.666 2.778 6.916h-5.556Zm-.476-6.199c-.788 1.56-1.301 3.71-1.414 6.187h-3.09a7.905 7.905 0 0 1 4.504-6.187Zm-4.505 8.098h3.091c.1 2.477.626 4.627 1.414 6.186a7.885 7.885 0 0 1-4.505-6.186Zm11.024 6.186c.789-1.559 1.302-3.709 1.414-6.186h3.09a7.885 7.885 0 0 1-4.504 6.186ZM27.29 42.324H12.11a.953.953 0 0 1-.95-.955c0-.528.425-.956.95-.956H27.29c.526 0 .951.428.951.956-.012.54-.425.955-.95.955ZM24.886 46.851h-10.36a.953.953 0 0 1-.952-.955c0-.528.426-.956.951-.956h10.36c.526 0 .952.428.952.956-.013.528-.438.955-.951.955Z"
			fill="#fff"
		/>
		<path
			d="M60 28.153v15.441c0 .34-.075.667-.213.956a2.302 2.302 0 0 1-2.09 1.345H28.143a2.31 2.31 0 0 1-2.302-2.313V28.153c0-.817.425-1.546 1.076-1.949.35-.226.776-.352 1.226-.352h29.556A2.307 2.307 0 0 1 60 28.153Z"
			fill="#8EA1B4"
		/>
		<path
			d="M60 28.153v15.441c0 .34-.075.667-.213.956-.35.226-.776.364-1.226.364H29.006a2.31 2.31 0 0 1-2.303-2.313V27.173c0-.34.075-.667.213-.956.35-.226.776-.352 1.226-.352h29.556A2.288 2.288 0 0 1 60 28.153Z"
			fill="#FBFAF8"
		/>
		<path
			d="M56.47 29.99H43.63a.715.715 0 0 1-.713-.717c0-.403.325-.717.713-.717h12.851c.4 0 .713.327.713.717 0 .39-.325.716-.726.716ZM56.47 33.158H43.63a.715.715 0 0 1-.713-.717c0-.402.325-.716.713-.716h12.851c.4 0 .713.327.713.716 0 .39-.325.717-.726.717ZM49.688 36.326H43.63a.715.715 0 0 1-.713-.717c0-.402.325-.716.713-.716h6.056c.4 0 .714.327.714.716 0 .39-.326.717-.714.717ZM32.622 42.626h-2.315a.715.715 0 0 1-.713-.716c0-.403.325-.717.713-.717h2.315c.4 0 .713.327.713.717a.7.7 0 0 1-.713.716ZM37.388 42.626h-2.315a.715.715 0 0 1-.714-.716c0-.403.326-.717.714-.717h2.315c.4 0 .713.327.713.717a.715.715 0 0 1-.713.716ZM42.145 42.626H39.83a.715.715 0 0 1-.713-.716c0-.403.326-.717.713-.717h2.315c.4 0 .714.327.714.717a.715.715 0 0 1-.714.716ZM46.9 42.626h-2.316a.715.715 0 0 1-.713-.716c0-.403.325-.717.713-.717H46.9c.4 0 .713.327.713.717a.715.715 0 0 1-.713.716Z"
			fill="#CFD8E0"
		/>
		<path
			d="M38.653 30.064v7.683c0 .39-.313.717-.7.717h-7.646a.73.73 0 0 1-.5-.214c-.038-.038-.076-.1-.113-.15-.038-.064-.05-.114-.075-.19a.641.641 0 0 1-.025-.163v-7.683c0-.39.313-.704.713-.704h7.645a.71.71 0 0 1 .701.704Z"
			fill="#E4ECEF"
		/>
		<path
			d="M35.309 32.894c-.213.352-.126.05-.126.503 0 .189.126.34.288.44.1.063.213.113.3.138.076.038.138.05.138.05l-.175.114-1.49 1.106h-.012l-1.676-1.22s.25-.062.45-.2c.15-.1.275-.24.275-.428 0-.427.063-.176-.087-.427-.013-.013-.025-.038-.038-.038-.012-.025-.025-.025-.025-.025-.212-.365-.375-.805-.375-1.069 0 0-.038-.013-.063-.038-.112-.063-.325-.239-.313-.654 0-.188.038-.301.076-.364.075-.126.175-.088.187-.088a4.336 4.336 0 0 1-.263-.805 4.633 4.633 0 0 1-.025-.402c0-.05.013-.101.038-.126.163-.428.45-.453.425-.616v-.038c.113-.214.338-.277.551-.302a.621.621 0 0 0 .425-.025c0-.012 0-.012.013-.012.113-.038.263-.076.425-.088.038 0 .063 0 .088.012.125.013.25.038.338.075a.75.75 0 0 0 .438.038c.15.063.263.151.263.264 0 0 .125 0 .287.038.05.013.113.038.163.05.163.076.313.201.313.428 0 .1-.3 1.496-.3 1.509.012 0 .112-.038.187.088.038.063.075.176.075.364.013.415-.187.579-.313.654-.037.025-.062.038-.062.038 0 .176-.075.428-.188.666 0 0 0 .013-.012.026 0 .012-.013.025-.013.037-.012.025-.025.038-.025.05h-.012a.505.505 0 0 1-.063.114.965.965 0 0 0-.087.163Z"
			fill="url(#a)"
		/>
		<path
			d="M35.81 30.68c-.026.089-.139.265-.139.265h-.112c.112-.264-.163-.93-.163-.93-.062-.026-.125-.026-.213-.026h-.112c-.638-.012-1.602-.515-1.602-.515l-.25.264c-.088.1-.175.176-.213.277-.188.39-.163.98-.163.98h-.05c0-.126-.15-.34-.15-.34a4.34 4.34 0 0 1-.263-.804c-.012-.138-.025-.277-.025-.402 0-.05.013-.1.038-.126.163-.428.45-.453.425-.616v-.038c.113-.214.338-.277.551-.302.188-.025.338 0 .425-.025 0-.012 0-.012.013-.012.113-.038.263-.076.425-.088.038 0 .063 0 .088.012.125.013.25.038.338.076.137.062.3.062.438.037.15.063.263.151.263.264 0 0 .125 0 .287.038.05.013.113.038.163.05.15.05.263.126.375.327.25.478-.375 1.622-.375 1.635Z"
			fill="#23466B"
		/>
		<path
			d="m35.774 33.975-.037.176-1.49 1.107h-.012l-1.676-1.22s.25-.063.45-.2c.15-.102.288-.24.275-.428 0-.176-.012-.202-.087-.428.1.113.338.352.8.415.1.025.188.025.3.025.589 0 .94-.402 1.102-.666-.025.05-.063.113-.087.176-.1.276-.113.301-.088.503.025.2.088.327.25.44.088.025.213.063.3.1Z"
			fill="url(#b)"
		/>
		<path
			d="M38.651 37.71v.037c0 .39-.312.717-.7.717h-7.646a.73.73 0 0 1-.5-.214.82.82 0 0 1-.188-.34c.175-.93.55-2.577 1.101-2.954.776-.516 2.278-1.17 2.278-1.17s0 .013.012.026c.063.113.388.716 1.139.742.037.012.05.012.087.012.739 0 1.039-.364 1.177-.59.037-.064.05-.114.062-.152.013-.025.013-.038.013-.038s1.501.654 2.265 1.17c.5.34.775 1.798.9 2.754Z"
			fill="#93BFC9"
		/>
		<path d="M35.725 31.247s.138-.276.138-.125c0 .15-.088.427-.138.477-.05.05 0-.352 0-.352Z" fill="url(#c)" />
		<path d="M32.743 31.247s-.138-.276-.138-.125c0 .15.088.427.138.477.05.05 0-.352 0-.352Z" fill="url(#d)" />
		<path
			d="M34.887 30.63c-.363 0-.463.126-.488.29a.268.268 0 0 0-.15-.05.312.312 0 0 0-.163.05c-.025-.164-.125-.302-.488-.302-.425 0-.5.05-.5.352 0 .239 0 .654.5.654.276 0 .5-.227.5-.503v-.088a.238.238 0 0 1 .15-.063c.076 0 .126.038.139.063v.088c0 .276.225.503.5.503.5 0 .5-.403.5-.654 0-.277-.087-.34-.5-.34Zm-1.301.906c-.376 0-.4-.252-.4-.553 0-.214 0-.252.4-.252s.4.15.4.402a.396.396 0 0 1-.4.403Zm1.301 0a.396.396 0 0 1-.4-.403c0-.251 0-.402.4-.402s.4.05.4.252c0 .314-.037.553-.4.553Z"
			fill="#4F6B89"
		/>
		<path
			d="M34.324 28.292c-.025-.013-.05-.013-.088-.013a1.34 1.34 0 0 0-.425.088c-.013 0-.013 0-.013.013-.087.025-.25 0-.425.025-.213.025-.438.088-.55.302v.037c.024.164-.264.19-.426.617 0 0-.013.012 0 .025-.025.025-.038.063-.038.113 0 .113.013.264.025.402.075.39.263.793.263.805-.013 0-.113-.038-.188.088-.037.063-.075.176-.075.365-.012.415.188.578.313.654a.657.657 0 0 0 .063.037c0 .264.162.704.375 1.07 0 0 .012 0 .025.024.013.013.025.038.038.038.05.126.075.163.087.428.013.188-.125.339-.275.427-.013-.013-.013-.025-.013-.025s-1.501.654-2.277 1.17c-.55.364-.926 2.024-1.101 2.954a.64.64 0 0 1-.025-.163V34.93c.05-.063.112-.113.175-.163.776-.516 2.277-1.17 2.277-1.17s0 .013.013.025c.15-.1.275-.239.275-.427 0-.428.063-.176-.088-.428-.012-.012-.025-.038-.037-.038-.013-.025-.025-.025-.025-.025-.213-.364-.375-.804-.375-1.068 0 0-.038-.013-.063-.038-.113-.063-.325-.24-.313-.654 0-.189.038-.302.075-.365.075-.125.175-.088.188-.088a4.33 4.33 0 0 1-.263-.804c-.012-.101-.012-.202-.012-.29-.013-.037-.013-.075-.013-.113 0-.05.013-.1.038-.125.162-.428.45-.453.425-.617v-.037c.113-.214.338-.277.55-.302.188-.025.339 0 .426-.025 0-.013 0-.013.013-.013.112-.038.262-.075.413-.088.162.013.312.038.425.088a.75.75 0 0 0 .438.038.294.294 0 0 1 .188.088Z"
			fill="#8EA1B4"
		/>
		<path
			d="M20.796 1.433v5.08H6.344v-5.08A1.43 1.43 0 0 1 7.77 0h11.6a1.43 1.43 0 0 1 1.426 1.433Z"
			fill="#61A7B7"
		/>
		<path
			d="M20.795 1.433v3.898H9.258a1.37 1.37 0 0 1-1.363-1.37V0h11.474a1.43 1.43 0 0 1 1.426 1.433Z"
			fill="#93BFC9"
		/>
		<path
			d="M16.605 1.962c-.025.163-.125.44-.326.78-.187.339-1.113 1.307-1.113 1.307 0 .05.463 1.697.675 2.464h-.538l-.8-1.735-.013-.012c-.1.088-.213.176-.313.251-.425.34-.763.591-1.051.805a7.339 7.339 0 0 1-.688.465l.025.063.037.15h-.863l-1.051-.502c-.05-.025-.063-.088-.025-.126l.225-.226c.025-.025.05-.025.075-.025l1.189.314c.037.013.075 0 .1-.038.225-.352.6-.88 1.239-1.697.087-.113.175-.214.263-.315l-.013-.012-2.553-1.182a.095.095 0 0 1 0-.113l.213-.214c.038-.038.088-.038.113 0 0 0 2.878.842 2.94.842 0 0 .951-.918 1.277-1.106.35-.214.638-.314.8-.34.1.026.188.101.176.202Z"
			fill="#fff"
		/>
		<path d="M52.877 23.544 50.23 26.91l-.942 1.168-3.55-4.534h1.885v-5.662h3.35v5.662h1.904Z" fill="#93BFC9" />
		<defs>
			<linearGradient
				id="a"
				x1="31.741"
				y1="34.493"
				x2="33.464"
				y2="32.448"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F9AD8B" />
				<stop offset=".865" stop-color="#FDD6AC" />
			</linearGradient>
			<linearGradient
				id="b"
				x1="34.162"
				y1="32.438"
				x2="34.162"
				y2="35.38"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F9AD8B" />
				<stop offset=".865" stop-color="#F7D297" />
			</linearGradient>
			<linearGradient
				id="c"
				x1="36.627"
				y1="30.116"
				x2="34.416"
				y2="33.258"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F9AD8B" />
				<stop offset=".865" stop-color="#E1BA7C" />
			</linearGradient>
			<linearGradient
				id="d"
				x1="34.551"
				y1="28.659"
				x2="32.34"
				y2="31.801"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#F9AD8B" />
				<stop offset=".865" stop-color="#E1BA7C" />
			</linearGradient>
		</defs>
	</svg>
</template>
