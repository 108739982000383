<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18.33 0H5.342a2.466 2.466 0 0 0-2.46 2.46v17.997c0 .238.098.463.266.625l2.686 2.57a.876.876 0 0 0 1.216-.01l2.014-2.015 2.015 2.014a.867.867 0 0 0 1.227 0l2.02-2.02 2.183 2.136a.854.854 0 0 0 .607.249c.22 0 .434-.081.602-.243l2.808-2.715a.872.872 0 0 0 .266-.625V2.46A2.466 2.466 0 0 0 18.331 0Zm.725 20.052-1.934 1.87-2.194-2.148a.854.854 0 0 0-.608-.249.873.873 0 0 0-.613.255l-2.015 2.014-2.014-2.014a.867.867 0 0 0-1.227 0l-2.026 2.026-1.807-1.725V2.461c0-.4.325-.724.724-.724h12.99c.4 0 .724.324.724.723v17.592Z"
			fill="currentColor"
		/>
		<path
			d="M14.933 6.148H8.74a.867.867 0 1 0 0 1.736h6.194a.867.867 0 1 0 0-1.736ZM14.933 10.316H8.74a.867.867 0 1 0 0 1.736h6.194a.867.867 0 1 0 0-1.736Z"
			fill="currentColor"
		/>
	</svg>
</template>
