<template>
	<svg viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.444 14.322c.195 0 .39 0 .585-.196a1.038 1.038 0 0 0 0-1.277L6.518 9.314h1.659c.488 0 .878-.295.878-.786 0-.393-.39-.785-.878-.785H4.664c-.488 0-.78.392-.78.785v3.634c0 .393.487.785.877.785s.78-.294.78-.785v-1.375l3.318 3.339c.195.098.39.196.585.196ZM18.908 14.224c0-.491.39-.884.878-.884.487 0 .878.393.878.982v3.535c0 .491-.39.884-.878.884h-3.61a.877.877 0 0 1-.878-.884c0-.49.39-.883.878-.883h1.756l-3.512-3.536c-.293-.294-.293-.883 0-1.178.293-.295.878-.295 1.17 0l3.318 3.339v-1.375Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24.273 6.662a2.319 2.319 0 0 0-2.341-2.356h-4.78V3.127c0-1.178-.976-2.16-2.147-2.16h-5.56C8.272.869 7.297 1.85 7.297 3.029v1.178H2.615C1.347 4.306.273 5.287.273 6.662v13.061c0 1.277 1.074 2.357 2.342 2.357h19.317c1.268 0 2.341-1.08 2.341-2.357V6.663ZM9.151 3.03c0-.196.098-.295.293-.295h5.561c.195 0 .293.099.293.295v.884c0 .196-.098.294-.293.294h-5.56c-.196 0-.294-.196-.294-.294v-.884ZM22.42 19.723c0 .295-.195.491-.488.491H2.615c-.293 0-.488-.196-.488-.49V6.661c0-.294.195-.49.488-.49h19.317c.293 0 .488.196.488.49v13.061Z"
			fill="currentColor"
		/>
	</svg>
</template>
