import getRoute from 'ziggy-js'
import { url, routes, defaults } from '../../json/routes.json'

/**
 * Gets a URL for a web route.
 */
export function route<Routes extends typeof routes, RouteName extends keyof Routes>(
	name: RouteName,
	params?: (Routes[RouteName] extends { bindings: any } ? Partial<Record<keyof Routes[RouteName]['bindings'], any>> : {}) & Record<string, any>,
	absolute: boolean = true,
) {
	return getRoute(
		name as any,
		params as any,
		absolute,
		{ url, routes, defaults } as any,
	).toString()
}
