<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.53 9.816 5.193 7.6a.607.607 0 0 1 0-.889.689.689 0 0 1 .936 0l1.208 1.146v-5.23c0-.344.297-.627.661-.627.364 0 .662.283.662.628v5.23L9.869 6.71a.689.689 0 0 1 .936 0 .61.61 0 0 1 .195.443.61.61 0 0 1-.195.443l-2.34 2.22a.688.688 0 0 1-.936 0Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3 12.75a.75.75 0 0 1 .75-.75h8.5a.75.75 0 0 1 0 1.5h-8.5a.75.75 0 0 1-.75-.75Z"
			fill="currentColor"
		/>
	</svg>
</template>
