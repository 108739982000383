<script setup lang="ts">
import { route } from '@/composables/route'
import Settings from '@/layouts/settings.vue'
import { removeEmpty } from '~/ts/utils/object'
import UserData = SupportCaptainJet.Data.UserData
import UpdateUserData = SupportCaptainJet.Data.UpdateUserData

const props = defineProps<{
	user: UserData
}>()

const form = useForm<UpdateUserData>('profile', {
	first_name: props.user.first_name,
	last_name: props.user.last_name,
	email: props.user.email,
	phone: props.user.phone!,
	current_password: '',
	password: '',
	password_confirmation: '',
	business_company_name: props.user.business_company_name,
	business_company_address: props.user.business_company_address,
	business_logo: null,
})

function submit() {
	form
		.transform((data) => removeEmpty(data, (v) => ['', undefined, null].includes(v)))
		.post(route('captainjet.web.settings.profile.update'))
}

function removeLogo() {
	router.delete(route('captainjet.web.settings.profile.business-logo'))
}

function deleteAccount() {
	// TODO
}
</script>


			<script lang="ts">
			import layout from '@/layouts/default.vue'
			export default { layout }
			</script>
			<template>
		
	<Settings current="profile">
		<form class="space-y-4" @submit.prevent="submit">
			<!-- Profile -->
			<SettingsCard title="profile">
				<div class="grid gap-4 sm:grid-cols-2">
					<BaseInput v-model="form.first_name" :error="form.errors.first_name" label="First name" type="text" />
					<BaseInput v-model="form.last_name" :error="form.errors.last_name" label="Last name" type="text" />
					<BaseInput v-model="form.email" :error="form.errors.email" label="Email" type="email" />
					<BaseInput v-model="form.phone" :error="form.errors.phone" label="Phone" type="tel" />
				</div>
			</SettingsCard>

			<!-- Business -->
			<SettingsCard v-if="user.is_business_getter" title="Business getter options">
				<div class="grid gap-4 sm:grid-cols-2">
					<BaseInput v-model="form.business_company_name" :error="form.errors.business_company_name" label="Company name" type="text" />
					<BaseInput v-model="form.business_company_address" :error="form.errors.business_company_address" label="Company address" type="text" />
					<InputFile
						v-model="form.business_logo"
						:error="form.errors.business_logo"
						:current-preview-url="user.business_logo_url"
						label="Logo"
						accept="image/*"
						@remove="removeLogo"
					/>
				</div>
			</SettingsCard>

			<!-- Password -->
			<SettingsCard title="password">
				<div class="grid gap-4 sm:grid-cols-2">
					<BaseInput
						v-model="form.current_password"
						:error="form.errors.current_password"
						label="Current password"
						autocomplete="current-password"
						type="password"
						clearable
					/>
					<div class="hidden sm:block" />
					<BaseInput
						v-model="form.password"
						:error="form.errors.password"
						label="New password"
						type="password"
						autocomplete="new-password"
						clearable
					/>
					<BaseInput
						v-model="form.password_confirmation"
						:error="form.errors.password_confirmation"
						label="Confirm new password"
						autocomplete="off"
						type="password"
						clearable
					/>
				</div>

				<!-- Actions -->
				<div class="mt-6 flex flex-col sm:flex-row sm:justify-end">
					<BaseButton
						class="mb-4 hidden sm:mb-0"
						variant="danger"
						icon="X"
						icon-class="w-3 h-3 mr-3"
						size="sm"
						type="button"
						@click.prevent="deleteAccount"
					>
						Delete my account
					</BaseButton>
					<BaseButton
						icon="Check"
						icon-class="w-5 h-5"
						size="sm"
						type="submit"
						variant="primary"
					>
						Save changes
					</BaseButton>
				</div>
			</SettingsCard>
		</form>
	</Settings>
</template>
