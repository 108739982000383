<script setup lang="ts">
import { useNotifications } from '~/ts/notifications/notifications'

const { notifications, dismiss } = useNotifications()
</script>

<template>
	<div aria-live="assertive" class="pointer-events-none fixed inset-0 z-[60] flex items-end px-4 py-6 sm:items-start sm:p-6">
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<TransitionGroup
				tag="ul"
				class="w-full max-w-sm space-y-2"
				enter-active-class="transform ease-out duration-300 transition"
				enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
				leave-active-class="transition ease-in duration-500"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<li v-for="(notification) in notifications" :key="notification.state.id">
					<NotificationItem :notification="notification" @dismiss="dismiss(notification)" />
				</li>
			</TransitionGroup>
		</div>
	</div>
</template>
