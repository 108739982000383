<template>
	<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.97 1.914A.889.889 0 0 0 10.715.657l-4.4 4.4-4.4-4.4A.889.889 0 1 0 .657 1.914l4.4 4.4-4.4 4.4a.889.889 0 0 0 1.257 1.257l4.4-4.4 4.4 4.4a.889.889 0 0 0 1.257-1.258l-4.4-4.4 4.4-4.399Z"
			fill="currentColor"
		/>
	</svg>
</template>
