<template>
	<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
			stroke="currentColor"
			stroke-width="1.5"
			stroke-miterlimit="10"
		/>
		<path d="M5 10c-.09 6.573 9.91 6.712 10 .14L5 10Z" />
		<path
			d="M5.5 10.007a.5.5 0 1 0-1-.014l1 .014Zm10 .14a.5.5 0 0 0-1-.013l1 .014Zm-11-.154c-.024 1.781.64 3.162 1.677 4.097 1.028.927 2.394 1.39 3.749 1.41 1.354.018 2.732-.407 3.785-1.304 1.063-.906 1.764-2.267 1.789-4.048l-1-.014c-.02 1.505-.603 2.59-1.438 3.3-.844.72-1.977 1.082-3.122 1.066-1.146-.017-2.269-.41-3.093-1.152-.815-.735-1.367-1.836-1.346-3.341l-1-.014Z"
			fill="currentColor"
		/>
		<path
			d="M12.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM7.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
			stroke="currentColor"
			stroke-miterlimit="10"
		/>
	</svg>
</template>
