import { router } from '@inertiajs/vue3'

/**
 * Returns a ref with a value saved in the history state through Inertia.
 */
export function useHistory<T = any>(key: string, initial: T) {
	const value = ref<T>(router.restore(key) as T ?? initial)

	watch(value, (value) => {
		router.remember(toRaw(value), key)
	}, { immediate: true, deep: true })

	return value
}
