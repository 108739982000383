<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.82 12.196c-.032-1.027-.37-1.848-1.006-2.44-.977-.91-2.27-.912-2.495-.906h-.994c-.698-1.713-2.3-2.983-4.23-3.246V4.389h5.238a.552.552 0 0 0 .559-.545c0-.301-.25-.545-.56-.545H6.096V2.188C6.095.767 5.127 0 4.215 0H2.292a.736.736 0 0 0-.746.727c0 .401.334.726.746.726h1.923c.004 0 .39.023.39.735v3.416C2.006 5.959 0 8.139 0 10.769c0 2.877 2.4 5.216 5.35 5.216 2.95 0 5.35-2.34 5.35-5.216 0-.157-.007-.312-.021-.465h.685c.052-.002.882-.007 1.429.51.312.296.49.73.53 1.29-.821.26-1.418 1.013-1.418 1.9 0 1.1.919 1.996 2.048 1.996C15.08 16 16 15.104 16 14.004c0-.799-.483-1.489-1.18-1.808Zm-9.47 2.336c-2.128 0-3.86-1.688-3.86-3.763 0-2.074 1.732-3.763 3.86-3.763s3.86 1.689 3.86 3.763c0 2.075-1.732 3.763-3.86 3.763Zm8.603.378a.92.92 0 0 1-.93-.906c0-.5.417-.906.93-.906a.92.92 0 0 1 .929.906.92.92 0 0 1-.93.906Z"
			fill="currentColor"
		/>
		<path
			d="M5.723 11.646a.94.94 0 0 0 .527-1.24.982.982 0 0 0-1.271-.513.94.94 0 0 0-.527 1.24.982.982 0 0 0 1.271.513Z"
			fill="currentColor"
		/>
	</svg>
</template>
