<script setup lang="ts">
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle,
} from '@headlessui/vue'

const emit = defineEmits<{
	(e: 'close', force?: boolean): void
	(e: 'back'): void
}>()

const props = withDefaults(defineProps<{
	show: boolean
	title?: string
	subtitle?: string
	size?: 'sm' | 'md' | 'lg' | '3xl' | 'screen'
	titleSize?: 'sm' | 'lg'
	initialFocus?: HTMLElement
	unmount?: boolean
	xClass?: string
	closeable?: boolean
}>(), {
	size: 'md',
	titleSize: 'sm',
	unmount: true,
	closeable: true,
})

function onClose() {
	if (props.closeable === false) {
		return
	}

	close()
}

function close(force?: boolean) {
	emit('close', force)
}
</script>

<template>
	<TransitionRoot appear :show="show" as="template" :unmount="unmount">
		<Dialog as="div" :initial-focus="initialFocus" :unmount="unmount" @close="onClose">
			<div class="fixed inset-0 z-dialog-base overflow-y-auto">
				<div class="min-h-screen px-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100"
						leave-to="opacity-0"
					>
						<div class="fixed inset-0 z-[-1] bg-blue-500/80 backdrop-blur-sm [@supports(backdrop-filter:blur(0px))]:bg-blue-500/40" />
					</TransitionChild>

					<span class="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>

					<TransitionChild
						:unmount="unmount"
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<div
							class="relative my-8 inline-block w-full text-left align-middle transition-all"
							:class="{
								'max-w-sm': size === 'sm',
								'max-w-md': size === 'md',
								'max-w-lg': size === 'lg',
								'max-w-3xl': size === '3xl',
								'max-w-7xl': size === 'screen'
							}"
						>
							<slot name="before-wrapper" />

							<DialogPanel
								class="relative mx-auto w-full rounded-2xl bg-white"
							>
								<button
									v-if="closeable"
									type="button"
									class="absolute right-5 top-5 z-dialog-panel text-blue-200 transition duration-75 hover:text-blue-400"
									:class="xClass"
									@click="close(true)"
								>
									<IconX class="h-3" />
								</button>
								<div
									:class="[
										{
											'py-3.5 text-15 font-bold': titleSize === 'sm',
											'py-8 text-lg font-semibold': titleSize === 'lg',
										},
										'relative flex w-full items-center justify-center font-display'
									]"
								>
									<slot name="back" />
									<div>
										<DialogTitle
											v-if="title"
											as="h1"
											class=" text-center uppercase tracking-widest text-blue-500"
										>
											{{ title }}
										</DialogTitle>

										<DialogTitle
											v-if="subtitle"
											as="h2"
											class="font-body -mb-1.5 mt-1 text-center text-sm font-normal text-blue-400"
										>
											{{ subtitle }}
										</DialogTitle>
									</div>
								</div>

								<slot />
							</DialogPanel>
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
