<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M.168 7.409c0-3.978 3.22-7.205 7.195-7.205 3.974 0 7.195 3.227 7.195 7.205a7.18 7.18 0 0 1-1.58 4.506l2.678 2.798a.64.64 0 1 1-.924.885L12.09 12.84a7.161 7.161 0 0 1-4.728 1.774c-3.975 0-7.195-3.227-7.195-7.205Zm7.195-5.925A5.92 5.92 0 0 0 1.448 7.41a5.92 5.92 0 0 0 5.915 5.925 5.92 5.92 0 0 0 5.915-5.925 5.92 5.92 0 0 0-5.915-5.925Z"
			fill="currentColor"
		/>
	</svg>
</template>
