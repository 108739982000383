<template>
	<svg viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m1 12.25 5-5.625L1 1"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</template>
