import axios from 'axios'
import { cache } from './cache'
import { token } from './token'
import { route } from '@/composables/route'

export function useUnreadChatMessage() {
	const unreadChatMessages = asyncComputed(async() => {
		const messageCache = cache.value.get(token.value)

		if (!messageCache && token.value) {
			try {
				const response = await axios.get<number | null>(route('captainjet.web.api.chat.messages.unread', { token: token.value }))
				return Number(response.data ?? 0)
			} catch (error) {}
		}

		return messageCache?.messages.filter((message) => {
			return !message.read_at && !message.is_client
		}).length ?? 0
	})

	return {
		unreadChatMessages,
	}
}
