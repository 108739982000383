<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import countries, { Country } from '../../ts/phone/countries'

const emit = defineEmits<{
	(e: 'update:text', value: string): void
	(e: 'update:e164', value: string): void
	(e: 'update:iso2', value: string): void
	(e: 'update:dialCode', value: string): void
	(e: 'update:formattedText', value: string): void
	(e: 'clear'): void
}>()

const props = withDefaults(defineProps<{
	id: string
	name: string
	text: string
	iso2: string
	e164: string
	dialCode: string
	error?: string
	clearable?: boolean
}>(), {
	id: 'phonenumber',
	name: 'phonenumber',
})

const inputFocus = ref<boolean>()
const inputRef = ref()

const clearInput = () => {
	inputRef.value.focus()
	emit('clear')
}

const getParsedPhoneNumberFromString = (phoneNumber: string, countryCode: any) => {
	const parsing = phoneNumber && countryCode
		? parsePhoneNumberFromString(phoneNumber, countryCode)
		: null

	return {
		countryCode,
		isValid: false,
		...(phoneNumber && phoneNumber !== '' ? { phoneNumber } : {}),
		...(parsing
			? {
				country: parsing.country,
				countryCallingCode: parsing.countryCallingCode,
				formattedNumber: parsing.number,
				nationalNumber: parsing.nationalNumber,
				isValid: parsing.isValid(),
				type: parsing.getType(),
				formatInternational: parsing.formatInternational(),
				formatNational: parsing.formatNational(),
				uri: parsing.getURI(),
				e164: parsing.format('E.164'),
			}
			: null),
	}
}

const formatPhoneNumber = (phonenumber: string) => {
	const result = getParsedPhoneNumberFromString(phonenumber, props.iso2.toString().toUpperCase())

	emit('update:e164', result.e164 ?? props.e164)
	emit('update:iso2', result.country ?? props.iso2)
	emit('update:dialCode', result.countryCallingCode ?? props.dialCode)
	emit('update:formattedText', result?.formatNational ?? result?.phoneNumber ?? phonenumber)

	return result.formatNational ?? result.phoneNumber
}

function updateCountry(country: Country) {
	emit('update:iso2', country.iso2)
	emit('update:dialCode', country.dialCode)
}
</script>

<template>
	<div class="space-y-1">
		<div
			:class="[
				{ 'focus-within:ring-1 focus-within:ring-turquoise-600': inputFocus },
				'relative flex items-center rounded-xl bg-turquoise-50',
			]"
		>
			<div class="flex flex-1 flex-col py-2 pl-3.5">
				<label :for="id" class="font-display text-sm font-medium text-blue-300">Phone</label>
				<div class="mt-2 flex flex-1">
					<Menu v-slot="{ open }" as="div">
						<div>
							<MenuButton
								class="flex h-11 items-center space-x-2 rounded-xl bg-white px-3 focus:ring-2 focus:ring-turquoise-600"
							>
								<div :class="'flag ' + iso2.toLowerCase()" />
								<div class="text-sm font-medium text-blue-500">
									+{{ dialCode }}
								</div>
								<IconChevronDown
									:class="[{ '-rotate-180': open }, 'w-3 text-turquoise-400 transition duration-150']"
								/>
							</MenuButton>
						</div>

						<transition
							v-show="open"
							enter-active-class="transition duration-100 ease-out"
							enter-from-class="scale-95 opacity-0 origin-top"
							enter-to-class="scale-100 opacity-100"
							leave-active-class="transition duration-75 ease-in origin-top"
							leave-from-class="scale-100 opacity-100"
							leave-to-class="scale-95 opacity-0"
						>
							<MenuItems
								static
								class="absolute inset-x-0 top-24 z-10 overflow-hidden rounded-2xl shadow focus:outline-none"
							>
								<div
									class="flex max-h-96 w-full flex-col divide-y divide-turquoise-100 overflow-y-auto bg-white"
								>
									<MenuItem v-for="(country, index) in countries" :key="index" v-slot="{ active }">
										<button
											type="button"
											:class="[
												{ 'bg-turquoise-50': active || country.iso2 === iso2 },
												'flex items-center space-x-2 px-5 py-3.5 hover:bg-turquoise-50',
											]"
											@click="updateCountry(country)"
										>
											<div :class="'flag ' + country.iso2.toLowerCase()" />
											<div
												class="flex flex-1 items-center space-x-1 text-left text-sm text-blue-500"
											>
												<span class="font-medium">
													{{ country.name }}
													<span class="font-normal">+{{ country.dialCode }}</span>
												</span>
											</div>
										</button>
									</MenuItem>
								</div>
							</MenuItems>
						</transition>
					</Menu>
					<input
						:id="id"
						ref="inputRef"
						:value="formatPhoneNumber(text)"
						autocomplete="tel-local"
						:name="name"
						class="w-full flex-1 bg-transparent pl-4 font-medium text-blue-500 caret-blue-500 focus:outline-none"
						type="text"
						@input="$emit('update:text', ($event.target as HTMLInputElement)?.value)"
						@focus="inputFocus = true"
						@blur="inputFocus = false"
					/>
				</div>
			</div>

			<div v-if="clearable || error" class="flex items-center px-2.5">
				<span v-if="error" class="mr-1.5 block size-2.5 flex-none rounded-full bg-danger-500" />
				<button v-if="clearable" class="rounded-full p-2 text-blue-500 ring-blue-500 focus-visible:ring-1" @click="clearInput">
					<IconX class="w-2" />
				</button>
			</div>
		</div>
		<div v-if="error" class="flex items-center space-x-2 text-sm font-medium text-danger-500">
			<IconExclamationCircle class="size-3.5 shrink-0" />
			<span>{{ error }}</span>
		</div>
	</div>
</template>

<style scoped lang="postcss">
@import '../../css/captainjet/input-flags.css';
</style>
