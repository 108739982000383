<script setup lang="ts">
import { route } from '@/composables/route'

defineProps<{
	current: Item
}>()

type Item = 'profile' | 'payment'

// TODO: implement actual preferences
const items: Record<Item, { href: string; title: string }> = {
	profile: { href: route('captainjet.web.settings.profile'), title: 'Profile' },
	payment: { href: route('captainjet.web.settings.payment'), title: 'Payment' },
}
</script>

<template>
	<div class="-mx-4 -my-1 flex sm:m-0">
		<nav class="flex space-x-2.5 overflow-auto whitespace-nowrap px-4 py-1 sm:space-x-3.5 sm:overflow-visible sm:p-0">
			<SettingsMenuItem
				v-for="(item, name) in items"
				:key="name"
				:href="item.href"
				:title="item.title"
				:active="name === current"
			/>
		</nav>
	</div>
</template>
