<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.743 7.739a1.33 1.33 0 0 0 1.032-1.294c0-.731-.596-1.327-1.327-1.327-.73 0-1.326.596-1.326 1.327 0 .63.44 1.159 1.031 1.294a2.584 2.584 0 0 0-2.285 2.564c0 .224.181.406.406.406h4.35a.406.406 0 0 0 .407-.406 2.589 2.589 0 0 0-2.288-2.564Zm-.812-1.294a.514.514 0 1 1 1.028 0 .514.514 0 0 1-1.028 0ZM2.723 9.897a1.774 1.774 0 0 1 1.722-1.365 1.77 1.77 0 0 1 1.722 1.365H2.723Z"
			fill="currentColor"
		/>
		<path
			d="M8.48 5.221h4.545a.405.405 0 1 1 0 .812H8.479a.405.405 0 1 1 0-.812ZM8.48 7.303h3.38a.406.406 0 1 1 0 .812H8.48a.405.405 0 1 1 0-.812Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.286 2.56h13.428c.71 0 1.286.577 1.286 1.286v8.135c0 .71-.577 1.286-1.286 1.286H1.286c-.71 0-1.286-.576-1.286-1.286V3.846c0-.71.577-1.286 1.286-1.286Zm13.428 9.624c.111 0 .203-.092.203-.203V3.846a.202.202 0 0 0-.203-.203H1.286a.205.205 0 0 0-.203.203v8.135a.203.203 0 0 0 .203.203h13.428Z"
			fill="currentColor"
		/>
	</svg>
</template>
