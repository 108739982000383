<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.67 10.408v.873h.542v-.9c.892-.146 1.367-.786 1.367-1.47 0-.77-.448-1.22-1.34-1.582-.754-.31-1.072-.538-1.072-.986 0-.346.25-.743.915-.743.554 0 .908.192 1.09.294l.215-.57c-.251-.146-.605-.286-1.124-.302v-.838H7.73v.873c-.795.13-1.305.673-1.305 1.391 0 .754.545 1.15 1.426 1.497.64.259.978.553.978 1.029 0 .494-.416.848-1.03.848-.483 0-.934-.165-1.245-.373l-.208.578c.303.216.822.373 1.324.381Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.001 14.239a6.197 6.197 0 0 1-4.41-1.826A6.197 6.197 0 0 1 1.763 8c0-1.666.648-3.233 1.826-4.41a6.197 6.197 0 0 1 4.411-1.827c1.667 0 3.234.648 4.412 1.826a6.197 6.197 0 0 1 1.826 4.411 6.197 6.197 0 0 1-1.826 4.412A6.202 6.202 0 0 1 8 14.239Zm0-11.667a5.432 5.432 0 0 0-5.427 5.427 5.432 5.432 0 0 0 5.427 5.427A5.43 5.43 0 0 0 13.43 8 5.435 5.435 0 0 0 8 2.571Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.001 16a7.948 7.948 0 0 1-5.656-2.342A7.94 7.94 0 0 1 0 8c0-2.136.832-4.146 2.342-5.656A7.95 7.95 0 0 1 8.002 0c2.136 0 4.146.832 5.656 2.342A7.948 7.948 0 0 1 16 8a7.948 7.948 0 0 1-2.342 5.656A7.94 7.94 0 0 1 8 16Zm0-14.92A6.867 6.867 0 0 0 3.11 3.108a6.872 6.872 0 0 0-2.026 4.892c0 1.847.719 3.587 2.026 4.892a6.872 6.872 0 0 0 4.892 2.026 6.867 6.867 0 0 0 4.893-2.026 6.872 6.872 0 0 0 2.025-4.892 6.867 6.867 0 0 0-2.026-4.892A6.872 6.872 0 0 0 8.002 1.08Z"
			fill="currentColor"
		/>
	</svg>
</template>
