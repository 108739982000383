<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="currentColor"
		viewBox="0 0 31.999999 31.999998"
	>
		<path
			d="m16.001 1045.4c-0.554 0-1 0.4459-1 1v1c0 0.554 0.446 1 1 1s1-0.446 1-1v-1c0-0.5541-0.446-1-1-1zm7.0703-2.9297c-0.25562 0-0.51116 0.097-0.70703 0.2929-0.39174 0.3917-0.39174 1.0224 0 1.414l0.70703 0.7072c0.39174 0.3916 1.0223 0.3916 1.4141 0 0.39174-0.3919 0.39174-1.0224 0-1.4141l-0.70703-0.7071c-0.19587-0.1958-0.45141-0.2929-0.70703-0.2929zm-14.141 0c-0.25562 0-0.51116 0.097-0.70703 0.2929l-0.70703 0.7071c-0.39174 0.3918-0.39174 1.0223 0 1.4141 0.39173 0.3916 1.0223 0.3916 1.4141 0l0.70703-0.7072c0.39174-0.3916 0.39174-1.0223 0-1.414-0.19587-0.1958-0.45141-0.2929-0.70703-0.2929zm17.07-7.0703c-0.554 0-1 0.4459-1 1 0 0.554 0.446 1 1 1h1c0.554 0 1-0.446 1-1 0-0.5541-0.446-1-1-1h-1zm-21 0c-0.554 0-1 0.4459-1 1 0 0.554 0.446 1 1 1h1c0.554 0 1-0.446 1-1 0-0.5541-0.446-1-1-1h-1zm11-7a8 8 0 0 0 -8 8 8 8 0 0 0 8 8 8 8 0 0 0 8 -8 8 8 0 0 0 -8 -8zm0 2a6 6 0 0 1 6 6 6 6 0 0 1 -6 6 6 6 0 0 1 -6 -6 6 6 0 0 1 6 -6zm7.7773-2.7793c-0.25561 0-0.51116 0.099-0.70703 0.2949l-0.70703 0.7071c-0.39174 0.3917-0.39174 1.0223 0 1.414 0.39174 0.3918 1.0223 0.3918 1.4141 0l0.70703-0.707c0.39174-0.3917 0.39174-1.0223 0-1.4141-0.19586-0.1958-0.45141-0.2949-0.70703-0.2949zm-15.555 0c-0.25562 0-0.51117 0.099-0.70703 0.2949-0.39174 0.3918-0.39174 1.0224 0 1.4141l0.70703 0.707c0.39173 0.3918 1.0223 0.3918 1.4141 0 0.39174-0.3917 0.39174-1.0223 0-1.414l-0.70703-0.7071c-0.19587-0.1958-0.45142-0.2949-0.70703-0.2949zm7.7773-3.2207c-0.554 0-1 0.4459-1 1v1c0 0.554 0.446 1 1 1s1-0.446 1-1v-1c0-0.5541-0.446-1-1-1z"
			transform="translate(0 -1020.4)"
		/>
	</svg>
</template>
