<template>
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.455 13.09c.008 0 .012.004.02.004.068.012.136 0 .196-.028 1.175-.553 12.513-5.907 13.523-6.195 1.122-.32 4.756-.215 4.913.24.162.453-15.238 7.134-17.352 7.942-.965.371-3.587-.898-3.974-1.09a.102.102 0 0 0-.096.001l-.53.238a.103.103 0 0 1-.084.004l-.728-.258c-.088-.031-.106-.152-.026-.2l1.92-1.284a.117.117 0 0 1 .096-.016l2.122.642Z"
			fill="currentColor"
		/>
		<path
			d="m9.343 10.067-4.35-1.12c-.105-.023-.118-.171-.023-.212l1.287-.577a.123.123 0 0 1 .052-.012l6.08.345c.12.008.146.168.043.216l-3.013 1.356a.16.16 0 0 1-.076.004ZM23.006 22.08H.994c-.55 0-.994.42-.994.937 0 .517.445.936.994.936h22.012c.55 0 .994-.419.994-.936 0-.518-.445-.937-.994-.937Z"
			fill="currentColor"
		/>
	</svg>
</template>
