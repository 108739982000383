<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.726 14.034c-4.728.337-5.976-1.17-6.39-4.253C.922 6.698 2.83 4.943 5.65 4.527c2.088-.309 3.814.985 4.738 1.676.323.242.548.41.655.41.414 0 .828-1.17.828-1.17 1.908.17 2.91 1.509 3.324 2.341.111.224.259.412.397.589.376.477.683.868.017 1.661-.509.602-1.121.557-1.692.516-.454-.033-.883-.064-1.212.232-.64.567.054 1.2.528 1.632.081.074.155.141.216.202.414.422.666 1.001-.828 1.17l-.209.024c-1.385.158-2.384.273-2.779-.44-.414-.754-1.325-1.086-1.41-.084-.083.995-.497.748-.497.748Zm2.748-1.128.004.002a.886.886 0 0 0 .304.056c.365.022.839-.03 1.519-.108a3.793 3.793 0 0 1-.445-.511c-.165-.234-.363-.594-.37-1.042-.006-.499.226-.923.58-1.237.394-.354.841-.462 1.209-.489.26-.019.553.003.754.018l.123.009c.45.027.576-.015.722-.188.081-.097.124-.164.147-.205a4.293 4.293 0 0 0-.214-.285c-.13-.166-.32-.41-.472-.714-.286-.575-.874-1.368-1.9-1.686a2.98 2.98 0 0 1-.369.541c-.132.15-.481.507-1.023.507-.237 0-.424-.081-.502-.118a2.11 2.11 0 0 1-.256-.144 8.616 8.616 0 0 1-.438-.314l-.028-.02c-.303-.228-.655-.492-1.069-.743-.86-.524-1.87-.92-2.96-.759-1.26.186-2.206.656-2.791 1.315-.565.637-.887 1.554-.711 2.862.197 1.47.565 2.288 1.204 2.77.63.476 1.73.781 3.786.676.045-.351.175-.735.484-1.027.37-.349.833-.415 1.212-.347.67.12 1.207.65 1.5 1.18Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.072 2.775c-.122-.51-.385-.855-.873-.855-.236 0-.323.482-.319 1.15.01 1.456.452 3.793.739 3.97 0 0 .801-2.808.453-4.265ZM0 10.88a1.28 1.28 0 1 1 2.56 0 1.28 1.28 0 0 1-2.56 0Z"
			fill="currentColor"
		/>
	</svg>
</template>
