<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.6 4.17A2.445 2.445 0 1 1 9.32 8.233 2.445 2.445 0 0 1 6.6 4.169Zm2.537 1.788h.781a1.978 1.978 0 0 0-1.14-1.542 3.7 3.7 0 0 1 .36 1.542Zm-1.875.48c.058 1.082.429 1.722.698 1.722v.032c.269 0 .653-.691.704-1.754H7.262ZM7.96 4.23c-.27 0-.653.666-.698 1.728h1.402C8.6 4.87 8.229 4.23 7.96 4.23ZM6.782 5.958a3.7 3.7 0 0 1 .359-1.542 1.959 1.959 0 0 0-1.159 1.542h.8Zm0 .48h-.8a1.958 1.958 0 0 0 1.159 1.543 3.725 3.725 0 0 1-.359-1.543Zm2.355 0a3.725 3.725 0 0 1-.358 1.543 1.978 1.978 0 0 0 1.14-1.543h-.782Z"
			fill="currentColor"
		/>
		<path
			d="M6.033 10.752h3.84a.244.244 0 0 0 0-.48h-3.84a.243.243 0 0 0 0 .48ZM9.265 11.904h-2.61a.243.243 0 0 1-.238-.237.237.237 0 0 1 .237-.237h2.611a.23.23 0 0 1 .237.237.237.237 0 0 1-.237.237Z"
			fill="currentColor"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.652.64h8.615a1.094 1.094 0 0 1 1.05 1.094v12.218c.006.072.006.145 0 .218a1.1 1.1 0 0 1-1.076.876H3.627a1.094 1.094 0 0 1-1.069-.876.812.812 0 0 1 0-.218V1.734A1.094 1.094 0 0 1 3.652.64Zm.007 13.312h8.614V1.734H3.66v12.218Z"
			fill="currentColor"
		/>
	</svg>
</template>
