<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M13.244.176a.855.855 0 0 0-.683-.16l-1.603.056a.555.555 0 0 0-.51.389L8.75 5.899H7.652l-.127-2.843 1.041-.219a.788.788 0 0 0 .59-.998l-.153-.502A.781.781 0 0 0 8.54.835a.78.78 0 0 0-.681.05l-1.21.7a.555.555 0 0 0-.277.504l.17 3.81h-.333a8.513 8.513 0 0 0-.003-.008L4.868 2.816a.555.555 0 0 0-.428-.327l-1.593-.231a.828.828 0 0 0-.701.225.756.756 0 0 0-.209.698l.06.248c.078.424.49.715.937.654l1.205-.163.862 1.98h-.566c-.408 0-.739.33-.739.738v7.204A2.16 2.16 0 0 0 5.854 16h3.855a2.16 2.16 0 0 0 2.158-2.158V6.638c0-.408-.33-.74-.739-.74H9.912l1.318-4.222 1.206.46a.55.55 0 0 0 .201.037c.47-.002.863-.365.878-.808l.02-.565a.756.756 0 0 0-.29-.624Zm-2.856 13.666a.68.68 0 0 1-.679.68H5.854a.68.68 0 0 1-.68-.68V7.377h5.214v6.465Z"
			fill="currentColor"
		/>
		<path
			d="M7.191 12.01h1.28c.683 0 1.238-.556 1.238-1.238V9.176a.555.555 0 0 0-.555-.554H6.51a.555.555 0 0 0-.555.554v1.596c0 .682.554 1.237 1.236 1.237Zm-.127-2.28h1.535v1.042c0 .071-.057.128-.128.128h-1.28a.128.128 0 0 1-.128-.128V9.731Z"
			fill="currentColor"
		/>
	</svg>
</template>
