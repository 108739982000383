<template>
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="M8.456.607c0-.335.272-.607.607-.607a7.176 7.176 0 0 1 7.177 7.177.607.607 0 0 1-1.214 0 5.962 5.962 0 0 0-5.963-5.963.607.607 0 0 1-.607-.607Z"
		/>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.051 5.188c.688-.589.822-1.373.63-2.1-.181-.689-.65-1.334-1.187-1.833C4.956.753 4.286.34 3.625.165 2.973-.006 2.178.029 1.652.674l-.104.124c-.33.395-.613.732-.835 1.033-.249.337-.45.663-.573 1.023-.254.738-.135 1.48.136 2.513C.47 6.27 1.124 7.53 2 8.827a24.985 24.985 0 0 0 3.349 3.976c1.278 1.22 2.705 2.277 4.116 2.816 1.412.539 2.894.583 4.143-.372.886-.556 1.335-1.153 1.475-1.776.124-.552-.029-1.028-.104-1.263a4.744 4.744 0 0 1-.018-.057c-.242-.79-.858-1.694-1.643-2.233-.4-.275-.878-.48-1.402-.49-.538-.011-1.065.185-1.53.607a.646.646 0 0 0-.024.023c-.099.1-.184.22-.238.297l-.013.02a1.584 1.584 0 0 1-.148.19c-.037.037-.054.043-.057.044a.06.06 0 0 1-.018.003.29.29 0 0 1-.072-.007.605.605 0 0 0-.123-.011.888.888 0 0 1-.05-.024 3.308 3.308 0 0 1-.435-.286 13.392 13.392 0 0 1-1.31-1.16C6.92 8.154 5.975 7.006 5.62 6.238v-.002c-.126-.268-.15-.429-.15-.512 0-.069.016-.097.032-.119a.72.72 0 0 1 .176-.15l.052-.034c.084-.056.222-.146.321-.234ZM3.315 1.34c-.429-.113-.627-.017-.723.102l-.006.007-.1.12c-.34.406-.598.714-.797.984-.215.291-.335.503-.401.695-.125.364-.107.786.166 1.824a.6.6 0 0 1 .007.03c.139.664.682 1.77 1.544 3.047a23.774 23.774 0 0 0 3.181 3.777c1.224 1.168 2.514 2.103 3.71 2.56 1.185.452 2.188.41 2.991-.216a.599.599 0 0 1 .053-.037c.735-.456.912-.821.958-1.027.05-.22-.007-.402-.086-.656l-.012-.04v-.002c-.166-.542-.624-1.213-1.17-1.589-.267-.183-.52-.272-.74-.276-.2-.005-.426.059-.674.278-.021.024-.05.064-.115.155l-.002.003a2.678 2.678 0 0 1-.27.336c-.123.125-.29.257-.514.338-.201.072-.416.09-.641.06a.96.96 0 0 1-.278-.037 1.648 1.648 0 0 1-.308-.125 4.451 4.451 0 0 1-.609-.395c-.427-.32-.933-.77-1.438-1.27C6.05 9 4.971 7.726 4.52 6.75c-.172-.369-.264-.709-.264-1.028.001-.334.104-.61.266-.832.152-.207.34-.346.472-.436.067-.046.114-.077.15-.1.051-.034.077-.05.103-.074a.613.613 0 0 1 .013-.011c.278-.237.343-.515.249-.87-.104-.395-.407-.852-.84-1.256-.432-.401-.931-.692-1.353-.804Z"
		/>
		<path
			fill="currentColor"
			d="M9.063 2.698a.607.607 0 0 0 0 1.214 3.264 3.264 0 0 1 3.265 3.265.607.607 0 1 0 1.214 0 4.478 4.478 0 0 0-4.479-4.48Z"
		/>
	</svg>
</template>
