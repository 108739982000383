import { shallowRef } from 'vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import type { PusherPresenceChannel } from 'laravel-echo/dist/channel'

/** Singleton instance of Echo. */
const echo = shallowRef<Echo<'pusher'>>()

/** Checks if Echo is ready. */
const isReady = computed(() => !!echo.value)

/**
 * Throws if Echo is not initialized.
 */
function ensureEchoIsReady() {
	if (!isReady.value) {
		throw new Error('Echo is not yet ready.')
	}
}

/**
 * Loads Echo if it is not initialized.
 */
export function loadEcho() {
	if (!echo.value) {
		const client = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY!, {
			forceTLS: true,
			cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER!,
			authEndpoint: '/broadcasting/auth',
			auth: {
				headers: {},
			},
		})

		echo.value = new Echo({
			broadcaster: 'pusher',
			client,
		})
	}
}

/**
 * Defines multiple listeners at once.
 */
export function listen<T = any>(
	channel: string,
	events: Record<string, (event: BroadcastMessage<T>) => void>,
) {
	loadEcho()
	ensureEchoIsReady()

	const stopListeningCallbacks: Function[] = []

	for (const [event, cb] of Object.entries(events)) {
		const eventName = !event.startsWith('.') ? `.${event}` : event
		echo.value?.channel(channel).listen(eventName, cb)
		stopListeningCallbacks?.push(() => echo.value?.channel(channel).stopListening(eventName))
	}

	tryOnUnmounted(() => {
		stopListeningCallbacks.forEach((cb) => cb())
	})

	return () => stopListeningCallbacks.forEach((cb) => cb())
}

/**
 * Join a presence channel.
 */
export function join<T = any>(
	channel: string,
	events: Record<string, (event: BroadcastMessage<T>) => void>,
) {
	loadEcho()
	ensureEchoIsReady()

	const stopListeningCallbacks: Function[] = []

	for (const [event, cb] of Object.entries(events)) {
		const eventName = !event.startsWith('.') ? `.${event}` : event
		echo.value?.join(channel).listen(eventName, cb)
		stopListeningCallbacks?.push(() => echo.value?.channel(channel).stopListening(eventName))
	}

	tryOnUnmounted(() => {
		stopListeningCallbacks.forEach((cb) => cb())
	})

	return () => stopListeningCallbacks.forEach((cb) => cb())
}

/**
 * Emits a whisper.
 */
export function whisper<T>(channel: string, event: string, data?: T) {
	loadEcho()
	ensureEchoIsReady()

	const presence = echo.value?.join(channel) as PusherPresenceChannel
	presence.whisper(event, data)
}

/**
 * Emits a whisper.
 */
export function listenToWhispers<T>(channel: string, event: string, cb?: (data: T) => void) {
	loadEcho()
	ensureEchoIsReady()

	echo.value?.join(channel)?.listenForWhisper(event, cb as Function)

	return () => echo.value?.join(channel).stopListeningForWhisper(event)
}

/**
 * Scoped use of Echo.
 */
export function useEcho() {
	loadEcho()

	/**
	 * Callbacks to stop listening to events.
	 */
	const stopListeningCallbacks: Function[] = []

	/**
	 * Defines multiple listeners at once.
	 * @example
	 * ```
	 * listen({
	 *   MessageCreated: ({ model }) => addMessage(model),
	 *   MessageUpdated: ({ model }) => updateMessage(model),
	 * })
	 * ```
	 */
	async function scopedListen<T = any>(channel: string, events: Record<string, (event: BroadcastMessage<T>) => void>) {
		stopListeningCallbacks.push(
			listen(channel, events),
		)
	}

	/**
	 * Join a presence channel.
	 */
	async function scopedJoin<T = any>(channel: string, events: Record<string, (event: BroadcastMessage<T>) => void>) {
		stopListeningCallbacks.push(
			join(channel, events),
		)
	}

	/**
	 * Whisper something.
	 */
	async function scopedWhisper<T = any>(channel: string, event: string, data?: T) {
		return whisper(channel, event, data)
	}

	/**
	 * Listen for whispers.
	 */
	async function scopedListenToWhisper<T = any>(channel: string, event: string, cb?: (data: T) => void) {
		stopListeningCallbacks.push(
			listenToWhispers(channel, event, cb),
		)
	}

	// When unmounting, stop listening to callbacks.
	tryOnUnmounted(() => stopListeningCallbacks.forEach((cb) => cb()))

	return {
		echo,
		listen: scopedListen,
		join: scopedJoin,
		whisper: scopedWhisper,
		listenToWhispers: scopedListenToWhisper,
		isReady,
	}
}
