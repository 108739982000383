<template>
	<svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M47.1819 67.5236C47.1819 68.2763 46.6664 68.8818 46.0282 68.8818H24.7712C24.133 68.8818 23.6094 68.2763 23.6094 67.5236L23.7076 38.33C23.7076 37.5854 24.223 36.98 24.8612 36.98H27.815V31.9725H32.716V29.8616H36.6352V31.9725H41.5363V36.98H44.49C45.1282 36.98 45.6436 37.5854 45.6436 38.33L47.1819 67.5236Z" fill="#9EC9D4" />
		<path d="M26.2615 18.8647C26.2615 21.8512 21.9986 27.0959 20.1904 29.1823C20.1249 29.2641 20.0513 29.3378 19.994 29.4114C19.8222 29.215 19.6258 28.9859 19.4049 28.7323C17.4494 26.4168 13.7266 21.6548 13.7266 18.8647C13.7266 15.2728 16.4839 12.3273 20.0022 12.0327C23.5123 12.3273 26.2615 15.2728 26.2615 18.8647Z" fill="#9EC9D4" />
		<path d="M27.4397 18.8649C27.4397 22.6532 20.5831 30.0743 20.5831 30.0743L20.5749 30.0661C20.5422 30.0334 20.3213 29.7879 19.994 29.4116C19.8222 29.2152 19.6258 28.9861 19.4049 28.7324C17.4494 26.4169 13.7266 21.655 13.7266 18.8649C13.7266 15.273 16.4839 12.3274 20.0022 12.0329C20.1986 12.0165 20.3949 12.0083 20.5913 12.0083C24.3714 12.0001 27.4397 15.0684 27.4397 18.8649Z" fill="#CED7DF" />
		<path d="M23.438 18.3656C23.438 19.9447 22.1616 21.2211 20.5825 21.2211C20.4025 21.2211 20.2306 21.2047 20.0588 21.172C18.7333 20.9265 17.7188 19.7647 17.7188 18.3656C17.7188 16.9664 18.7251 15.8046 20.0588 15.5591C20.2306 15.5264 20.4025 15.51 20.5825 15.51C22.1616 15.51 23.438 16.7864 23.438 18.3656Z" fill="#8CA0B4" />
		<path d="M22.3907 18.3658C22.3907 19.7649 21.3843 20.9268 20.0588 21.1722C18.7333 20.9268 17.7188 19.7649 17.7188 18.3658C17.7188 16.9667 18.7251 15.8048 20.0588 15.5593C21.3925 15.8048 22.3907 16.9667 22.3907 18.3658Z" fill="white" />
		<path d="M20.5817 30.066H19.4034C19.4034 30.066 12.5469 22.6448 12.5469 18.8565C12.5469 15.0682 15.6151 12 19.4034 12C19.5998 12 19.8044 12.0082 19.9925 12.0245C16.4743 12.3191 13.7169 15.2646 13.7169 18.8565C13.7169 21.6466 17.4397 26.4086 19.3952 28.7241C19.6162 28.9859 19.8207 29.215 19.9844 29.4032C20.328 29.7878 20.5407 30.025 20.5817 30.066Z" fill="#8CA0B4" />
		<path d="M67.1963 68.8734H45.3502C44.6548 68.8734 44.082 68.2516 44.082 67.4825V15.0437C44.082 13.9883 45.113 13.3173 45.9557 13.8246L67.8018 26.9568C68.2109 27.2023 68.4563 27.6686 68.4563 28.1759V67.4825C68.4645 68.2516 67.8918 68.8734 67.1963 68.8734Z" fill="#BDDBE3" />
		<path d="M44.6722 42.1917H26.909C26.3935 42.1917 25.9844 41.7744 25.9844 41.2671C25.9844 40.7516 26.4017 40.3425 26.909 40.3425H44.6722C45.1877 40.3425 45.5968 40.7598 45.5968 41.2671C45.6049 41.7744 45.1877 42.1917 44.6722 42.1917Z" fill="#BDDBE3" />
		<path d="M44.6722 46.1929H26.909C26.3935 46.1929 25.9844 45.7756 25.9844 45.2683C25.9844 44.7528 26.4017 44.3438 26.909 44.3438H44.6722C45.1877 44.3438 45.5968 44.761 45.5968 45.2683C45.6049 45.7756 45.1877 46.1929 44.6722 46.1929Z" fill="#BDDBE3" />
		<path d="M44.6722 50.1856H26.909C26.3935 50.1856 25.9844 49.7683 25.9844 49.261C25.9844 48.7455 26.4017 48.3364 26.909 48.3364H44.6722C45.1877 48.3364 45.5968 48.7537 45.5968 49.261C45.6049 49.7765 45.1877 50.1856 44.6722 50.1856Z" fill="#BDDBE3" />
		<path d="M44.6722 54.1868H26.909C26.3935 54.1868 25.9844 53.7695 25.9844 53.2622C25.9844 52.7468 26.4017 52.3376 26.909 52.3376H44.6722C45.1877 52.3376 45.5968 52.7549 45.5968 53.2622C45.6049 53.7695 45.1877 54.1868 44.6722 54.1868Z" fill="#BDDBE3" />
		<path d="M44.6722 58.1875H26.909C26.3935 58.1875 25.9844 57.7702 25.9844 57.263C25.9844 56.7475 26.4017 56.3384 26.909 56.3384H44.6722C45.1877 56.3384 45.5968 56.7557 45.5968 57.263C45.6049 57.7702 45.1877 58.1875 44.6722 58.1875Z" fill="#BDDBE3" />
		<path d="M44.6722 62.1807H26.909C26.3935 62.1807 25.9844 61.7634 25.9844 61.2561C25.9844 60.7406 26.4017 60.3315 26.909 60.3315H44.6722C45.1877 60.3315 45.5968 60.7488 45.5968 61.2561C45.6049 61.7634 45.1877 62.1807 44.6722 62.1807Z" fill="#BDDBE3" />
		<path d="M44.6722 66.1814H26.909C26.3935 66.1814 25.9844 65.7641 25.9844 65.2569C25.9844 64.7414 26.4017 64.3323 26.909 64.3323H44.6722C45.1877 64.3323 45.5968 64.7496 45.5968 65.2569C45.6049 65.7641 45.1877 66.1814 44.6722 66.1814Z" fill="#BDDBE3" />
		<path d="M65.5451 32.0871H58.3367C58.0913 32.0871 57.8867 31.8907 57.8867 31.6371V30.3116C57.8867 30.0661 58.0831 29.8616 58.3367 29.8616H65.5451C65.7906 29.8616 65.9951 30.0579 65.9951 30.3116V31.6371C65.987 31.8825 65.7906 32.0871 65.5451 32.0871Z" fill="#9EC9D4" />
		<path d="M65.5451 36.8488H58.3367C58.0913 36.8488 57.8867 36.6524 57.8867 36.3988V35.0733C57.8867 34.8278 58.0831 34.6233 58.3367 34.6233H65.5451C65.7906 34.6233 65.9951 34.8197 65.9951 35.0733V36.407C65.987 36.6524 65.7906 36.8488 65.5451 36.8488Z" fill="#9EC9D4" />
		<path d="M65.5451 41.6191H58.3367C58.0913 41.6191 57.8867 41.4227 57.8867 41.1691V39.8436C57.8867 39.5981 58.0831 39.3936 58.3367 39.3936H65.5451C65.7906 39.3936 65.9951 39.5899 65.9951 39.8436V41.1691C65.987 41.4145 65.7906 41.6191 65.5451 41.6191Z" fill="#9EC9D4" />
		<path d="M65.5451 46.381H58.3367C58.0913 46.381 57.8867 46.1847 57.8867 45.931V44.6055C57.8867 44.3601 58.0831 44.1555 58.3367 44.1555H65.5451C65.7906 44.1555 65.9951 44.3519 65.9951 44.6055V45.931C65.987 46.1847 65.7906 46.381 65.5451 46.381Z" fill="#9EC9D4" />
		<path d="M65.5451 51.1511H58.3367C58.0913 51.1511 57.8867 50.9547 57.8867 50.7011V49.3756C57.8867 49.1301 58.0831 48.9255 58.3367 48.9255H65.5451C65.7906 48.9255 65.9951 49.1219 65.9951 49.3756V50.7011C65.987 50.9465 65.7906 51.1511 65.5451 51.1511Z" fill="#9EC9D4" />
		<path d="M65.5451 55.913H58.3367C58.0913 55.913 57.8867 55.7167 57.8867 55.463V54.1375C57.8867 53.8921 58.0831 53.6875 58.3367 53.6875H65.5451C65.7906 53.6875 65.9951 53.8839 65.9951 54.1375V55.463C65.987 55.7167 65.7906 55.913 65.5451 55.913Z" fill="#9EC9D4" />
		<path d="M65.5451 60.6833H58.3367C58.0913 60.6833 57.8867 60.4869 57.8867 60.2333V58.9078C57.8867 58.6623 58.0831 58.4578 58.3367 58.4578H65.5451C65.7906 58.4578 65.9951 58.6541 65.9951 58.9078V60.2333C65.987 60.4787 65.7906 60.6833 65.5451 60.6833Z" fill="#9EC9D4" />
		<path d="M65.5451 65.4452H58.3367C58.0913 65.4452 57.8867 65.2489 57.8867 64.9952V63.6698C57.8867 63.4243 58.0831 63.2197 58.3367 63.2197H65.5451C65.7906 63.2197 65.9951 63.4161 65.9951 63.6698V64.9952C65.987 65.2489 65.7906 65.4452 65.5451 65.4452Z" fill="#9EC9D4" />
		<path opacity="0.5" d="M44.0854 36.9798V68.8817H38.1289V31.9724H41.5408V36.9798H44.0854Z" fill="#5EA6B7" />
		<path d="M30.801 68.8734H13.4959C12.8741 68.8734 12.375 68.3743 12.375 67.7525V53.2621C12.375 52.6402 12.8741 52.1411 13.4959 52.1411H30.801C31.4228 52.1411 31.9219 52.6402 31.9219 53.2621V67.7525C31.9137 68.3743 31.4146 68.8734 30.801 68.8734Z" fill="#CEE1E5" />
		<path d="M17.9829 59.505H15.2664C15.021 59.505 14.8164 59.3086 14.8164 59.055V55.504C14.8164 55.2585 15.0128 55.054 15.2664 55.054H17.9829C18.2283 55.054 18.4329 55.2503 18.4329 55.504V59.055C18.4329 59.3004 18.2283 59.505 17.9829 59.505Z" fill="#9EC9D4" />
		<path d="M23.5063 59.505H20.7899C20.5444 59.505 20.3398 59.3086 20.3398 59.055V55.504C20.3398 55.2585 20.5362 55.054 20.7899 55.054H23.5063C23.7518 55.054 23.9563 55.2503 23.9563 55.504V59.055C23.9481 59.3004 23.7518 59.505 23.5063 59.505Z" fill="#9EC9D4" />
		<path d="M29.0219 59.505H26.3055C26.06 59.505 25.8555 59.3086 25.8555 59.055V55.504C25.8555 55.2585 26.0518 55.054 26.3055 55.054H29.0219C29.2674 55.054 29.4719 55.2503 29.4719 55.504V59.055C29.4719 59.3004 29.2674 59.505 29.0219 59.505Z" fill="#9EC9D4" />
		<path d="M17.9829 65.6988H15.2664C15.021 65.6988 14.8164 65.5025 14.8164 65.2488V61.6978C14.8164 61.4524 15.0128 61.2478 15.2664 61.2478H17.9829C18.2283 61.2478 18.4329 61.4442 18.4329 61.6978V65.2488C18.4329 65.4943 18.2283 65.6988 17.9829 65.6988Z" fill="#9EC9D4" />
		<path d="M23.5063 65.6988H20.7899C20.5444 65.6988 20.3398 65.5025 20.3398 65.2488V61.6978C20.3398 61.4524 20.5362 61.2478 20.7899 61.2478H23.5063C23.7518 61.2478 23.9563 61.4442 23.9563 61.6978V65.2488C23.9481 65.4943 23.7518 65.6988 23.5063 65.6988Z" fill="#9EC9D4" />
		<path d="M29.0219 65.6988H26.3055C26.06 65.6988 25.8555 65.5025 25.8555 65.2488V61.6978C25.8555 61.4524 26.0518 61.2478 26.3055 61.2478H29.0219C29.2674 61.2478 29.4719 61.4442 29.4719 61.6978V65.2488C29.4719 65.4943 29.2674 65.6988 29.0219 65.6988Z" fill="#9EC9D4" />
		<path d="M72.1645 68.996H8.83547C8.64728 68.996 8.5 68.8487 8.5 68.6605V67.8587C8.5 67.6705 8.64728 67.5232 8.83547 67.5232H72.1645C72.3527 67.5232 72.5 67.6705 72.5 67.8587V68.6605C72.5 68.8405 72.3445 68.996 72.1645 68.996Z" fill="#8CA0B4" />
		<path d="M50.1544 63.4488C50.1544 63.4488 51.9299 62.6306 51.2508 61.8615C50.5717 61.0924 50.6617 61.0924 50.6617 60.0697C50.6617 59.0469 49.1726 58.0487 48.8044 58.4905C48.4362 58.9324 47.2498 58.9815 47.0043 60.0697C46.7589 61.1579 45.7034 61.9843 46.6934 62.9252C47.6916 63.8661 48.518 64.087 50.1544 63.4488Z" fill="#F2F5F7" />
		<path d="M48.4531 63.4489V67.3353H48.9195V63.408L48.4531 63.4489Z" fill="#F2F5F7" />
		<path d="M65.0451 63.4488C65.0451 63.4488 66.8206 62.6306 66.1415 61.8615C65.4623 61.0924 65.5524 61.0924 65.5524 60.0697C65.5524 59.0469 64.0632 58.0487 63.695 58.4905C63.3268 58.9324 62.1404 58.9815 61.895 60.0697C61.6495 61.1579 60.594 61.9843 61.5841 62.9252C62.5823 63.8661 63.4087 64.087 65.0451 63.4488Z" fill="#F2F5F7" />
		<path d="M63.3438 63.4489V67.3353H63.8183V63.408L63.3438 63.4489Z" fill="#F2F5F7" />
		<path d="M44.2286 64.316C44.2286 64.316 45.5213 62.4014 45.0222 60.6177C44.5231 58.834 44.5967 58.8259 44.5967 56.4285C44.5967 54.0312 43.5167 51.7075 43.2467 52.7384C42.9767 53.7694 42.1176 53.8839 41.9376 56.4285C41.7576 58.9731 40.9885 60.8959 41.7085 63.0887C42.4367 65.2897 43.034 65.8051 44.2286 64.316Z" fill="#F2F5F7" />
		<path d="M42.8867 62.786V67.5234H43.3531V62.7288L42.8867 62.786Z" fill="#F2F5F7" />
	</svg>
</template>
