<template>
	<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.65 9.601a.454.454 0 0 0 .41.259h1.883c.176 0 .335-.1.41-.259l1.655-2.05a.465.465 0 0 0 .058-.484.457.457 0 0 0-.41-.259H9.787a.465.465 0 0 0-.356.17l-.43.53-.428-.53a.456.456 0 0 0-.356-.17H6.35a.454.454 0 0 0-.353.743L7.65 9.6Zm.349-1.88.645.8a.456.456 0 0 0 .712 0l.645-.8h.693l-.988 1.226H8.29L7.302 7.72H8Z"
			fill="currentColor"
		/>
		<path
			d="M16.05 12.281c1.265-.955 1.95-2.142 1.95-3.41 0-1.515-1.007-3.21-2.69-4.533A10.27 10.27 0 0 0 9 2.16c-1.176 0-2.347.207-3.473.615-1.037.374-1.99.903-2.835 1.566C1.007 5.665 0 7.36 0 8.874c0 1.268.684 2.455 1.95 3.41-.493.465-.633.934-.633 1.311 0 .666.43 1.612 2.48 2.333 1.399.493 3.245.764 5.201.764 1.957 0 3.803-.27 5.202-.764 2.048-.72 2.48-1.667 2.48-2.333.003-.38-.137-.846-.63-1.314ZM1.217 8.874c0-1.126.852-2.498 2.224-3.575a9.12 9.12 0 0 1 2.497-1.378 8.936 8.936 0 0 1 3.064-.544c1.037 0 2.065.182 3.06.541a9.12 9.12 0 0 1 2.497 1.378c1.372 1.077 2.224 2.45 2.224 3.575 0 .98-.648 1.925-1.831 2.692a7.162 7.162 0 0 0-.748-.304c-1.4-.493-3.246-.764-5.202-.764-1.957 0-3.803.271-5.202.764-.28.097-.527.2-.749.304-1.186-.767-1.834-1.713-1.834-2.69Zm12.275 3.431c-1.309.514-2.86.791-4.49.791-1.631 0-3.183-.277-4.49-.79 1.231-.384 2.81-.594 4.49-.594 1.676 0 3.255.21 4.49.593Zm.307 2.477c-1.272.447-2.978.693-4.8.693-1.823 0-3.526-.246-4.8-.693-1.15-.405-1.665-.889-1.665-1.187 0-.167.161-.39.502-.626 1.64.87 3.742 1.344 5.962 1.344 2.221 0 4.323-.474 5.963-1.344.34.234.502.459.502.626.003.298-.514.782-1.664 1.187Z"
			fill="currentColor"
		/>
	</svg>
</template>
